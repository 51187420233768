import { Box, useTheme } from "@mui/material";
import { Biotech } from "@mui/icons-material";
import React from "react";

export function LabsOnDemandIndicator() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        color: theme.palette.grey[500],
        marginLeft: "-5px",
        paddingTop: theme.spacing(1),
      }}
    >
      <Biotech />
      <span>On Demand</span>
    </Box>
  );
}
