import { Theme } from "@mui/material";

export const LabsStyles = (theme: Theme) => ({
  errorText: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.disabled,
  },
  aboutLabsWidgetContent: {
    top: theme.spacing(-1),
  },
  whyLabsWidgetContent: {
    top: theme.spacing(-1),
    textAlign: "center",
  },
  button: {
    "&:hover": {
      backgroundColor: "rgba(27,118,210, 0.07)",
    },
  },
  toolbar: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    "& > *": {
      display: "flex",
      flexShrink: 0,
      marginLeft: theme.spacing(1),
    },
  },
  tab: {
    width: 150,
  },
  search: {
    display: "flex",
    alignItems: "center",
    width: "46%",
  },
  searchIcon: {
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 4),
      transition: theme.transitions.create("width"),
      width: "100% !important",
      [theme.breakpoints.up("md")]: {
        width: 400,
      },
    },
  },
  imageBox: {
    alignItems: "start",
    maxWidth: "80px",
    maxHeight: "20px",
    objectFit: "scale-down",
    objectPosition: "left",
    marginRight: "8px",
  },
  tooltip: {
    "& .MuiTooltip-tooltip": {
      marginBottom: theme.spacing(0.5),
    },
  },
  detailsDiv: {
    paddingRight: "8px",
    paddingLeft: "8px",
    paddingBottom: "8px",
  },
  labDetails: {
    "& .MuiDialog-paperFullWidth": {
      maxHeight: "93%",
      maxWidth: "56%",
    },
    "& .MuiDialog-paperFullScreen": {
      maxWidth: "100%",
      borderRight: "none",
      borderLeft: "none",
    },
  },
  detailsDialog: {
    padding: 0,
    overflow: "hidden",
  },
  detailsContainer: {
    display: "flex",
    width: "102%",
    height: "77vh",
  },
  detailsColumns: {
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: "100%",
    height: "51%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  detailsLeftTitle: {
    fontSize: "20px",
    fontWeight: 600,
  },
  lightColorContent: {
    color: theme.palette.primary.light,
  },
  carouselBase: {
    width: "98.5%",
    maxHeight: "35vh",
  },
  carouselImage: {
    objectFit: "cover",
    width: "100%",
    height: "34vh",
  },
  carouselControls: {
    backgroundColor: "#FFF",
    border: "0px",
    boxShadow: "none !important",
    "& .MuiButton-label": {
      // @ts-ignore
      color: theme.palette.primary.alt,
    },
  },
  detailsLinkContainerLink: {
    display: "block",
  },
  requestDialogContent: {
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  accessError: {
    marginTop: theme.spacing(-0.5),
    color: theme.palette.error.main,
  },
  overrideAltColor: {
    color: theme.palette.primary.contrastText + "!important",
  },
});
