import React from "react";
import {
  ArticleDto,
  NewsCenterClient,
  NewsCenterQuery,
  PageLayoutWidgetDto,
} from "@lib/ShiOneClient";
import {
  Box,
  Chip,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../utils";
import ms from "ms";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { WidgetWrapper } from "shared-ui";
import {
  categoryColors,
  severityLevelColors,
} from "@features/news-center-feature/utils/newsCenterColors";
import Logo from "../../components/Logo";

function useGetNewsArticlesItems() {
  const api = useApi(NewsCenterClient);
  return useQuery(
    [`news-updates-widget-data`],
    () =>
      api
        .getNewsCenter(
          new NewsCenterQuery({
            page: 1,
            pageSize: 6,
          })
        )
        .then((r) => r.articles?.items),
    {
      staleTime: ms("10m"),
    }
  );
}

function NewsArticleItem({ article }: { article: ArticleDto }) {
  const theme = useTheme();

  return (
    <ListItemButton
      href={AppendIfPrefixExists("/message-center/" + article.id)}
      sx={{ borderTop: "solid 1px " + theme.palette.border }}
    >
      <Box
        style={{
          position: "absolute",
          height: "100%",
          width: "4px",
          marginLeft: "-1em",
          backgroundColor:
            severityLevelColors[
              article.severityLevel?.toLowerCase() ?? "unknown"
            ],
        }}
      />
      <ListItemAvatar>
        <Logo product={article.partner ?? ""} shape={"square"} size={"small"} />
      </ListItemAvatar>
      <ListItemText primary={article.title} aria-hidden={true} />
      {article.category ? (
        <Chip
          label={article.category}
          size="small"
          variant="outlined"
          sx={{
            backgroundColor: categoryColors[article.category.toLowerCase()],
            color: "white",
            borderColor: categoryColors[article.category.toLowerCase()],
          }}
        />
      ) : (
        <></>
      )}
    </ListItemButton>
  );
}

export default function NewsArticleWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const theme = useTheme();
  const { data: response, isError } = useGetNewsArticlesItems();
  const articles: ArticleDto[] = response ?? [];

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      error={isError}
      noPadding={true}
    >
      <Box alignContent="stretch" sx={{ padding: 0 }}>
        <List sx={{ padding: 0 }}>
          {articles &&
            articles.map((article) => (
              <NewsArticleItem key={article.id} article={article} />
            ))}
          <ListItemButton
            href={AppendIfPrefixExists("/message-center/")}
            sx={{ borderTop: "solid 1px " + theme.palette.border }}
          >
            <Typography color="primary" fontSize={"small"} fontWeight={"bold"}>
              VIEW ALL
            </Typography>
          </ListItemButton>
        </List>
      </Box>
    </WidgetWrapper>
  );
}
