import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { SvgIcon } from "@mui/material";
import ShiLogo from "@images/shi-logo.svg?react";
import React from "react";
import { assetsGridDefinitions } from "@features/assets-feature/utils/assetsGridDefinitions";
import { Asset } from "@lib/ShiOneClient";
import { InventoryTabIds } from "@features/assets-feature/constants";
import SubscriptionPurchaseProgress from "@features/assets-feature/components/asset-inventory/SubscriptionPurchaseProgress";
import { SortingOrder } from "../../../../../components/mui-data-grid/constants";
import assetsAccessor from "@features/assets-feature/utils/assetsAccessor";
import { AssetWithChildrenCount } from "../../../interfaces/AssetInterfaces";

export const numberFormatter = new Intl.NumberFormat("en-US");

export const ShiIcon = (props: any) => {
  return <SvgIcon {...props} component={ShiLogo} />;
};

export const prepareAssetInventoryGrid = (
  handleAssetClick: (asset: AssetWithChildrenCount, tabValue?: string) => void,
  filteredAssetType?: string
) => {
  const {
    displayName,
    product,
    manufacturer,
    itemQuantity,
    serialNumber,
    assetType,
    shiCategory,
    subCategory,
    shiUseState,
    discoveredOnNet,
    networkId,
    networkAddress,
    shiLifecycleState,
    contract,
    serviceLevelAgreement,
    coveredStatus,
    renewalDate,
    coveredStartDate,
    coveredEndDate,
    managingPartner,
    displayLocationName,
    children,
    tags,
  } = assetsAccessor;

  let columns;
  switch (filteredAssetType) {
    case InventoryTabIds.hardwareConsumables:
      columns = [
        displayName,
        product,
        manufacturer,
        itemQuantity,
        serialNumber,
        assetType,
        shiCategory,
        subCategory,
        shiUseState,
        discoveredOnNet,
        {
          field: networkId,
          headerName: "Network ID",
          minWidth: 200,
        },
        {
          field: networkAddress,
          headerName: "Network Address",
          minWidth: 200,
        },
        shiLifecycleState,
        contract,
        serviceLevelAgreement,
        coveredStatus,
        coveredStartDate,
        coveredEndDate,
        managingPartner,
        displayLocationName,
        children,
        tags,
      ];
      break;
    case InventoryTabIds.softwareSubscription:
      columns = [
        displayName,
        product,
        manufacturer,
        assetType,
        {
          field: "licensesUsed",
          headerName: "License Consumption",
          minWidth: 300,
          sortable: false,
          valueGetter: (value: number, row: Asset) =>
            value !== undefined ? value : row.itemQuantity,
          renderCell: (params: GridRenderCellParams) => {
            const { value, row } = params;
            return (
              <SubscriptionPurchaseProgress
                used={value}
                total={row.itemQuantity}
              />
            );
          },
        },
        {
          field: "licensesPurchased",
          headerName: "Licenses Purchased",
          minWidth: 150,
          valueGetter: (value: number, row: Asset) =>
            value !== undefined
              ? numberFormatter.format(value)
              : row.itemQuantity,
        },
        {
          field: "growthAllowance",
          headerName: "Growth Allowance",
          minWidth: 150,
          valueGetter: (value: number) =>
            value ? numberFormatter.format(value) : 0,
        },
        itemQuantity,
        renewalDate,
        contract,
        coveredStatus,
        coveredEndDate,
        managingPartner,
        children,
        tags,
      ];
      break;
    case InventoryTabIds.dataDigital:
      columns = [
        displayName,
        product,
        manufacturer,
        itemQuantity,
        assetType,
        shiCategory,
        subCategory,
        shiUseState,
        discoveredOnNet,
        shiLifecycleState,
        contract,
        serviceLevelAgreement,
        coveredStatus,
        coveredEndDate,
        managingPartner,
        displayLocationName,
        tags,
      ];
      break;

    default:
      columns = [
        displayName,
        product,
        manufacturer,
        itemQuantity,
        serialNumber,
        assetType,
        shiCategory,
        subCategory,
        shiUseState,
        discoveredOnNet,
        contract,
        serviceLevelAgreement,
        coveredStatus,
        coveredStartDate,
        coveredEndDate,
        displayLocationName,
        children,
        tags,
      ];
  }

  const columnDefinitions: GridColDef[] = assetsGridDefinitions(
    columns,
    handleAssetClick
  );

  const pinnedColumns = { left: ["__check__", displayName] };
  const sortedColumns: GridSortModel = [
    { field: displayName, sort: SortingOrder.Descending },
  ];
  return {
    columnDefinitions,
    pinnedColumns,
    sortedColumns,
  };
};
