import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { LabDto, LabsClient, SearchFilter } from "@lib/ShiOneClient";
import {
  CACHE_KEY_LABS,
  CACHE_KEY_TECHNOLOGY_GROUP,
} from "@features/labs-feature/constants";
import { useApi } from "../../../utils";

export function useGetAllLabs() {
  const api = useApi(LabsClient);

  const fetchAllLabs = async () => {
    return await api.getAllLabsFromSharepoint();
  };

  return useQuery<LabDto[], Error>({
    queryKey: [CACHE_KEY_LABS],
    queryFn: fetchAllLabs,
  });
}

export function useGetTechnologyGroupList() {
  const api = useApi(LabsClient);
  const fetchApi = async () => {
    return await api.getUniqueLabPracticeList();
  };
  return useQuery<string[], Error>({
    queryKey: [CACHE_KEY_LABS, CACHE_KEY_TECHNOLOGY_GROUP],
    queryFn: fetchApi,
  });
}

export function useLabsInfiniteQuery(searchFilter: SearchFilter) {
  const api = useApi(LabsClient);
  const pageSize = 15;

  const fetchPaginatedLabs = async ({
    pageParam = 1,
    searchFilter,
  }: {
    pageParam: number;
    searchFilter: SearchFilter;
  }) => {
    return await api.getPaginatedLabs(pageParam, pageSize, searchFilter);
  };

  return useInfiniteQuery(
    [CACHE_KEY_LABS, JSON.stringify(searchFilter)],
    ({ pageParam = 1 }) => fetchPaginatedLabs({ pageParam, searchFilter }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        const maxPage = lastPage.totalPages!;
        return nextPage <= maxPage ? nextPage : undefined;
      },
    },
  );
}
