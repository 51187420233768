import { useHarveyBallCapability } from "@features/assessments-feature/hooks/useHarveyBallCapability";
import { Button, TableCell, TableRow, Tooltip } from "@mui/material";
import MaturityLabelWithIcon from "@features/assessments-feature/components/maturity-chart/MaturityLabelWithIcon";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorIcon from "@mui/icons-material/Error";
import { CapabilityWithProduct } from "@features/assessments-feature/types/PlatformInsightsProductsType";

const CapabilityRows = ({
  capability,
  currentProduct,
  handleCompareDialog,
}: {
  capability: CapabilityWithProduct;
  currentProduct: string;
  handleCompareDialog: (
    state: boolean,
    currentCapability: CapabilityWithProduct,
  ) => void;
}) => {
  const {
    data: harveyBallData,
    isLoading,
    isError,
  } = useHarveyBallCapability(capability.capabilityId ?? "");

  const currentUsingFilteredCurrentProduct =
    capability.currentlyUsingTools.filter((tool) => tool !== currentProduct);

  const handleShowCompareButton = () => {
    const comparingProductInHarveyBall = [
      ...capability.currentlyUsingTools,
      currentProduct,
    ].filter((tool) =>
      harveyBallData?.harveyBallProductsCollection?.some(
        (harveyBallTool) => harveyBallTool.productName === tool,
      ),
    );

    return (
      !isLoading &&
      capability.hasHarveyBallData &&
      currentUsingFilteredCurrentProduct.length > 0 &&
      comparingProductInHarveyBall.length > 1
    );
  };

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        sx={{ width: { xl: "14vw", md: 240, xs: 172 } }}
      >
        {capability.shortName || capability.capabilityName || "Unknown"}
      </TableCell>
      <TableCell component="th" scope="row" sx={{ width: { md: 160, xs: 80 } }}>
        <MaturityLabelWithIcon
          maturityValue={capability.currentMaturity ?? ""}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        {currentUsingFilteredCurrentProduct.length > 0
          ? currentUsingFilteredCurrentProduct.join(", ")
          : null}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{ maxWidth: 400 }}
        align="right"
      >
        {isLoading && <CircularProgress size={16} />}
        {isError && (
          <Tooltip
            title={"There's an error occurred when loading the Harvey Balls"}
          >
            <ErrorIcon color={"error"} />
          </Tooltip>
        )}
        {handleShowCompareButton() ? (
          <Button onClick={() => handleCompareDialog(true, capability)}>
            Compare with {currentProduct}
          </Button>
        ) : (
          <div className={"w-4"} />
        )}
      </TableCell>
    </TableRow>
  );
};
export default CapabilityRows;
