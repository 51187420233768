import { DateTime } from "luxon";

export const parseDateToDate = (date: string | Date): DateTime | null => {
  let dateTime: DateTime | null;
  if (typeof date === "string") {
    const isoDate = date.replace(" ", "T");
    dateTime = DateTime.fromISO(isoDate);
  } else {
    dateTime = DateTime.fromJSDate(date);
  }

  if (!dateTime || !dateTime.isValid) {
    return null;
  }
  return dateTime;
};

export const parseDateToString = (date: any) => {
  if (typeof date === "string") {
    return DateTime.fromISO(date).toJSDate().toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  } else if (date instanceof Date) {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  } else {
    return null;
  }
};
