import shiColors from "shared-ui/src/theme/shiColors";

export const servicesColors: { [key: string]: string } = {
  "microsoft 365": shiColors.blue.DEFAULT,
  exchange: shiColors.pink.DEFAULT,
  teams: shiColors.green.DEFAULT,
  sharepoint: shiColors.orange.light,
  dynamics365: shiColors.pink.dark,
  onedrive: shiColors.pink.dark,
  "viva suite": shiColors.red.DEFAULT,
  unknown: shiColors.gray.lighter,
};
