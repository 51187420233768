import { useQuery } from "@tanstack/react-query";
import { CACHE_KEY_HARVEY_BALL } from "@features/assessments-feature/constants";
import { AssessmentsClient } from "@lib/ShiOneClient";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";

const api = new AssessmentsClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export function useHarveyBallCapability(capabilityId: string) {
  const fetch = async () => {
    return await api.getHarveyBallsByCapability(capabilityId);
  };

  return useQuery({
    queryKey: [CACHE_KEY_HARVEY_BALL, capabilityId],
    queryFn: fetch,
    enabled: !!capabilityId,
  });
}
