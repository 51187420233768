import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { SkeletonWrapper } from "shared-ui";
import ColoredChips from "shared-ui/src/theme/extensions/ColoredChips";
import { maturityLevelColors } from "@features/assessments-feature/utils/assessmentsColors";
import {
  CapabilityWithProduct,
  GroupedProduct,
} from "@features/assessments-feature/types/PlatformInsightsProductsType";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PlatformInsightsDetails from "@features/assessments-feature/components/platform-insights/PlatformInsightsDetails";
import HarveyBall from "@features/assessments-feature/components/spr-harveyball/HarveyBall";
import { Recommend, Stars } from "@mui/icons-material";

const ProductItems = ({
  expandAll,
  loading,
  productData,
}: {
  loading: boolean;
  productData?: GroupedProduct;
  expandAll?: boolean;
}) => {
  const [expand, setExpand] = useState(false);
  const [isCompareDialogOpen, setIsCompareDialogOpen] = useState(false);
  const [currentCapability, setCurrentCapability] =
    useState<CapabilityWithProduct>();

  useEffect(() => {
    if (expandAll !== undefined) {
      setExpand(expandAll);
    }
  }, [expandAll]);

  const handleCompareDialog = (
    state: boolean,
    currentCapability: CapabilityWithProduct,
  ) => {
    setIsCompareDialogOpen(state);
    setCurrentCapability(currentCapability);
  };
  const hasNoUsingTools = !loading && !productData?.hasUsingTools;
  const hasCapabilities =
    !loading &&
    ((productData?.capabilitiesWithKnownProducts.length ?? 0) > 0 ||
      (productData?.capabilitiesWithOtherProducts.length ?? 0) > 0);

  return (
    <>
      <ListItem
        disablePadding
        sx={{
          bgcolor: hasNoUsingTools ? "background.default" : "background.paper",
          border: hasNoUsingTools ? "1px dashed" : "1px solid",
          borderColor: (theme) => theme.palette.border,
          borderTop: "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container alignItems={"center"} p={1} gap={1}>
          <Grid item container xs={4} alignItems={"center"} p={1} gap={3}>
            {productData?.logo ? (
              <Tooltip title={productData?.productName} followCursor>
                <img
                  alt={productData?.productName}
                  src={productData.logo}
                  height={"32px"}
                  style={{
                    width: "64px",
                    objectFit: "contain",
                  }}
                />
              </Tooltip>
            ) : (
              <SkeletonWrapper loading={loading}>
                <Typography
                  variant="caption"
                  component={"div"}
                  width={"64px"}
                  textAlign={"center"}
                >
                  {productData?.productName}
                </Typography>
              </SkeletonWrapper>
            )}
            <SkeletonWrapper loading={loading}>
              <ListItemText
                primary={productData?.productName}
                secondary={productData?.platformName}
                secondaryTypographyProps={{
                  color: "textSecondary",
                }}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
              />
            </SkeletonWrapper>
          </Grid>
          <Grid container gap={1} item xs>
            {productData?.capabilitiesWithKnownProducts?.map((capability) => (
              <SkeletonWrapper
                key={capability.capabilityId + "known-product-chips"}
                loading={loading}
              >
                <ColoredChips
                  icon={
                    <Tooltip
                      followCursor
                      title={`${productData?.productName} is being used in this capability`}
                    >
                      <Stars />
                    </Tooltip>
                  }
                  label={
                    capability?.shortName ||
                    capability?.capabilityName ||
                    "Unknown"
                  }
                  bgColor={
                    maturityLevelColors[capability?.currentMaturity ?? ""]
                  }
                />
              </SkeletonWrapper>
            ))}
            {productData?.capabilitiesWithOtherProducts?.map((capability) => (
              <SkeletonWrapper
                key={capability.capabilityId + "other-product-chips"}
                loading={loading}
              >
                <ColoredChips
                  icon={
                    <Tooltip
                      followCursor
                      title={`${productData?.productName} is recommended in this capability`}
                    >
                      <Recommend />
                    </Tooltip>
                  }
                  label={
                    capability?.shortName ||
                    capability?.capabilityName ||
                    "Unknown"
                  }
                  bgColor={
                    maturityLevelColors[capability?.currentMaturity ?? ""]
                  }
                />
              </SkeletonWrapper>
            ))}
          </Grid>
          <Grid
            item
            container
            justifyContent="end"
            alignItems="center"
            gap={2}
            xs={2}
          >
            {hasCapabilities && (
              <IconButton
                aria-label="expand row"
                disabled={loading}
                size="small"
                onClick={() => setExpand(!expand)}
              >
                {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </Grid>
        </Grid>
        <PlatformInsightsDetails
          isExpand={expand}
          currentProduct={productData?.productName || ""}
          capabilitiesWithKnownProduct={
            productData?.capabilitiesWithKnownProducts ?? []
          }
          capabilitiesWithOtherProduct={
            productData?.capabilitiesWithOtherProducts ?? []
          }
          handleCompareDialog={handleCompareDialog}
        />
      </ListItem>
      {currentCapability?.capabilityId && (
        <HarveyBall
          capabilityId={currentCapability.capabilityId || ""}
          open={isCompareDialogOpen}
          setIsOpen={setIsCompareDialogOpen}
          actionTitle={currentCapability.capabilityName || ""}
          filteredProducts={[
            ...(currentCapability.currentlyUsingTools || ""),
            productData?.productName || "",
          ]}
        />
      )}
    </>
  );
};

export default ProductItems;
