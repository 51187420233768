import React, { Suspense, useEffect, useState, useTransition } from "react";
import { useParams } from "react-router-dom";
import { assessmentTabIds } from "@features/assessments-feature/constants";
import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import LinkBehavior from "shared-ui/src/theme/overrides/LinkBehavior";
import { AppendIfPrefixExists } from "../../../../../modules/sitePaths";
import { TabExportType } from "../types/TabTypes";
import { assessmentTabsAndExports } from "@features/assessments-feature/utils/assessmentTabsAndExports";
import LoadingOrError from "shared-ui/src/global-layout/LoadingOrError";
import { useAssessmentViewContext } from "@features/assessments-feature/contexts-providers/AssessmentViewContextProvider";

const Overview = React.lazy(
  () =>
    import(
      "@features/assessments-feature/components/overview/AssessmentOverview"
    ),
);
const MaturityChart = React.lazy(
  () =>
    import(
      "@features/assessments-feature/components/maturity-chart/AssessmentMaturityChart"
    ),
);
const Recommendations = React.lazy(
  () =>
    import(
      "@features/assessments-feature/components/priorities-recommendations/AssessmentPrioritiesRecs"
    ),
);
const PlatformInsights = React.lazy(
  () =>
    import(
      "@features/assessments-feature/components/platform-insights/AssessmentPlatformInsights"
    ),
);
const CapabilityInsights = React.lazy(
  () =>
    import(
      "@features/assessments-feature/components/capability-insights/AssessmentCapabilityInsights"
    ),
);

const AssessmentView = () => {
  const { program: paramUrlProgram, type } = useParams();
  const { handleChangeTab, tab } = useAssessmentViewContext();
  const [isPending, startTransition] = useTransition();

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    startTransition(() => {
      handleChangeTab((type as assessmentTabIds) ?? assessmentTabIds.overview);
    });
    const container = document.getElementById("main-container");
    container?.scrollTo(0, 0);
  }, [type]);

  useEffect(() => {
    let timeoutId: any;

    if (isPending) {
      timeoutId = setTimeout(() => {
        setShowLoading(true);
      }, 80);
    } else {
      setShowLoading(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isPending]);

  return (
    <>
      {showLoading ? <LoadingOrError loading /> : null}
      <Grid container direction={"column"} rowGap={2}>
        <Grid item>
          <Paper>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={tab}
              indicatorColor="secondary"
            >
              {assessmentTabsAndExports.map(
                (tab: TabExportType, index: number) => {
                  const key = tab.tabId + index;
                  return (
                    <Tab
                      disabled={tab.tabDisabled}
                      value={tab.tabId}
                      key={key}
                      label={<Box px={3}>{tab.tabName}</Box>}
                      LinkComponent={LinkBehavior}
                      href={AppendIfPrefixExists(
                        `/assessments/${paramUrlProgram}/${tab.tabId}`,
                      )}
                    />
                  );
                },
              )}
            </Tabs>
          </Paper>
        </Grid>
        <Grid item>
          <Suspense fallback={<LoadingOrError loading />}>
            {tab === assessmentTabIds.overview && <Overview />}
            {tab === assessmentTabIds.maturityChart && <MaturityChart />}
            {tab === assessmentTabIds.recommendations && <Recommendations />}
            {tab === assessmentTabIds.platformInsights && <PlatformInsights />}
            {tab === assessmentTabIds.capabilityInsights && (
              <CapabilityInsights />
            )}
          </Suspense>
        </Grid>
      </Grid>
    </>
  );
};

export default AssessmentView;
