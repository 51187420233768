import {
  ActiveUsersByServiceWidgetResponse,
  MicrosoftAdoptionWidgetsClient,
  PageLayoutWidgetDto,
} from "@lib/ShiOneClient";
import { WidgetWrapper } from "shared-ui";
import { Box, Grid, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import Logo from "../../components/Logo";
import LinearProgress from "@mui/material/LinearProgress";
import theme from "../../../../theme";
import ToggleButton from "@mui/material/ToggleButton";
import {
  NoContent,
  useWidgetFilterUserConfig,
} from "../../dashboards/framework";
import { useApi } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import { RankingWidgetContainerHeight } from "@widgets/standard";

let timeOptions = {
  D7: "7",
  D30: "30",
  D90: "90",
  D180: "180",
};

function useGetWidgetData(dateOption: string) {
  const api = useApi(MicrosoftAdoptionWidgetsClient);

  return useQuery<ActiveUsersByServiceWidgetResponse, Error>({
    queryKey: ["active-users-by-service-widget", dateOption],
    queryFn: () => api.getActiveUsersByServiceWidget(dateOption),
    staleTime: ms("10m"),
    enabled: true,
  });
}

export default function ActiveUsersByServiceWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const filterKey = "active-users-by-service-widget";
  const { currentFilterValue, setFilter } = useWidgetFilterUserConfig(
    pageLayoutWidget.widgetId!,
    filterKey,
    timeOptions.D7
  );

  const { data, isError } = useGetWidgetData(currentFilterValue);

  // @ts-ignore
  const isEmpty = !data?.services.length > 0;

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      error={isError}
      noPadding={true}
      isEmpty={isEmpty}
      headerAction={
        <ToggleButtonGroup
          color="secondary"
          exclusive
          value={currentFilterValue}
          onChange={(_e, value) => {
            if (value === null) return;
            setFilter(value);
          }}
        >
          {Object.keys(timeOptions).map((key) => {
            return (
              <ToggleButton
                style={{ lineHeight: 1 }}
                key={key}
                // @ts-ignore It is a key as we are mapping the keys
                value={timeOptions[key]}
              >
                {key}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      }
    >
      {!isEmpty ? (
        <Grid container spacing={2} sx={{ padding: theme.spacing(2) }}>
          {data?.services?.map((item, i) => (
            <Grid key={i} item xs={4}>
              <Box
                sx={{
                  padding: theme.spacing(2),
                  flexDirection: "column",
                  background: theme.palette.background.default,
                }}
              >
                <Logo
                  product={item.service?.replace(/\s/g, "") || "undefined"}
                  shape={"square"}
                  size={"medium"}
                />
                <Typography variant={"h6"}>
                  {item.activeUsers} / {item.totalUsers}
                </Typography>
                <Box sx={{ width: "100%", padding: `${theme.spacing(1)} 0` }}>
                  <LinearProgress
                    variant="determinate"
                    value={
                      ((item.activeUsers || 0) / (item.totalUsers || 1)) * 100
                    }
                    sx={{ borderRadius: "5px" }}
                  />
                </Box>
                <Typography
                  variant={"body2"}
                  color={theme.palette.text.secondary}
                >
                  {item.service} Active Users
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <NoContent
          containerHeight={RankingWidgetContainerHeight}
          header={"No User Data"}
          body={"No data found for this widget."}
          actionText={""}
          actionLink={""}
          icon={""}
        />
      )}
    </WidgetWrapper>
  );
}
