import {
  AssessmentProgram,
  AssessmentsClient,
  CapabilityInsightsDto,
} from "@lib/ShiOneClient";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";
import useAssessmentDemoData from "@features/assessments-feature/hooks/useAssessmentDemoData";
import { useQuery } from "@tanstack/react-query";
import { CACHE_KEY_ASSESSMENTS_CAPABILITY_INSIGHTS } from "@features/assessments-feature/constants";

const api = new AssessmentsClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

const UseGetAssessmentCapabilityInsights = (
  assessmentProgram?: AssessmentProgram
) => {
  const { setDemoMode, isDemoMode } = useAssessmentDemoData();
  const queryFn = async () => {
    if (!assessmentProgram) return null;
    if (isDemoMode.get(assessmentProgram)) return null;
    const result = api.getCapabilityInsights(assessmentProgram);
    return result
      .then((r) => {
        return r;
      })
      .catch((except) => {
        if (except.status === 404) {
          setDemoMode(true, assessmentProgram);
          return null;
        }
      });
  };

  return useQuery<CapabilityInsightsDto, Error>({
    queryKey: [CACHE_KEY_ASSESSMENTS_CAPABILITY_INSIGHTS, assessmentProgram],
    queryFn: async () => await queryFn().then(),
    enabled: !!assessmentProgram && !isDemoMode.get(assessmentProgram),
  });
};

export default UseGetAssessmentCapabilityInsights;
