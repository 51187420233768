import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { goodToBadColors } from "shared-ui";

const HarveyBallMapKey = () => {
  const mapKeys = ["●", "◕", "◑", "◔", "○"];
  const mapKeysColors = Object.values(goodToBadColors);
  return (
    <Grid container direction={"column"} width={240} gap={1}>
      <Grid
        item
        xs
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row-reverse"
        gap={1}
      >
        {mapKeys.map((key, index) => (
          <Typography
            key={key}
            variant="h3"
            fontFamily={"Arial Unicode MS"}
            lineHeight={"20px"}
            color={mapKeysColors[index]}
          >
            {key}
          </Typography>
        ))}
      </Grid>
      <Grid item xs>
        <div style={{ width: "100%", display: "flex", position: "relative" }}>
          <Box
            width={8}
            height={8}
            left={0}
            position="absolute"
            borderTop={(theme) => "1px solid" + theme.palette.action.disabled}
            borderRight={(theme) => "1px solid" + theme.palette.action.disabled}
            sx={{ transform: "rotate(-135deg)" }}
          />
          <Box
            mt={0.5}
            width="100%"
            bgcolor={"action.disabled"}
            height={"1px"}
          />
          <Box
            width={8}
            height={8}
            right={0}
            position="absolute"
            borderTop={(theme) => "1px solid" + theme.palette.action.disabled}
            borderRight={(theme) => "1px solid" + theme.palette.action.disabled}
            sx={{ transform: "rotate(45deg)" }}
          />
        </div>
      </Grid>
      <Grid item container justifyContent={"space-between"}>
        <Typography variant="caption" color={"textSecondary"}>
          No Functionality
        </Typography>
        <Typography variant="caption" color={"textSecondary"}>
          Full Functionality
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HarveyBallMapKey;
