import React, {
  createContext,
  startTransition,
  useContext,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import assessmentViewsMap, {
  AssessmentViewProps,
} from "@features/assessments-feature/utils/assessmentViewsMap";
import {
  assessmentTabIds,
  assessmentTabViewMode,
} from "@features/assessments-feature/constants";
import { assessmentTabViewModeMap } from "@features/assessments-feature/utils/assessmentViewMode";

interface AssessmentViewContextState {
  assessmentViewProps?: AssessmentViewProps;
  currentTabViewMode?: assessmentTabViewMode;
  handleViewMode: (tab: assessmentTabIds, mode: assessmentTabViewMode) => void;
  tab: assessmentTabIds;
  handleChangeTab: (tab: assessmentTabIds) => void;
}

const AssessmentContext = createContext<AssessmentViewContextState | undefined>(
  undefined,
);

export function useAssessmentViewContext() {
  const context = useContext(AssessmentContext);
  if (context === undefined) {
    throw new Error(
      "AssessmentViewContext must be used within a AssessmentViewContextProvider",
    );
  }
  return context;
}

interface Props {
  children: React.ReactNode;
}

const AssessmentViewContextProvider = ({ children }: Props) => {
  const { program, type } = useParams();
  const isTabValid =
    !!type &&
    Object.values(assessmentTabIds).includes(type as assessmentTabIds);
  const assessmentViewProps = assessmentViewsMap.get(program ?? "");
  const [tab, setTab] = useState<assessmentTabIds>(
    isTabValid ? (type as assessmentTabIds) : assessmentTabIds.overview,
  );
  const [currentTabViewMode, setCurrentTabViewMode] = useState<
    assessmentTabViewMode | undefined
  >(assessmentTabViewModeMap.get(tab));

  const handleChangeTab = (tab: assessmentTabIds) => {
    setTab(tab);
    setCurrentTabViewMode(assessmentTabViewModeMap.get(tab));
  };

  const handleViewMode = (
    tab: assessmentTabIds,
    mode: assessmentTabViewMode,
  ) => {
    startTransition(() => {
      assessmentTabViewModeMap.set(tab, mode);
      setCurrentTabViewMode(assessmentTabViewModeMap.get(tab));
    });
  };

  const value = useMemo(
    () => ({
      assessmentViewProps,
      currentTabViewMode,
      handleViewMode,
      tab,
      handleChangeTab,
    }),
    [assessmentViewProps, handleViewMode, currentTabViewMode, tab],
  );
  return (
    <AssessmentContext.Provider value={value}>
      {children}
    </AssessmentContext.Provider>
  );
};

export default AssessmentViewContextProvider;
