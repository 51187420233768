import { useEffect, useRef, useState } from "react";

const UseGetScrollbarWidth = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);

  useEffect(() => {
    const calculateScrollBarWidth = () => {
      const containerBody = containerRef.current;

      if (containerBody) {
        const offsetWidth = containerBody.offsetWidth;
        const clientWidth = containerBody.clientWidth;
        const scrollBarWidth = offsetWidth - clientWidth;
        setScrollbarWidth(scrollBarWidth);
      }
    };

    // Observer to check when the containerRef is added to the DOM
    const observer = new MutationObserver(() => {
      if (containerRef.current) {
        calculateScrollBarWidth();

        // Set up ResizeObserver after the element is available
        const resizeObserver = new ResizeObserver(() => {
          calculateScrollBarWidth();
        });
        resizeObserver.observe(containerRef.current);

        // Disconnect MutationObserver once we have the element
        observer.disconnect();

        // Cleanup ResizeObserver on unmount
        return () => {
          resizeObserver.disconnect();
        };
      }
    });
    // Start observing the document for changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Cleanup the event listener and the observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []);
  return {
    containerRef,
    scrollbarWidth,
  };
};

export default UseGetScrollbarWidth;
