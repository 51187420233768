import { LabDto } from "@lib/ShiOneClient";

export const CACHE_KEY_LABS = "Labs";
export const CACHE_KEY_TECHNOLOGY_GROUP = "technology_group_list";

export const LABS_V2_LAUNCH_LABS: LabDto[] = [
  {
    id: 1152,
    productName: "Sentinel One Endpoint Detection & Response Demo Lab",
    uniqueId: "64a579b1-a647-40de-9614-679a17717f4e",
    isOnDemandLab: true,
    practice: ["Security"],
    platform: ["Azure"],
    labLinks: [],
    partners: [
      // @ts-ignore
      {
        partner: "SentinelOne",
        logo: "https://int-shione-webapi-centralus-app.azurewebsites.net/api/Labs/logo/sites/asg-services/Logos/SentinelOne_Logo.png",
      },
    ],
    overview:
      "Competitive bake off between EDR offerings with apples-to-apples malware detection analysis demo.",
    deliverables: "Customer Demo",
    technology: "Endpoint Detection and Response",
    duration: "90 minutes",
  },
];
