import {
  AssessmentProgram,
  AssessmentsClient,
  PlatformInsightCapability,
  PlatformInsightProduct,
} from "@lib/ShiOneClient";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";
import useAssessmentDemoData from "@features/assessments-feature/hooks/useAssessmentDemoData";
import { useQuery } from "@tanstack/react-query";
import { CACHE_KEY_ASSESSMENTS_PLATFORM_INSIGHTS } from "@features/assessments-feature/constants";
import ms from "ms";

const api = new AssessmentsClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT,
);

const useGetAssessmentPlatformInsights = (
  assessmentProgram?: AssessmentProgram,
) => {
  const { setDemoMode, isDemoMode } = useAssessmentDemoData();

  function groupByPartnerAndProduct(data: PlatformInsightProduct[]) {
    const groupedData: { [key: string]: any } = {};
    if (!data.length) return null;
    data.forEach((item) => {
      const partnerName = item.partnerName || "Unknown";

      if (!groupedData[partnerName]) {
        groupedData[partnerName] = [];
      }

      groupedData[partnerName].push(item);
    });
    return groupedData;
  }

  const queryFn = async () => {
    if (!assessmentProgram) return null;
    if (isDemoMode.get(assessmentProgram)) return null;
    const result = api.getPlatformInsights(assessmentProgram);
    return result
      .then((r) => {
        return groupByPartnerAndProduct(r.products ?? []);
      })
      .catch((except) => {
        if (except.status === 404) {
          setDemoMode(true, assessmentProgram);
          return null;
        }
      });
  };
  return useQuery<{ [key: string]: any }, Error>({
    queryKey: [CACHE_KEY_ASSESSMENTS_PLATFORM_INSIGHTS, assessmentProgram],
    queryFn: async () => await queryFn().then(),
    staleTime: ms("1m"),
    enabled: !!assessmentProgram && !isDemoMode.get(assessmentProgram),
    placeholderData: {
      loadingPartner: [
        new PlatformInsightProduct({
          productId: "loading",
          productName: "loading product name",
          platformName: "platform name",
          capabilities: [
            new PlatformInsightCapability({
              capabilityId: "loading",
              capabilityName: "loading",
            }),
          ],
        }),
      ],
    },
  });
};

export default useGetAssessmentPlatformInsights;
