import React, { useMemo } from "react";
import {
  GridColDef,
  GridGroupNode,
  GridTreeDataGroupingCell,
  GridRenderCellParams,
  GridColumnHeaderParams,
  DataGridProProps,
} from "@mui/x-data-grid-pro";
import { Button, Typography } from "@mui/material";
import LinkBehavior from "shared-ui/src/theme/overrides/LinkBehavior";
import { AppendIfPrefixExists } from "../../../../../../modules/sitePaths";
import { assessmentTabIds } from "@features/assessments-feature/constants";
import MuiDataGrid from "../../../../../../components/mui-data-grid/MuiDataGrid";
import { ResponseCategory } from "@lib/ShiOneClient";
import Box from "@mui/material/Box";
import MaturityLabelWithIcon from "@features/assessments-feature/components/maturity-chart/MaturityLabelWithIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useAssessmentExportContext } from "@features/assessments-feature/contexts-providers/AssessmentExportContextProvider";

const MaturityChartGrid = ({
  responseCategories,
}: {
  responseCategories?: ResponseCategory[];
}) => {
  const navigate = useNavigate();
  const { program } = useParams();

  const { isPrint } = useAssessmentExportContext();

  const formattedData: any[] = [];
  let capabilityIndex = 0;
  let groupingCells = 0;
  responseCategories?.forEach((category) => {
    groupingCells++;
    category.responseSubcategories?.forEach((sub) => {
      groupingCells++;
      formattedData.push(
        ...(sub.responseCapabilities
          ? sub.responseCapabilities.map((cap) => {
              const newData = {
                id: capabilityIndex,
                hierarchy: [category.name, sub.name, capabilityIndex],
                name: cap.name,
                maturity: cap.maturity,
                tools: cap.tools,
                hasRecommendation: cap.hasRecommendation,
              };
              capabilityIndex++;
              return newData;
            })
          : []),
      );
    });
  });

  const groupingColDef = {
    headerName: "Category",
    minWidth: 80,
    flex: 0.15,
    hideable: false,
    disableReorder: true,
    renderHeader: (params: GridColumnHeaderParams) => (
      <Typography component={"div"} variant={"subtitle2"} pl={1}>
        {params.colDef.headerName}
      </Typography>
    ),
    renderCell: (
      params: GridRenderCellParams<any, any, any, GridGroupNode>,
    ) => {
      if (params.rowNode.type !== "group") {
        return null;
      } else {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              ".MuiDataGrid-treeDataGroupingCell": { width: "unset" },
            }}
          >
            <GridTreeDataGroupingCell {...params} hideDescendantCount />
            <Typography component={"span"} variant={"body2"} ml={0.5}>
              ({params.rowNode.children.length})
            </Typography>
          </Box>
        );
      }
    },
  } as DataGridProProps["groupingColDef"];

  const gridDefinitions = useMemo(
    () => ({
      columnDefinitions: [
        {
          field: "name",
          headerName: "Capability",
          valueGetter: (value) => (value !== "" ? value : "N/A"),
          minWidth: 200,
          flex: 0.2,
        },
        {
          field: "maturity",
          headerName: "Maturity",
          minWidth: 150,
          flex: 0.1,
          renderCell: (params) => {
            if (params.value)
              return <MaturityLabelWithIcon maturityValue={params.value} />;
            else return null;
          },
        },
        {
          field: "tools",
          headerName: "Known tools",
          valueGetter: (value: string[]) =>
            value?.length !== 0 ? value?.join(" / ") : "None",
          minWidth: 150,
          flex: 0.1,
        },
        {
          field: "hasRecommendation",
          headerName: "",
          sortable: false,
          minWidth: isPrint ? 100 : 200,
          renderCell: (params) => {
            if (params.value && !isPrint)
              return (
                <Button
                  variant={"text"}
                  size={"small"}
                  LinkComponent={LinkBehavior}
                  onClick={() =>
                    navigate(
                      AppendIfPrefixExists(
                        `/assessments/${program}/${assessmentTabIds.recommendations}`,
                      ),
                      {
                        state: {
                          capability: params.row.name,
                          openRecsDialog: true,
                        },
                      },
                    )
                  }
                >
                  View Recommendation
                </Button>
              );
            else return null;
          },
        },
      ] as GridColDef[],
      groupingColDef: groupingColDef,
    }),
    [],
  );

  return (
    <MuiDataGrid
      loading={!formattedData.length}
      treeData
      gridDefinitions={gridDefinitions}
      gridData={formattedData}
      defaultGroupingExpansionDepth={-1}
      hideFooter
      customTheme={{
        "& .MuiDataGrid-main .MuiDataGrid-virtualScroller": {
          overflowY: "visible",
        },
      }}
    />
  );
};

export default MaturityChartGrid;
