import { useMemo, useState } from "react";
import { Plugin } from "chart.js";

const useAdjustCategoriesChartPositions = () => {
  const [labelPosition, setLabelPosition] = useState<number[]>([]);
  const [labelHeight, setLabelHeight] = useState<number>(0);
  const [labelWidth, setLabelWidth] = useState<string[]>([]);

  const customLabelsPlugin: Plugin<"line"> = useMemo(
    () => ({
      id: "customLabels",
      afterDraw: (chart) => {
        const xAxis = chart.scales["x"];

        const xList = xAxis.ticks.map((_, index) =>
          xAxis.getPixelForTick(index)
        );
        const labelWidthList = xAxis.ticks.map((_, index) => {
          if (index === 0 || index === xAxis.ticks.length - 1) {
            return "48px";
          } else return `calc(${100 / xAxis.ticks.length}% - 10px)`;
        });
        const isLabelHeightChanged = labelHeight !== chart.height - 64;
        const isPosChanged =
          JSON.stringify(xList) !== JSON.stringify(labelPosition);
        const isLabelWidthChanged =
          JSON.stringify(labelWidth) !== JSON.stringify(labelWidthList);

        if (isPosChanged) {
          setLabelPosition(xList);
        }
        if (isLabelHeightChanged) {
          setLabelHeight(chart.height - 64);
        }
        if (isLabelWidthChanged) {
          setLabelWidth(labelWidthList);
        }
      },
    }),
    []
  );

  return {
    customLabelsPlugin,
    labelHeight,
    labelPosition,
    labelWidth,
  };
};

export default useAdjustCategoriesChartPositions;
