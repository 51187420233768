// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import { ExcelSheet } from "./types";

export enum excelSheetActions {
  add_excel = "add_excel",
  update_excel = "update_excel",
  delete_excel = "delete_excel",
  populate_excel = "populate_excel",
}

export enum locationPatterns {
  exact = "exactMatch",
  startsWith = "startsWith",
}

const validateObject = (object: ExcelSheet) => {
  switch (object.locationPattern) {
    case locationPatterns.exact:
      return {
        ...object,
        valid: true,
        locationKey: object.sourceSheetName,
      };
    case locationPatterns.startsWith: {
      if (object.locationKey.length > 0) {
        if (object.sourceSheetName.startsWith(object.locationKey)) {
          return {
            ...object,
            valid: true,
          };
        }
      }
      return {
        ...object,
        valid: false,
      };
    }
    default:
      return {
        ...object,
        valid: false,
      };
  }
};

export function excelSheetReducer(state: ExcelSheet[], action: any) {
  switch (action.type) {
    case "populate_excel": {
      return [
        ...state,
        {
          sourceSheetName: action.sourceSheetName,
          locationPattern: action.locationPattern,
          locationKey: action.locationKey,
          id: action.id,
          valid: true,
        },
      ];
    }
    case "add_excel": {
      return [
        ...state,
        {
          sourceSheetName: "",
          locationPattern: "",
          locationKey: "",
          id: uuidv4(),
          valid: false,
        },
      ];
    }
    case "update_excel": {
      return state.map((t) => {
        if (t.id === action.id) {
          const updateObject = {
            ...t,
            [action.name]: action.value,
          };
          return validateObject(updateObject);
        } else {
          return t;
        }
      });
    }
    case "delete_excel": {
      return state.filter((item: { id: string }) => item.id !== action.id);
    }
    default: {
      return state;
    }
  }
}
