import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import ForwardNavLink from "./ForwardRefNavLink";
import { AppendIfPrefixExists } from "../modules/sitePaths";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import MailIcon from "@mui/icons-material/Mail";
import SignOutMenuItem from "./SignOutMenuItem";
import React from "react";
import { useFeatureFlag } from "shared-ui/src/utils/FeatureFlagHelper";

export default function UserMenu({
  classes,
  contact,
  anchorEl,
  openUserProfileMenu,
  handleClose,
}: any) {
  const { enabled: messageCenterEnabled } = useFeatureFlag(
    "message-center-profile-navigation"
  );

  return (
    <Menu
      id="userProfileMenu"
      data-telemetry-name="Hide User Profile Menu"
      anchorEl={anchorEl}
      open={openUserProfileMenu && open}
      onClose={handleClose}
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      MenuListProps={{
        disablePadding: true,
      }}
    >
      <Box sx={[classes.menuHeader, classes.menuSmall]}>
        <Avatar sx={classes.avatar}>
          {contact.firstName.toUpperCase().charAt(0)}
          {contact.lastName.toUpperCase().charAt(0)}
        </Avatar>
        <div style={classes.info}>
          <Typography component="div" noWrap>
            {contact.firstName} {contact.lastName}
          </Typography>
          <Typography component="div" noWrap variant="caption">
            {contact.eMailAddress}
          </Typography>
        </div>
      </Box>
      <MenuList component="nav">
        {messageCenterEnabled && (
          <>
            {/* @ts-ignore ts not aware of component override */}
            <MenuItem
              component={ForwardNavLink}
              to={AppendIfPrefixExists("/message-center")}
            >
              <ListItemIcon sx={classes.menuIcon}>
                <MailIcon />
              </ListItemIcon>
              <span>Message Center</span>
            </MenuItem>
            <Divider />
          </>
        )}
        {/* @ts-ignore ts not aware of component override */}
        <MenuItem
          component={ForwardNavLink}
          to={AppendIfPrefixExists("/userprofile")}
          name="user-profile-button"
        >
          <ListItemIcon sx={classes.menuIcon}>
            <PersonIcon />
          </ListItemIcon>
          <span>My Profile</span>
        </MenuItem>
      </MenuList>
      <MenuList component="nav">
        {/* @ts-ignore ts not aware of component override */}
        <MenuItem
          component={ForwardNavLink}
          to={AppendIfPrefixExists("/organizationprofile")}
          name="user-profile-button"
        >
          <ListItemIcon sx={classes.menuIcon}>
            <BusinessIcon />
          </ListItemIcon>
          <span>Organization Profile</span>
        </MenuItem>
      </MenuList>
      <SignOutMenuItem classes={classes} />
    </Menu>
  );
}
