import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import HarveyBallMapKey from "@features/assessments-feature/components/spr-harveyball/HarveyBallMapKey";
import ShiLogo from "@images/shi-logo.svg";
import {
  featureFunctionalityCharts,
  FeatureFunctionalityChartType,
} from "@features/assessments-feature/helpers/assessmentCapabilityInsightsHelper";
import FeatureFunctionalityRadarChart from "@features/assessments-feature/components/capability-insights/FeatureFunctionalityRadarChart";
import FeatureFunctionalityLineChart from "@features/assessments-feature/components/capability-insights/FeatureFunctionalityLineChart";

import {
  CapabilityInsightsProduct,
  CapabilityWithCategories,
} from "@lib/ShiOneClient";

const FeatureFunctionalityCharts = ({
  data,
  productsList,
}: {
  data?: CapabilityWithCategories;
  productsList: (CapabilityInsightsProduct | undefined)[];
}) => {
  const [currentChart, setCurrentChart] =
    useState<FeatureFunctionalityChartType>(
      FeatureFunctionalityChartType.Radar
    );
  const handleCurrentChartType = (value: FeatureFunctionalityChartType) => {
    setCurrentChart(value);
  };
  const cleanProductsList = productsList.filter(
    (product): product is CapabilityInsightsProduct => product !== undefined
  );
  return (
    <Box display={"flex"} flexDirection={"column"} p={2}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={4} container>
          <Typography>Feature Functionality</Typography>
        </Grid>
        <Grid
          item
          xs={8}
          container
          justifyContent={"end"}
          alignItems={"center"}
          gap={4}
        >
          <img alt="shi-logo" src={ShiLogo} width={"48px"} />
          <HarveyBallMapKey />
          <Grid item>
            <ButtonGroup color="inherit">
              {featureFunctionalityCharts.map((chart) => (
                <Tooltip key={chart.value} title={chart.label}>
                  <Button
                    key={chart.value}
                    onClick={() => handleCurrentChartType(chart.value)}
                    sx={{
                      color:
                        currentChart === chart.value
                          ? "secondary.main"
                          : "text.secondary",
                    }}
                  >
                    {chart.icon}
                  </Button>
                </Tooltip>
              ))}
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>

      <Box height={520} p={2} mt={1}>
        {data && productsList.length > 0 ? (
          <>
            {currentChart === FeatureFunctionalityChartType.Radar && (
              <FeatureFunctionalityRadarChart
                productsList={cleanProductsList ?? []}
                categoriesList={data.harveyBallCategories ?? []}
              />
            )}
            {currentChart === FeatureFunctionalityChartType.Bar && (
              <FeatureFunctionalityLineChart
                productsList={cleanProductsList ?? []}
                categoriesList={data.harveyBallCategories ?? []}
              />
            )}
          </>
        ) : (
          <Typography
            display={"flex"}
            height={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            variant={"h5"}
            color={"textSecondary"}
          >
            No data to display. Please select comparing products
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default FeatureFunctionalityCharts;
