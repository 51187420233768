import React from "react";
import ProductItems from "@features/assessments-feature/components/platform-insights/ProductItems";
import { groupProductsByCapability } from "@features/assessments-feature/helpers/assessmentPlatformInsightsHelper";
import { PlatformInsightProduct } from "@lib/ShiOneClient";
import { List } from "@mui/material";

const ProductItemsContainer = ({
  productList,
  loading,
  expandAll,
}: {
  loading: boolean;
  productList: PlatformInsightProduct[];
  expandAll?: boolean;
}) => {
  const groupedProductList = groupProductsByCapability(productList);

  if (groupedProductList.length > 0) {
    return (
      <List disablePadding>
        {groupedProductList?.map((product) => (
          <ProductItems
            key={product.productId}
            expandAll={expandAll}
            productData={product}
            loading={loading}
          />
        ))}
      </List>
    );
  } else return null;
};

export default ProductItemsContainer;
