import React from "react";
import {
  Typography,
  List,
  ListItemText,
  ListItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Link,
  SxProps,
} from "@mui/material";
import {
  capabilitiesImpact,
  capabilitiesPriorities,
} from "@features/assessments-feature/constants";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import { useAssessmentExportContext } from "@features/assessments-feature/contexts-providers/AssessmentExportContextProvider";
import { SkeletonWrapper } from "shared-ui";
import {
  PrioritizedRecommendation,
  ResponseRecommendation,
} from "@lib/ShiOneClient";
import { impactColor } from "@features/assessments-feature/utils/assessmentsColors";

const tableHeaderStyle = (isPrint: boolean): SxProps | undefined => ({
  zIndex: 1,
  position: isPrint ? "static" : "sticky",
  top: isPrint ? undefined : -24,
  bgcolor: "table.header",
  minWidth: "200px",
});

const PrioritiesRecsTable = ({
  sortedPrioritizedRecs,
  handleRecsDialogOpenState,
  loading,
}: {
  sortedPrioritizedRecs: PrioritizedRecommendation[] | undefined;
  handleRecsDialogOpenState: (
    recsValue: ResponseRecommendation | undefined,
    open: boolean,
  ) => void;
  loading: boolean;
}) => {
  const { isPrint } = useAssessmentExportContext();

  const handleGetCapability = (priority: string, impact: string) => {
    const priorityRes = sortedPrioritizedRecs?.find(
      (data) => data.priority === priority,
    )?.responseRecommendations;
    const impactRes =
      priorityRes?.filter((res) => res.impact === impact) ?? null;
    if (impactRes?.length) {
      return (
        <List dense>
          {impactRes?.map((res) => (
            <ListItem key={res.capabilityId}>
              <ListItemText
                primary={
                  <Link
                    component={"button"}
                    onClick={() =>
                      isPrint ? undefined : handleRecsDialogOpenState(res, true)
                    }
                  >
                    {res.capability}
                  </Link>
                }
                secondary={res.recommendationsSummary}
                primaryTypographyProps={{ variant: "subtitle1" }}
              />
            </ListItem>
          ))}
        </List>
      );
    }
    return (
      <Typography p={2} color={"textSecondary"}>
        <SkeletonWrapper loading={loading}>None</SkeletonWrapper>
      </Typography>
    );
  };

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Table sx={{ border: (theme) => "1px solid" + theme.palette.border }}>
        <TableHead>
          <TableRow>
            <TableCell> </TableCell>
            <TableCell
              sx={{
                ...tableHeaderStyle(isPrint),
                border: (theme) => "1px solid" + theme.palette.border,
              }}
            >
              <Typography variant={"subtitle1"}>
                {capabilitiesPriorities.doNow} (Short Term)
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                ...tableHeaderStyle(isPrint),
                minWidth: "200px",
              }}
            >
              <Typography variant={"subtitle1"} p={1}>
                {capabilitiesPriorities.doLater} (Long Term)
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              sx={{
                minWidth: "4.5rem",
                backgroundColor:
                  impactColor[capabilitiesImpact.highImpact.toLowerCase()],
                textAlign: "center",
              }}
            >
              <Typography
                color={"white"}
                variant="subtitle1"
                py={2}
                ml={-4}
                mr={-4}
                sx={{ transform: "rotate(-90deg)" }}
              >
                {capabilitiesImpact.highImpact}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                bgcolor: "background.paper",
                verticalAlign: "top",
              }}
            >
              <Typography
                color={"black"}
                minHeight={isPrint ? 80 : 160}
                minWidth={320}
                component={"div"}
              >
                {handleGetCapability(
                  capabilitiesPriorities.doNow,
                  capabilitiesImpact.highImpact,
                )}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                border: (theme) => "1px solid" + theme.palette.border,

                bgcolor: "background.paper",
                verticalAlign: "top",
              }}
            >
              <Typography minWidth={320} component={"div"} color={"black"}>
                {handleGetCapability(
                  capabilitiesPriorities.doLater,
                  capabilitiesImpact.highImpact,
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              sx={{
                backgroundColor:
                  impactColor[capabilitiesImpact.lowImpact.toLowerCase()],
                borderTop: "none",
              }}
            >
              <Typography
                color={"white"}
                variant="subtitle1"
                py={2}
                ml={-4}
                mr={-4}
                sx={{ transform: "rotate(-90deg)" }}
              >
                {capabilitiesImpact.lowImpact}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                bgcolor: "background.paper",
                verticalAlign: "top",
              }}
            >
              <Typography
                minHeight={isPrint ? 80 : 160}
                component={"div"}
                color={"black"}
              >
                {handleGetCapability(
                  capabilitiesPriorities.doNow,
                  capabilitiesImpact.lowImpact,
                )}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                border: (theme) => "1px solid" + theme.palette.border,
                bgcolor: "background.paper",
                verticalAlign: "top",
              }}
            >
              <Typography minWidth={320} component={"div"} color={"black"}>
                {handleGetCapability(
                  capabilitiesPriorities.doLater,
                  capabilitiesImpact.lowImpact,
                )}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default PrioritiesRecsTable;
