import { LabDto } from "@lib/ShiOneClient";
import { Box, CardHeader, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LabPracticeTitleData } from "@features/labs-feature/components/LabCards";
import { LogoContainer } from "@features/labs-feature/components/LogoContainer";

interface LabCardHeaderProps {
  lab: LabDto;
  practice: string;
  labPracticeTitleData: LabPracticeTitleData[];
}
const LabCardHeader: React.FC<LabCardHeaderProps> = ({
  lab,
  practice,
  labPracticeTitleData,
}) => {
  const theme = useTheme();
  const [practiceHeaderColor, setPracticeHeaderColor] = useState<string>("");

  useEffect(() => {
    const headerData = labPracticeTitleData.find(
      (item) => item.title === practice.trim()
    );
    const practiceHeaderColor = headerData?.color;
    setPracticeHeaderColor(practiceHeaderColor!);
  }, []);

  return (
    <CardHeader
      key={`${lab.id}-${practice}`}
      title={
        <Box
          key={`${lab.id}-${practice}`}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            color={practiceHeaderColor}
            variant="caption"
            sx={{
              display: "flex",
              fontStyle: "italic",
            }}
          >
            {lab.practice}
          </Typography>

          <Box key={`${lab.id}-${practice}`}>
            <LogoContainer selectedLab={lab} maxIconsToDisplay={1} />
          </Box>
        </Box>
      }
      subheader={
        <>
          <Typography
            key={`${lab.id}-${practice}`}
            color={theme.palette.text.primary}
            component="div"
            variant="body2"
            sx={{
              marginTop: 1,
              minHeight: 40,
              fontSize: "15px",
            }}
          >
            {lab.productName}
          </Typography>
        </>
      }
    />
  );
};

export default LabCardHeader;
