import React from "react";
import Box from "@mui/material/Box";
import AssessmentOverviewSummary from "@features/assessments-feature/components/AssessmentOverviewSummary";

const SummaryExport = () => {
  return (
    <Box className={"assessment-break"}>
      <AssessmentOverviewSummary />
    </Box>
  );
};

export default SummaryExport;
