import React from "react";
import { PageLayoutWidgetDto } from "@lib/ShiOneClient";
import { WidgetWrapper } from "shared-ui";
import useGetAssessments from "@features/assessments-feature/hooks/useGetAssessments";
import {
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import LinkBehavior from "shared-ui/src/theme/overrides/LinkBehavior";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import assessmentViewsMap from "@features/assessments-feature/utils/assessmentViewsMap";

const CompletedAssessmentsWidget = ({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) => {
  const { data: assessmentData, isLoading, isError } = useGetAssessments();
  const assessmentList = Array.from(assessmentViewsMap.values());
  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      noPadding
      error={isError}
      isEmpty={assessmentData?.length === 0 || isError}
      cardHeaderDivider
    >
      <List disablePadding sx={{ maxHeight: 248, overflow: "auto" }}>
        {isLoading && (
          <Grid container p={2} flexDirection={"column"} gap={1}>
            <Skeleton variant={"rounded"} width={"50%"} height={14} />
            <Skeleton variant={"rounded"} width={"80px"} height={12} />
          </Grid>
        )}

        {assessmentData?.length ? (
          assessmentData?.map((assessment) => {
            const currentCompleted = assessmentList.find(
              (item) => item.name === assessment.program,
            );
            return (
              <ListItem key={assessment.id} divider>
                <ListItemText
                  primary={
                    <Link
                      component={LinkBehavior}
                      href={AppendIfPrefixExists(
                        currentCompleted?.url
                          ? `${currentCompleted?.url}`
                          : "/assessments/",
                      )}
                      variant={"body1"}
                    >
                      {assessment.program}
                    </Link>
                  }
                  secondary={
                    assessment.createdDate
                      ? `Created on ${DateTime.fromJSDate(assessment.createdDate).toLocaleString()}`
                      : undefined
                  }
                  secondaryTypographyProps={{ variant: "body2" }}
                />
              </ListItem>
            );
          })
        ) : (
          <div className={"flex justify-center items-center"}>
            <Typography color={"textSecondary"} p={2}>
              No completed assessments to view
            </Typography>
          </div>
        )}
      </List>
    </WidgetWrapper>
  );
};

export default CompletedAssessmentsWidget;
