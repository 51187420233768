import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import FeatureFunctionalityCharts from "@features/assessments-feature/components/capability-insights/FeatureFunctionalityCharts";
import {
  CapabilityInsightsProduct,
  CapabilityWithCategories,
} from "@lib/ShiOneClient";
import ErrorIcon from "@mui/icons-material/Error";
import ComparingProductsAutocomplete from "@features/assessments-feature/components/capability-insights/ComparingProductsAutocomplete";
import { useAssessmentExportContext } from "@features/assessments-feature/contexts-providers/AssessmentExportContextProvider";

const ComparingProductsContainer = ({
  error,
  loading,
  currentCapability,
}: {
  error: boolean;
  loading: boolean;
  currentCapability?: CapabilityWithCategories;
}) => {
  const { isPrint } = useAssessmentExportContext();
  const [comparingProducts, setComparingProducts] = useState<string[]>([]);
  const productsMap = new Map<string, CapabilityInsightsProduct>();

  const productOptions: string[] = [];

  useEffect(() => {
    if (currentCapability?.knownProductIds) {
      const matchingProducts = productOptions.filter((product) =>
        currentCapability?.knownProductIds?.includes(product),
      );

      setComparingProducts(matchingProducts);
    }
  }, [currentCapability]);

  currentCapability?.products?.forEach((product) => {
    if (product.productId) {
      productOptions.push(product.productId);
      productsMap.set(product.productId, product);
    }
  });

  const handleComparingProducts = (event: any, value: string[]) => {
    setComparingProducts(value);
  };

  return (
    <Box
      bgcolor={"background.paper"}
      border={(theme) => "1px solid" + theme.palette.border}
      borderRadius={1}
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
    >
      <Grid container px={2} gap={1} alignItems={"center"} width={"100%"}>
        <Grid item xs={"auto"}>
          <Typography py={2} textTransform={"uppercase"}>
            Comparing
          </Typography>
        </Grid>

        <Grid
          item
          xs
          container
          py={isPrint ? 0 : 1}
          flexWrap={"nowrap"}
          alignItems={"center"}
          width={"100%"}
        >
          {loading ? (
            <Skeleton width={"30%"} />
          ) : (
            <>
              {error && (
                <Tooltip title={"There is an error loading the data"}>
                  <ErrorIcon color="error" />
                </Tooltip>
              )}
              <ComparingProductsAutocomplete
                value={comparingProducts}
                options={productOptions}
                productsMap={productsMap}
                handleComparingProducts={handleComparingProducts}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Divider />
      <FeatureFunctionalityCharts
        data={currentCapability}
        productsList={comparingProducts.map((product) =>
          productsMap.get(product),
        )}
      />
    </Box>
  );
};

export default ComparingProductsContainer;
