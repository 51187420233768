import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Markdown from "react-markdown";
import CloseIcon from "@mui/icons-material/Close";
import { ResponseRecommendation } from "@lib/ShiOneClient";
import Box from "@mui/material/Box";
import ProductLogosList from "@features/assessments-feature/components/priorities-recommendations/ProductLogosList";
import ColoredChips from "shared-ui/src/theme/extensions/ColoredChips";
import { impactColor } from "@features/assessments-feature/utils/assessmentsColors";
import { useLocation, useNavigate } from "react-router-dom";

const InfoContainer = ({
  children,
  label,
}: {
  children: React.ReactNode;
  label: string;
}) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Typography variant={"subtitle1"}>{label}</Typography>
      <div>{children}</div>
    </Box>
  );
};
const RecommendationsDialog = ({
  openRecsDialog,
  currentRecs,
  handleRecsDialog,
}: {
  openRecsDialog: boolean;
  currentRecs: ResponseRecommendation | undefined;
  handleRecsDialog: (
    recsValue: ResponseRecommendation | undefined,
    isOpen: boolean
  ) => void;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Dialog
      open={openRecsDialog}
      onClose={() => {
        handleRecsDialog(undefined, false);
        location?.state && navigate(location.pathname, { replace: true });
      }}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle>
        <Grid container gap={1} alignItems={"center"} paddingRight={3}>
          <ColoredChips
            bgColor={impactColor[currentRecs?.impact?.toLowerCase() ?? ""]}
            label={currentRecs?.impact}
          />
          {currentRecs?.capability}
        </Grid>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          handleRecsDialog(undefined, false);
          location?.state && navigate(location.pathname, { replace: true });
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid container direction={"column"} gap={3}>
          {currentRecs?.knownTools?.length !== 0 && (
            <InfoContainer label={"Known tools"}>
              <ProductLogosList
                tools={currentRecs?.knownTools}
                toolsLogos={currentRecs?.knownToolsLogos}
              />
            </InfoContainer>
          )}
          {currentRecs?.otherTools?.length !== 0 && (
            <InfoContainer label={"Tools"}>
              <ProductLogosList
                tools={currentRecs?.otherTools}
                toolsLogos={currentRecs?.otherToolsLogos}
              />
            </InfoContainer>
          )}
          <Grid item>
            <Markdown>{currentRecs?.recommendations}</Markdown>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RecommendationsDialog;
