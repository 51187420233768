import { NavItemType } from "../../NavigationTypes";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useClaims } from "../../../../../../auth/ClaimsProvider";
import { ShiOnePermission } from "../../../../../../auth/authorizationTypes";

export function ReportCenterMenu(): NavItemType {
  const { hasPermission } = useClaims();
  return {
    id: "reportCenter",
    type: "Dashboard",
    displayName: "Report Center",
    path: "/report-center",
    icon: <AssessmentIcon />,
    children: [],
    visible: hasPermission(ShiOnePermission.reportRead),
  };
}
