import React from "react";
import SHIFilePond from "shared-ui/src/components/SHIFilePond";

export default function FileUpload({
  files,
  setFiles,
  setUploadResponse,
  token,
}: {
  files: any;
  setFiles: any;
  setUploadResponse: any;
  token: string;
}) {
  const filePondServer = {
    server: {
      url: `${import.meta.env.API_ENDPOINT}/api/MappingEngine`,
      timeout: 230000,
      process: {
        method: "POST" as const,
        withCredentials: false,
        timeout: 230000,
        onload: (response: string) => {
          const jsonResponse = JSON.parse(response);
          setUploadResponse(jsonResponse);
          return jsonResponse.guid;
        },
        onerror: (response: { data: any }) => response.data,
        ondata: (formData: any) => formData,
        headers: { Authorization: "Bearer " + token },
        revert: { url: "", headers: { Authorization: "Bearer " + token } },
      },
    },
  };

  return (
    <div className="mb-8 mt-4">
      <SHIFilePond
        server={filePondServer.server}
        setFiles={setFiles}
        files={files}
      />
    </div>
  );
}
