import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { widgetStyles } from "shared-ui";
import ShiLogo from "shared-ui/src/assets/shi-square-logo.svg?react";

const WelcomeWidget = () => {
  const theme = useTheme();
  const styles = widgetStyles(theme);
  return (
    <Card sx={styles.card}>
      <CardHeader
        title={"Welcome,"}
        titleTypographyProps={{ variant: "subtitle1" }}
      />
      <CardContent>
        <Grid container gap={3} px={2} pb={2}>
          <Grid item xs>
            <Typography>
              You have been invited to explore some features in SHI One.
            </Typography>
            <Typography variant={"body2"} color={"textSecondary"} mt={2}>
              Your organization is not yet fully onboarded to access all
              features. Please reach out to your account executive to request
              that an onboarding link be sent to your IT administrator.
            </Typography>
          </Grid>
          <Grid item xs={"auto"}>
            <SvgIcon
              sx={{ width: "6rem", height: "6rem" }}
              component={ShiLogo}
              inheritViewBox
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WelcomeWidget;
