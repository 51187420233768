import { CapabilityAreas } from "@lib/ShiOneClient";
import { capabilityAreasColors } from "@features/assessments-feature/utils/assessmentsColors";

const handleCapabilityName = (capability: string) => {
  let capabilityCamelCase = capability.replace(/([A-Z])/g, " $1").split(" ");
  // Capitalize the first letter of each word
  return capabilityCamelCase
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const handleConvertDataToLineVisual = (data?: CapabilityAreas) => {
  const capabilities: CapabilityAreas = data ?? new CapabilityAreas({});
  return Object.keys(capabilities).map((area, index) => ({
    color: capabilityAreasColors[index],
    label: handleCapabilityName(area),
    name: "Capability Areas",
    // @ts-ignore
    data: capabilities?.[area] ?? 0,
  }));
};
