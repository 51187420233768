import React from "react";
import { NoContent, WidgetWrapper } from "shared-ui";
import { PageLayoutWidgetDto } from "@lib/ShiOneClient";
import { Link, List, ListItem, ListItemText, Skeleton } from "@mui/material";
import { useGetTechnologyGroupList } from "@features/labs-feature/hooks/useLabs";
import {
  labPracticePalette,
  LabPracticeTitleData,
} from "@features/labs-feature/components/LabCards";
import ScienceIcon from "@mui/icons-material/Science";
import { NavLink } from "react-router-dom";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";

const ExploreLabsWidget = ({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) => {
  const {
    data: uniqueLabPractices,
    isLoading,
    isError,
  } = useGetTechnologyGroupList();
  let labTgData: LabPracticeTitleData[] = [];
  if (uniqueLabPractices && uniqueLabPractices?.length > 0) {
    const practiceData: LabPracticeTitleData[] = [];
    uniqueLabPractices.forEach((practice, index) => {
      practiceData[index] = {
        color: labPracticePalette[index],
        title: practice,
      };
    });
    labTgData = practiceData;
  }

  const loadingSkeletons = () => {
    let listItemsArray = [];
    for (let i = 0; i <= 5; i++) {
      listItemsArray.push(
        <ListItem key={i} divider>
          <Skeleton width={100 * Math.random() * 2} />
        </ListItem>
      );
    }
    return listItemsArray;
  };

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      noPadding
      cardHeaderDivider
      isEmpty={!isLoading && labTgData?.length === 0}
    >
      <List disablePadding className="overflow-auto h-[232px]">
        {isLoading ? (
          loadingSkeletons()
        ) : labTgData?.length ? (
          labTgData.map((tg) => (
            <ListItem
              key={tg.title}
              divider
              sx={{ borderLeft: "5px solid", borderLeftColor: tg.color }}
            >
              <ListItemText
                primary={
                  <Link
                    component={NavLink}
                    to={AppendIfPrefixExists("/labs")}
                    state={{ technologyGroup: tg.title }}
                  >
                    {tg.title}
                  </Link>
                }
              />
            </ListItem>
          ))
        ) : (
          <NoContent
            icon={<ScienceIcon />}
            containerHeight={232}
            body={isError ? "There's an error loading the data" : ""}
            actionText=""
          />
        )}
      </List>
    </WidgetWrapper>
  );
};

export default ExploreLabsWidget;
