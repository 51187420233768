import { Radar } from "react-chartjs-2";
import { CapabilityInsightsProduct } from "@lib/ShiOneClient";
import { goodToBadColors, graphColors } from "shared-ui/src/theme/shiColors";
import {
  Chart,
  RadialLinearScale,
  PolarAreaController,
  LineElement,
  PointElement,
  Tooltip,
  Filler,
} from "chart.js";
import { alpha, useTheme } from "@mui/material";
import { getHarveyBall } from "@features/assessments-feature/utils/assessmentHarveyBall";

Chart.register(
  RadialLinearScale,
  PolarAreaController,
  LineElement,
  PointElement,
  Tooltip,
  Filler
);
const FeatureFunctionalityRadarChart = ({
  productsList,
  categoriesList,
}: {
  productsList: CapabilityInsightsProduct[];
  categoriesList: string[];
}) => {
  const theme = useTheme();
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        beginAtZero: true,
        max: 5,
        min: 0,
        grid: { color: theme.palette.action.disabled },
        angleLines: {
          color: theme.palette.action.disabled,
          borderDashOffset: 10,
        },
        ticks: {
          stepSize: 1,
          font: {
            size: 32,
            family: "Arial Unicode MS",
            lineHeight: 1,
          },
          color: [
            "gray",
            goodToBadColors.bad,
            goodToBadColors.warning,
            goodToBadColors.ok,
            goodToBadColors.good,
            goodToBadColors.best,
          ],
          callback: function (value: string | number) {
            return `${getHarveyBall(value.toString())}    `;
          },
          z: 1,
          showLabelBackdrop: false,
        },
        pointLabels: {
          font: {
            size: 12,
          },
          color: theme.palette.text.secondary,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },

    parsing: {
      key: "averageFeatureScore",
    },
  };
  const radarData = {
    labels: categoriesList,
    datasets: productsList.map((product, index) => ({
      label: product.productName,
      data: product.featureScoresList,
      fill: true,
      backgroundColor: alpha(graphColors[index], 0.5),
      borderColor: alpha(graphColors[index], 0.5),
      borderWidth: 1,
      pointBackgroundColor: graphColors[index],
      pointBorderColor: graphColors[index],
    })),
  };
  return <Radar data={radarData} options={options} />;
};
export default FeatureFunctionalityRadarChart;
