import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PageLayoutWidgetDto } from "@lib/ShiOneClient";
import { WidgetWrapper } from "shared-ui";
import CircleIcon from "@mui/icons-material/Circle";
import assessmentViewsMap from "@features/assessments-feature/utils/assessmentViewsMap";
import Fade from "@mui/material/Fade";
import OnePagerDialog from "../../components/OnePagerDialog";

const AboutAssessmentsWidget = ({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) => {
  const assessmentInfo = Array.from(assessmentViewsMap.values());
  const [openOnePager, setOpenOnePager] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const timedActive = setInterval(() => {
      if (activeIndex < assessmentInfo.length - 1) {
        setActiveIndex(activeIndex + 1);
      } else {
        setActiveIndex(0);
      }
    }, 10000);
    return () => {
      clearInterval(timedActive);
    };
  }, [activeIndex]);

  return (
    <>
      <WidgetWrapper pageLayoutWidget={pageLayoutWidget} noPadding>
        <Box
          display="flex"
          flexDirection={"column"}
          position={"relative"}
          height={180}
        >
          <Fade
            key={activeIndex}
            in={activeIndex !== undefined}
            timeout={{ enter: 500, exit: 0 }}
          >
            <Box width={"78%"} px={2} flex={1} overflow={"auto"}>
              <Typography variant={"subtitle1"}>
                {assessmentInfo[activeIndex]?.name}
              </Typography>
              <Typography variant={"body2"} my={1}>
                {assessmentInfo[activeIndex]?.description}
              </Typography>
              {assessmentInfo[activeIndex]?.promoUrl && (
                <Button onClick={() => setOpenOnePager(true)}>More info</Button>
              )}
            </Box>
          </Fade>
          <Stack
            marginTop={"auto"}
            mb={2.5}
            direction="row"
            justifyContent={"center"}
            sx={{ "& svg": { fontSize: "10px" } }}
          >
            {assessmentInfo.map((assessment, index) => (
              <IconButton
                size={"small"}
                edge={"end"}
                key={assessment.id}
                color={index === activeIndex ? "primary" : "default"}
                onClick={() => setActiveIndex(index)}
              >
                <CircleIcon />
              </IconButton>
            ))}
          </Stack>

          <Box
            position={"absolute"}
            height={"108%"}
            width={"45%"}
            bottom={0}
            right={0}
            sx={{
              background: `url(${assessmentInfo[activeIndex]?.promoPic})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              WebkitMaskImage:
                "radial-gradient(ellipse at bottom right, black 10%, transparent 72%, transparent 100%)",
              maskImage:
                "radial-gradient(ellipse at bottom right, black 10%, transparent 72%, transparent 100%)",
            }}
          />
        </Box>
      </WidgetWrapper>
      {openOnePager && (
        <OnePagerDialog
          pdf={assessmentInfo[activeIndex]?.promoUrl ?? ""}
          dialogOpen={openOnePager}
          setDialogOpen={setOpenOnePager}
        />
      )}
    </>
  );
};

export default AboutAssessmentsWidget;
