import { create } from "zustand";
import { AssessmentProgram } from "@lib/ShiOneClient";

const isDemoModeProgram: Map<AssessmentProgram, boolean> = new Map<
  AssessmentProgram,
  boolean
>([
  [AssessmentProgram.SecurityPostureReview, false],
  [AssessmentProgram.AiReadiness, false],
]);

type SprDemoState = {
  isDemoMode: Map<AssessmentProgram, boolean>;
  setDemoMode: (value: boolean, program: AssessmentProgram) => void;
};

const useAssessmentDemoData = create<SprDemoState>((set) => ({
  isDemoMode: isDemoModeProgram,
  setDemoMode: (value: boolean, program: AssessmentProgram) =>
    set(() => ({ isDemoMode: isDemoModeProgram.set(program, value) })),
}));

export default useAssessmentDemoData;
