import React, { useEffect, useState } from "react";
import { Badge, Button, CircularProgress, Tooltip } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableFiltersDialog from "./TableFiltersDialog";
import _ from "lodash";
import ToolbarFilterChips from "../../v2/src/components/custom-toolbar-components/ToolbarFilterChips";
import { useTheme } from "@mui/material/styles";

const css = (theme) => ({
  toolbarChipsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    flex: "1 2",
    alignItems: "center",
    gap: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  toolbarActions: {
    color: theme.palette.text.secondary,
    flex: "0 0 auto",
  },
  progress: {
    margin: [[theme.spacing(10), "auto", 0, "auto"]],
    display: "block",
  },
});

TableFilters.whyDidYouRender = true;
export default function TableFilters(props) {
  const theme = useTheme();
  const classes = css(theme);
  const { filters, changeFilters, activeFiltersCount } = props;

  const [filterDialog, setFilterDialog] = useState(false);
  const [localActiveFiltersCount, setLocalActiveFiltersCount] = useState(0);

  useEffect(() => {
    if (!activeFiltersCount) {
      let count = 0;
      filters.forEach((filter) => {
        if (filter.datePicker && (filter.startDate || filter.endDate)) {
          count++;
        }
        if (!filter.datePicker && filter.value?.length) {
          count++;
        }
      });
      setLocalActiveFiltersCount(count);
    }
  }, [filters, activeFiltersCount]);

  const handleDialog = (value) => {
    setFilterDialog(value);
  };

  const handleChangeFilter = (e) => {
    const newArr = [...filters];
    const value = e.target.value;
    const index = e.target.name.split("-")[1];
    newArr[index] = Object.assign({}, newArr[index], {
      ...newArr[index],
      value,
    });

    changeFilters(newArr);
  };

  const handleChangeTypeaheadFilter = (value, index) => {
    const newArr = [...filters];

    newArr[index] = Object.assign({}, newArr[index], {
      ...newArr[index],
      value,
    });

    changeFilters(newArr);
  };

  const handleChangeDates = (filter, date) => {
    const newArr = [...filters];
    const dateType = filter.split("-")[0];
    const index = filter.split("-")[1];

    newArr[index] = Object.assign({}, newArr[index], {
      ...newArr[index],
      [dateType]: date,
    });

    changeFilters(newArr);
  };

  const handleChangeDateGroup = (groupName, isStart, date) => {
    const isValidDate = (date) => {
      return !isNaN(new Date(date).getTime());
    };
    if (!isValidDate(date)) return;

    const newArr = [...filters];
    let index = -1;

    if (isStart) {
      index = filters.findIndex(
        (filter) => filter.datePickerGroupName === groupName && filter.isStart
      );
    } else {
      index = filters.findIndex(
        (filter) => filter.datePickerGroupName === groupName && !filter.isStart
      );
    }

    if (index > -1) {
      newArr[index] = { ...newArr[index], value: date };
      changeFilters(newArr);
    }
  };

  const loading = _.isEmpty(filters);

  if (loading) {
    return (
      <>
        <div style={classes.toolbarActions}>
          <Tooltip followCursor title={"Loading Filters"}>
            <span>
              <Button color="inherit" size="small" disabled>
                <Badge
                  badgeContent={
                    <CircularProgress size={18} sx={{ color: "#253746" }} />
                  }
                  overlap="rectangular"
                >
                  <FilterListIcon />
                </Badge>
              </Button>
            </span>
          </Tooltip>
        </div>
      </>
    );
  }
  return (
    <>
      {activeFiltersCount === undefined && (
        <div style={classes.toolbarChipsContainer}>
          <ToolbarFilterChips
            handleFilters={changeFilters}
            filters={filters}
            activeFiltersCount={localActiveFiltersCount}
            setActiveFiltersCount={setLocalActiveFiltersCount}
          />
        </div>
      )}
      <div style={classes.toolbarActions}>
        <Tooltip followCursor title={"Apply Filters"}>
          <Button
            name={"Apply Filters"}
            color="inherit"
            size="small"
            aria-controls="filterDialog"
            aria-haspopup="true"
            onClick={() => handleDialog(true)}
            data-telemetry-name="Filter button"
            sx={{ minWidth: "40px", textAlign: "center" }}
          >
            {activeFiltersCount > 0 || localActiveFiltersCount > 0 ? (
              <Badge
                color={"secondary"}
                badgeContent={activeFiltersCount || localActiveFiltersCount}
                overlap="rectangular"
              >
                <FilterListIcon />
              </Badge>
            ) : (
              <FilterListIcon />
            )}
          </Button>
        </Tooltip>
      </div>
      <TableFiltersDialog
        filterDialog={filterDialog}
        filters={filters}
        handleChangeDates={handleChangeDates}
        handleChangeDateGroup={handleChangeDateGroup}
        handleChangeFilter={handleChangeFilter}
        handleDialog={handleDialog}
        handleChangeTypeaheadFilter={handleChangeTypeaheadFilter}
      />
    </>
  );
}
