import { Box, Card, Typography } from "@mui/material";
import { AssessmentProgram } from "@lib/ShiOneClient";

const fade = {
  opacity: 0.5,
  width: "100%",
  display: "flex",
};

export default function DemoModeAssessmentRecs({
  assessmentProgram,
}: {
  assessmentProgram: AssessmentProgram;
}) {
  let errorMessage: string;
  let suggestion: string | undefined;
  switch (assessmentProgram) {
    case AssessmentProgram.SecurityPostureReview:
      errorMessage = "Sorry there are no security recommendations at this time";
      suggestion =
        "Click 'Request Consultation' at the top of this page to engage\n" +
        "with SHI's security team and assess your security posture";
      break;
    case AssessmentProgram.AiReadiness:
      errorMessage =
        "Sorry there are no AI readiness recommendations at this time";
      break;
    default:
      errorMessage = "Sorry there's no assessment";
      break;
  }

  return (
    <Card>
      <Typography
        component="div"
        p={4}
        minHeight={(theme) => theme.spacing(40)}
        sx={fade}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant={"h4"} gutterBottom>
            {errorMessage}
          </Typography>
          {suggestion && (
            <Typography component="div" variant="caption" sx={{ width: "50%" }}>
              <Typography component={"div"} gutterBottom>
                Suggestions:
              </Typography>
              <Typography component={"ul"}>
                <li>{suggestion}</li>
              </Typography>
            </Typography>
          )}
        </Box>
      </Typography>
    </Card>
  );
}
