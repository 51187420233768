import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Item } from "./Item";

export function SortableItem(props: {
  id: any;
  children: any;
  className: any;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform) || "none", // Ensure transform is always valid
    transition: transition || "none", // Ensure transition is always valid
    cursor: "grab",
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={props?.className}
    >
      {props?.children}
    </Item>
  );
}
