import React, { useState } from "react";
import useGetAssessmentCapabilityInsights from "@features/assessments-feature/hooks/useGetAssessmentCapabilityInsights";
import { Grid } from "@mui/material";
import CapabilityInsightSelect from "@features/assessments-feature/components/capability-insights/CapabilityInsightSelect";
import ComparingProductsContainer from "@features/assessments-feature/components/capability-insights/ComparingProductsContainer";
import { CapabilityWithCategories } from "@lib/ShiOneClient";
import { useAssessmentExportContext } from "@features/assessments-feature/contexts-providers/AssessmentExportContextProvider";
import { useAssessmentViewContext } from "@features/assessments-feature/contexts-providers/AssessmentViewContextProvider";
import useAssessmentDemoData from "@features/assessments-feature/hooks/useAssessmentDemoData";

const AssessmentCapabilityInsights = () => {
  const { assessmentViewProps } = useAssessmentViewContext();
  const { isPrint } = useAssessmentExportContext();
  const { isDemoMode } = useAssessmentDemoData();
  const currentDemo =
    (assessmentViewProps && isDemoMode.get(assessmentViewProps.id)) ?? false;
  const { data, isLoading, isError } = useGetAssessmentCapabilityInsights(
    assessmentViewProps?.id,
  );

  const [capabilitySelect, setCapabilitySelect] = useState<string>("");

  let capabilitiesList: string[] = [];
  const CapabilitiesMap = new Map<string, CapabilityWithCategories>();

  data?.capabilityWithCategoriesList?.forEach((capability) => {
    if (capability.name && capability.hasHarveyBall) {
      capabilitiesList.push(capability.shortName || capability.name);
      CapabilitiesMap.set(capability.shortName || capability.name, capability);
    }
  });
  return (
    <Grid container direction="column" rowGap={isPrint ? 1 : 3}>
      <CapabilityInsightSelect
        error={isError || currentDemo}
        loading={!currentDemo && isLoading}
        capabilities={capabilitiesList}
        capabilityMap={CapabilitiesMap}
        capabilitySelect={capabilitySelect}
        setCapabilitySelectChange={setCapabilitySelect}
      />
      <ComparingProductsContainer
        error={isError || currentDemo}
        loading={!currentDemo && isLoading}
        currentCapability={CapabilitiesMap.get(capabilitySelect)}
      />
    </Grid>
  );
};

export default AssessmentCapabilityInsights;
