import React from "react";
import SampleWidgetA from "@widgets/sample/SampleWidgetA";
import SampleWidgetB from "@widgets/sample/SampleWidgetB";
import SampleWidgetC from "@widgets/sample/SampleWidgetC";
import AwsPotentialSavingsWidget from "@widgets/cloud/AwsPotentialSavingsWidget";
import SampleWidgetE from "@widgets/sample/SampleWidgetE";
import MyRequestsWidget from "@widgets/support/MyRequestsWidget";
import AssetsWarehouseStockWidget from "@widgets/assets/AssetsWarehouseStockWidget";
import KnowledgeBaseWidget from "@widgets/support/KnowledgeBaseWidget";
import OpenRequestsWidget from "@widgets/support/OpenRequestsWidget";
import TotalRequestsCompletedWidget from "@widgets/support/TotalRequestsCompletedWidget";
import CloudAccountsWidget from "@widgets/cloud/CloudAccountsWidget";
import ProjectsWidget from "@widgets/services/ProjectsWidget";
import AllRequestsWidget from "@widgets/support/AllRequestsWidget";
import { RequestMetricsWidget } from "@widgets/support";
import SupportOverviewWidget from "@widgets/support/SupportOverviewWidget";
import IssueRankingsWidget from "@widgets/support/IssueRankingsWidget";
import { RequesterRankingsWidget } from "@widgets/support/RequesterRankingsWidget";
import CompletedRequestsMeetingSlaWidget from "./support/CompletedRequestsMeetingSlaWidget";
import {
  CloudPotentialSavingsWidget,
  CurrentMonthCloudConsumptionWidget,
} from "@widgets/cloud";
import CloudConsumptionHistoryWidget from "@widgets/cloud/CloudConsumptionHistoryWidget";
import AssetsByUseStateWidget from "@widgets/assets/AssetsByUseStateWidget";
import AssetsWarehouseOrdersWidget from "@widgets/assets/AssetsWarehouseOrdersWidget";
import AssetsProductOverviewWidget from "@widgets/assets/AssetsProductOverviewWidget";
import AssetsExpiringContractsWidget from "@widgets/assets/AssetsExpiringContractsWidget";
import AssetsOverviewWidget from "@widgets/assets/AssetsOverviewWidget";
import ServiceContractsWidget from "@widgets/services/ServiceContractsWidget";
import { CoveredAssetsByProductWidget } from "@widgets/assets/CoveredAssetsByProductWidget";
import ServicesExpiringContractsWidget from "@widgets/services/ServicesExpiringContractsWidget";
import ContractRequestRankingsWidget from "@widgets/services/ContractRequestRankingsWidget";
import LastMonthsCostsWidget from "@widgets/services/LastMonthsCostsWidget";
import FilteredAssetProductWidget from "@widgets/assets/FilteredAssetProductWidget";
import { AssetWidgetType } from "@lib/ShiOneClient";
import AssetsLifecycleManagementWidget from "@widgets/assets/AssetsLifecycleManagementWidget";
import { WidgetRegistry } from "shared-ui/src/widgets/models/WidgetRegistry";
import NewsArticleWidget from "@widgets/message-center/NewsArticlesWidget";
import ActiveUsersByServiceWidget from "@widgets/microsoft-adoption/ActiveUsersByServiceWidget";
import ActiveUserTrendsWidget from "@widgets/microsoft-adoption/ActiveUserTrendsWidget";
import FeatureAdoptionWidget from "@widgets/microsoft-adoption/FeatureAdoptionWidget";
import LicenseBreakdownWidget from "@widgets/microsoft-adoption/LicenseBreakdownWidget";
import LicenseUtilizationWidget from "@widgets/microsoft-adoption/LicenseUtilizationWidget";
import ProductivityWidget from "@widgets/microsoft-adoption/ProductivityWidget";
import UserEngagementWidget from "@widgets/microsoft-adoption/UserEngagementWidget";
import WelcomeWidget from "@widgets/WelcomeWidget";
import AboutAssessmentsWidget from "@widgets/assessments/AboutAssessmentsWidget";
import CompletedAssessmentsWidget from "@widgets/assessments/CompletedAssessmentsWidget";
import ExploreLabsWidget from "@widgets/labs/ExploreLabsWidget";

// Add an entry for each widget here. The id must be unique.
export const widgetRegistry: WidgetRegistry = {
  widgets: [
    {
      id: "sample-widget-a",
      name: "Sample Widget A",
      widget: (props) => <SampleWidgetA {...props}></SampleWidgetA>,
    },
    {
      id: "sample-widget-b",
      name: "Sample Widget B",
      widget: (props) => <SampleWidgetB {...props}></SampleWidgetB>,
    },
    {
      id: "sample-widget-c",
      name: "Sample Widget C",
      widget: (props) => <SampleWidgetC {...props}></SampleWidgetC>,
    },
    {
      id: "aws-potential-savings-widget",
      name: "AWS Potential Savings",
      widget: (props) => (
        <AwsPotentialSavingsWidget {...props}></AwsPotentialSavingsWidget>
      ),
    },
    {
      id: "cloud-potential-savings-widget",
      name: "Potential Savings",
      widget: (props) => (
        <CloudPotentialSavingsWidget {...props}></CloudPotentialSavingsWidget>
      ),
    },
    {
      id: "assets-warehouse-stock-widget",
      name: "Warehouse Stock",
      widget: (props) => (
        <AssetsWarehouseStockWidget {...props}></AssetsWarehouseStockWidget>
      ),
    },
    {
      id: "sample-widget-e",
      name: "Sample Widget E",
      widget: (props) => <SampleWidgetE {...props}></SampleWidgetE>,
    },
    {
      id: "my-requests-widget",
      name: "My Requests",
      widget: (props) => <MyRequestsWidget {...props}></MyRequestsWidget>,
    },
    {
      id: "open-support-requests-widget",
      name: "Open Requests",
      widget: (props) => <OpenRequestsWidget {...props} />,
    },
    {
      id: "all-support-requests-widget",
      name: "All Requests",
      widget: (props) => <AllRequestsWidget {...props} />,
    },
    {
      id: "knowledgebase-widget",
      name: "Knowledge Base",
      widget: (props) => <KnowledgeBaseWidget {...props}></KnowledgeBaseWidget>,
    },
    {
      id: "total-requests-completed-widget",
      name: "Total Requests Completed",
      widget: (props) => <TotalRequestsCompletedWidget {...props} />,
    },
    {
      id: "cloud-accounts-widget",
      name: "Cloud Accounts",
      widget: (props) => <CloudAccountsWidget {...props}></CloudAccountsWidget>,
    },
    {
      id: "projects-widget",
      name: "Projects",
      widget: (props) => <ProjectsWidget {...props}></ProjectsWidget>,
    },
    {
      id: "issue-rankings-widget",
      name: "Issue Rankings",
      widget: (props) => <IssueRankingsWidget {...props}></IssueRankingsWidget>,
    },
    {
      id: "request-metrics-widget",
      name: "Request Metrics",
      widget: (props) => (
        <RequestMetricsWidget {...props}></RequestMetricsWidget>
      ),
    },
    {
      id: "support-overview-widget",
      name: "Support Overview",
      widget: (props) => <SupportOverviewWidget {...props} />,
    },
    {
      id: "completed-requests-meeting-sla-widget",
      name: "Completed Requests Meeting SLA",
      widget: (props) => <CompletedRequestsMeetingSlaWidget {...props} />,
    },
    {
      id: "requester-rankings-widget",
      name: "Requester Rankings",
      widget: (props) => <RequesterRankingsWidget {...props} />,
    },
    {
      id: "current-month-cloud-consumption-widget",
      name: "Current Month Cloud Consumption",
      widget: (props) => <CurrentMonthCloudConsumptionWidget {...props} />,
    },
    {
      id: "cloud-consumption-history-widget",
      name: "Cloud Consumption History",
      widget: (props) => <CloudConsumptionHistoryWidget {...props} />,
    },
    {
      id: "assets-use-state-widget",
      name: "Assets By Use State",
      widget: (props) => <AssetsByUseStateWidget {...props} />,
    },
    {
      id: "assets-warehouse-orders-widget",
      name: "Warehouse Orders",
      widget: (props) => <AssetsWarehouseOrdersWidget {...props} />,
    },
    {
      id: "assets-product-overview-widget",
      name: "Product Overview",
      widget: (props) => <AssetsProductOverviewWidget {...props} />,
    },
    {
      id: "asset-overview-widget",
      name: "Asset Overview",
      widget: (props) => <AssetsOverviewWidget {...props} />,
    },
    {
      id: "service-contracts-widget",
      name: "Service Contracts",
      widget: (props) => <ServiceContractsWidget {...props} />,
    },
    {
      id: "assets-expiring-contracts-widget",
      name: "Expiring Vendor Contracts",
      widget: (props) => <AssetsExpiringContractsWidget {...props} />,
    },
    {
      id: "assets-lifecycle-management-widget",
      name: "Lifecycle Management",
      widget: (props) => <AssetsLifecycleManagementWidget {...props} />,
    },
    {
      id: "covered-assets-by-product-widget",
      name: "Covered Assets By Product",
      widget: (props) => <CoveredAssetsByProductWidget {...props} />,
    },
    {
      id: "services-expiring-contracts-widget",
      name: "Expiring Contracts",
      widget: (props) => <ServicesExpiringContractsWidget {...props} />,
    },
    {
      id: "contract-request-rankings-widget",
      name: "Contract Request Rankings",
      widget: (props) => <ContractRequestRankingsWidget {...props} />,
    },
    {
      id: "assets-widget",
      name: "Assets",
      widget: (props) => (
        <FilteredAssetProductWidget {...props} type={AssetWidgetType.Asset} />
      ),
    },
    {
      id: "assets-products-widget",
      name: "Products",
      widget: (props) => (
        <FilteredAssetProductWidget {...props} type={AssetWidgetType.Product} />
      ),
    },
    {
      id: "last-months-costs-widget",
      name: "Last Month's Costs",
      widget: (props) => <LastMonthsCostsWidget {...props} />,
    },
    {
      id: "news-articles-widget",
      name: "News & Updates",
      widget: (props) => <NewsArticleWidget {...props} />,
    },
    {
      id: "active-users-by-service-widget",
      name: "Active Users By Service",
      widget: (props) => <ActiveUsersByServiceWidget {...props} />,
    },
    {
      id: "active-user-trends-widget",
      name: "Active User Trends",
      widget: (props) => <ActiveUserTrendsWidget {...props} />,
    },
    {
      id: "feature-adoption-widget",
      name: "Feature Adoption",
      widget: (props) => <FeatureAdoptionWidget {...props} />,
    },
    {
      id: "license-breakdown-widget",
      name: "License Breakdown",
      widget: (props) => <LicenseBreakdownWidget {...props} />,
    },
    {
      id: "license-utilization-widget",
      name: "License Utilization",
      widget: (props) => <LicenseUtilizationWidget {...props} />,
    },
    {
      id: "productivity-widget",
      name: "Productivity",
      widget: (props) => <ProductivityWidget {...props} />,
    },
    {
      id: "user-engagement-widget",
      name: "User Engagement",
      widget: (props) => <UserEngagementWidget {...props} />,
    },
    {
      id: "welcome-widget",
      name: "Welcome",
      widget: () => <WelcomeWidget />,
    },
    {
      id: "about-assessments-widget",
      name: "About Assessments",
      widget: (props) => <AboutAssessmentsWidget {...props} />,
    },
    {
      id: "completed-assessments-widget",
      name: "Completed Assessments",
      widget: (props) => <CompletedAssessmentsWidget {...props} />,
    },
    {
      id: "explore-labs-widget",
      name: "Explore Labs",
      widget: (props) => <ExploreLabsWidget {...props} />,
    },
  ],
};
