import { useGetWarehouseOrders } from "./useGetWarehouseOrders";
import { useEffect, useState } from "react";

export default function useWarehouseOrderMap() {
  const { data } = useGetWarehouseOrders();
  const [orders, setOrders] = useState(new Map());

  useEffect(() => {
    const tempMap = new Map();
    data?.warehouseOrders?.forEach((item) => {
      const key = `${item.purchOrder}`;
      if (!tempMap.has(key)) {
        const map = new Map();
        map.set(item.uniqueIdentifier, item);
        tempMap.set(key, map);
      } else {
        const currentItem = tempMap.get(key);
        if (!currentItem.has(item.uniqueIdentifier)) {
          currentItem.set(item.uniqueIdentifier, item);
        }
      }
    });
    setOrders(tempMap);
  }, [data]);

  return [orders];
}
