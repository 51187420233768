import React from "react";
import NavLink from "./NavLink";
import NavGroup from "./NavGroup";
import NavFunction from "./NavFunction";
import { NavItemType } from "./NavigationTypes";
import NavDashboard from "./NavDashboard";

interface NavItemProps {
  navItem: NavItemType;
  tier: number;
  parentContext?: string;
}
export default function NavItem({
  navItem,
  tier,
  parentContext = "Main Menu",
}: NavItemProps) {
  if (navItem.visible) {
    switch (navItem.type) {
      case "Group":
        return (
          <NavGroup
            navItem={navItem}
            tier={tier}
            parentContext={parentContext}
          />
        );
      case "Function":
        return (
          <NavFunction
            navItem={navItem}
            tier={tier}
            parentContext={parentContext}
          />
        );
      case "Link":
        return (
          <NavLink
            navItem={navItem}
            tier={tier}
            parentContext={parentContext}
          />
        );
      case "Dashboard":
        return (
          <NavDashboard
            navItem={navItem}
            tier={tier}
            parentContext={parentContext}
          />
        );
      default:
        return <></>;
    }
  } else return null;
}
