import React from "react";
import Box from "@mui/material/Box";
import PrioritiesRecsView from "@features/assessments-feature/components/priorities-recommendations/PrioritiesRecsView";
import { assessmentTabViewMode } from "@features/assessments-feature/constants";

const PrioritiesRecsListExport = () => {
  return (
    <Box
      className={"assessment-break"}
      sx={{
        zoom: "80%",
        "& .MuiCard-root": { overflow: "visible" },
        "& a": {
          color: (theme) => theme.palette.text.primary,
          cursor: "default",
          userSelect: "text",
        },
      }}
    >
      <PrioritiesRecsView viewMode={assessmentTabViewMode.list} />
    </Box>
  );
};

export default PrioritiesRecsListExport;
