export const impersonateUser = (
  userId: string | number,
  demoData?: boolean,
  url = window.location.origin
) => {
  clearImpersonate();
  localStorage.setItem("impersonateUser", userId.toString());
  if (demoData) localStorage.setItem("demoData", "true");
  window.location.href = url;
};

export const clearImpersonate = (
  url = window.location.origin,
  navigate = true
) => {
  localStorage.removeItem("impersonateUser");
  localStorage.removeItem("impersonateAccount");
  localStorage.removeItem("demoData");
  if (navigate) {
    window.location.href = url;
  }
};

export const getImpersonate = () => {
  return localStorage.getItem("impersonateUser");
};

export const impersonateAccount = (
  accountId: number | string,
  demoData?: boolean,
  url = window.location.origin,
  navigate = true
) => {
  clearImpersonate(url, navigate);
  localStorage.setItem("impersonateAccount", accountId.toString());
  if (demoData) localStorage.setItem("demoData", "true");
  if (navigate) {
    window.location.href = url;
  }
};

export const getImpersonateAccount = () => {
  return localStorage.getItem("impersonateAccount");
};

export const isImpersonatingAccount = () => {
  return !!localStorage.getItem("impersonateAccount");
};

export const isImpersonatingUser = () => {
  return !!localStorage.getItem("impersonateUser");
};
