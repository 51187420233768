import { useQuery } from "@tanstack/react-query";
import { GetWarehouseOrdersResponse } from "@lib/ShiOneClient";
import { CACHE_KEY_WAREHOUSE_ORDERS } from "@features/assets-feature/constants";
import ms from "ms";
import { getWarehouseOrders } from "@features/assets-feature/stores/warehouseStore";

export const useGetWarehouseOrders = () => {
  const fetchWarehouseOrders = async () => {
    return await getWarehouseOrders();
  };
  return useQuery<GetWarehouseOrdersResponse, Error>({
    queryKey: CACHE_KEY_WAREHOUSE_ORDERS,
    queryFn: fetchWarehouseOrders,
    staleTime: ms("1m"),
  });
};
