import { MappingRow, ExcelSheet } from "./types";

export const combineReducers = (reducers: {
  mappings?: (state: MappingRow[], action: any) => MappingRow[];
  excelSheets?: (
    state: ExcelSheet[],
    action: any
  ) => {
    sourceSheetName: any;
    locationPattern: any;
    locationKey: any;
    id: any;
    valid: boolean;
  }[];
  [key: string]: any;
}) => {
  // @ts-ignore
  return (state: { [key: string]: any }, action) => {
    return Object.keys(reducers).reduce(
      (nextState: { [key: string]: any }, key) => {
        nextState[key] = reducers[key](state[key], action);
        return nextState;
      },
      {}
    );
  };
};
