import React from "react";
import { CapabilityInsightsProduct } from "@lib/ShiOneClient";
import {
  Chart,
  LineElement,
  PointElement,
  Tooltip as ChartJSTooltip,
} from "chart.js";
import { goodToBadColors, graphColors } from "shared-ui/src/theme/shiColors";
import useAdjustCategoriesChartPositions from "@features/assessments-feature/hooks/useAdjustCategoriesChartPositions";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import { getHarveyBall } from "@features/assessments-feature/utils/assessmentHarveyBall";
import { useChartJsDefaults } from "shared-ui/src/theme/useChartJsDefaults";
Chart.register(LineElement, PointElement, ChartJSTooltip);

const FeatureFunctionalityLineChart = ({
  productsList,
  categoriesList,
}: {
  productsList: CapabilityInsightsProduct[];
  categoriesList: string[];
}) => {
  useChartJsDefaults();
  const { customLabelsPlugin, labelHeight, labelPosition, labelWidth } =
    useAdjustCategoriesChartPositions();
  const options = {
    clip: false as const,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    layout: {
      padding: {
        bottom: 64,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
      },
      y: {
        min: 1,
        max: 5,
        ticks: {
          padding: -1,
          font: {
            size: 32,
            family: "Arial Unicode MS",
            lineHeight: 0.8,
          },
          color: [
            "gray",
            goodToBadColors.bad,
            goodToBadColors.warning,
            goodToBadColors.ok,
            goodToBadColors.good,
            goodToBadColors.best,
          ],
          callback: function (value: string | number) {
            return `${getHarveyBall(value.toString())}      `;
          },
          stepSize: 1,
        },
      },
    },
    parsing: {
      yAxisKey: "averageFeatureScore",
      xAxisKey: "harveyBallCategory",
    },
    plugins: {
      legend: {
        display: false,
      },
      customLabelsPlugin: {},
    },
  };

  const barData = {
    labels: categoriesList,
    datasets: productsList.map((product, index) => ({
      label: product.productName,
      data: product.featureScoresList,
      borderColor: graphColors[index],
      backgroundColor: graphColors[index],
    })),
  };

  return (
    <Box height={"100%"} sx={{ position: "relative" }}>
      <Line options={options} data={barData} plugins={[customLabelsPlugin]} />
      {categoriesList.map((label, index) => {
        const key = label + index;
        return (
          <Box
            key={key}
            top={labelHeight}
            left={labelPosition[index]}
            position="absolute"
            color={"text.secondary"}
            display="flex"
            alignItems="start"
            justifyContent="center"
            textAlign="center"
            width={labelWidth[index]}
            sx={{ transform: "translateX(-50%)" }}
          >
            {labelPosition.length > 0 && (
              <Typography variant={"caption"}>{label}</Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default FeatureFunctionalityLineChart;
