import React from "react";
import RadarIcon from "@mui/icons-material/Radar";
import { Timeline } from "@mui/icons-material";
export enum FeatureFunctionalityChartType {
  Radar,
  Bar,
}

interface FeatureFunctionalityChartProps {
  label: string;
  value: FeatureFunctionalityChartType;
  icon: React.ReactNode;
}
export const featureFunctionalityCharts: FeatureFunctionalityChartProps[] = [
  {
    label: "Radar chart",
    value: FeatureFunctionalityChartType.Radar,
    icon: <RadarIcon />,
  },
  {
    label: "Bar chart",
    value: FeatureFunctionalityChartType.Bar,
    icon: <Timeline />,
  },
];
