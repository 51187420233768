import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import React, { useEffect, useState } from "react";
import {
  useGetUserConfig,
  useUpsertUserConfig,
} from "../../../../modules/hooks/useUserConfig";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { UserConfigDto } from "@lib/ShiOneClient";
import Visibility from "@mui/icons-material/Visibility";
import RemoveIcon from "@mui/icons-material/Remove";

interface UserViewsParams {
  gridId: string;
  defaultView?: View;
  setFilterHandler?: (value: any) => void;
  currentFilters?: any[];
  activeFiltersCount?: number;
  prebuiltViews?: View[];
  disableCustomViews?: boolean;
}

export class View {
  name: string = "";
  filters?: any[];
}

const UserViews = ({
  gridId,
  defaultView,
  setFilterHandler,
  currentFilters,
  activeFiltersCount,
  prebuiltViews,
  disableCustomViews,
}: UserViewsParams) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const location = useLocation();
  const configSettingsKey =
    location.pathname + "_UserViewSettings" + (gridId ? ":" + gridId : "");
  const { userConfig } = useGetUserConfig(configSettingsKey);
  const upsertUserConfigs = useUpsertUserConfig();
  const [inputValue, setInputValue] = useState("");
  const [views, setViews] = useState<View[]>([]);
  const [localDefaultView] = useState(defaultView);
  const [currentView, setCurrentView] = useState(defaultView);

  useEffect(() => {
    if (!userConfig) return;

    const settingsValue = userConfig?.settingsValue;
    if (settingsValue != null) {
      const settingsViews: View[] = JSON.parse(settingsValue);
      if (Array.isArray(settingsViews)) setViews(settingsViews);
    }
  }, [userConfig]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") {
      event.stopPropagation();
    } else {
      saveView();
      handleClose();
    }
  };

  const filterWithRestoredDates = (filters: any[] | undefined) => {
    if (!filters) return filters;

    return filters?.map((filter) => {
      if (filter.datePickerGroupName) {
        return {
          ...filter,
          value: filter.value ? new Date(filter.value) : null,
        };
      }
      if (filter.datePicker && !filter.datePickerGroupName) {
        return {
          ...filter,
          startDate: filter.startDate ? new Date(filter.startDate) : null,
          endDate: filter.startDate ? new Date(filter.endDate) : null,
        };
      }
      return filter;
    });
  };
  const selectView = (view: View | undefined) => {
    if (!view) return;

    if (
      setFilterHandler &&
      view.name == defaultView?.name &&
      defaultView.filters?.length == 0
    ) {
      clearAllFilters();
    } else if (setFilterHandler) {
      setFilterHandler(filterWithRestoredDates(view.filters));
    }
    setCurrentView(view);
    setAnchorEl(null);
  };

  const clearAllFilters = () => {
    const clearedFilters = currentFilters?.map((f) => {
      return {
        ...f,
        ...(f.datePicker &&
          !f.datePickerGroupName && {
            endDate: null,
            startDate: null,
          }),
        ...(f.datePickerGroupName && {
          value: null,
        }),
        ...(!f.datePicker && {
          value: f.multiselect ? [] : "",
        }),
      };
    });
    setFilterHandler && setFilterHandler(clearedFilters);
  };

  const removeItem = (view: View) => {
    const newConfig = views.filter((x) => x.name !== view.name);

    const settings = new UserConfigDto();
    settings.settingsKey = configSettingsKey;
    settings.settingsValue = JSON.stringify(newConfig);
    upsertUserConfigs.mutate(settings);
  };

  const saveView = () => {
    if (!activeFiltersCount || !currentFilters || !inputValue) return;
    const newView: View = {
      name: inputValue,
      filters: currentFilters,
    };
    const newConfig = [...views, newView];
    const settings = new UserConfigDto();
    settings.settingsKey = configSettingsKey;
    settings.settingsValue = JSON.stringify(newConfig);
    upsertUserConfigs.mutate(settings);
    setCurrentView(newView);
    setInputValue("");
  };

  const invalidName = () => {
    if (!inputValue) {
      return true;
    }
    return views.some((x) => x.name === inputValue);
  };

  return (
    <div>
      <Button
        color="inherit"
        id="view-button"
        aria-controls={open ? "view-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ whiteSpace: "nowrap", color: "text.primary" }}
      >
        <Visibility
          sx={{
            fontSize: "16px",
            marginRight: "8px",
            color: "text.secondary",
          }}
        />
        {currentView?.name}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* Don't create a default view item if we're using prebuilt views*/}
        {/* because one of the prebuiltViews will be the default*/}
        {defaultView && !prebuiltViews && (
          <MenuItem
            key={localDefaultView?.name}
            onClick={() => selectView(localDefaultView)}
          >
            {localDefaultView?.name}
          </MenuItem>
        )}
        {/* Add any prebuiltViews first. Note that there's no remove button*/}
        {prebuiltViews?.map((view) => (
          <MenuItem key={view.name} onClick={() => selectView(view)}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>{view.name}</div>
            </div>
          </MenuItem>
        ))}
        {views.map((view) => (
          <MenuItem key={view.name} onClick={() => selectView(view)}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>{view.name}</div>
              <IconButton
                size={"small"}
                onClick={(e) => {
                  e.stopPropagation();
                  removeItem(view);
                }}
              >
                <RemoveIcon />
              </IconButton>
            </div>
          </MenuItem>
        ))}
        {!disableCustomViews && (
          <MenuItem sx={{ cursor: "auto" }}>
            <FormControl
              error={inputValue.length === 25}
              sx={{ maxWidth: "230px" }}
            >
              <Input
                id="prefix-adornment"
                placeholder="Save current view"
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                onKeyDown={handleInputKeyDown}
                inputProps={{ maxLength: 25 }}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        !activeFiltersCount
                          ? "No filters are currently applied"
                          : ""
                      }
                    >
                      <span>
                        <IconButton
                          size={"small"}
                          onClick={saveView}
                          disabled={invalidName()}
                        >
                          <AddIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </InputAdornment>
                }
              />
              <FormHelperText sx={{ fontSize: 10, textAlign: "right" }}>
                {inputValue.length}/25
              </FormHelperText>
            </FormControl>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default UserViews;
