import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect } from "react";
import LabCard from "./LabCard";
import {
  LabDto,
  PaginatedResultDto_1OfLabDto,
  SearchFilter,
} from "@lib/ShiOneClient";
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useQueryClient,
} from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import {
  CACHE_KEY_LABS,
  CACHE_KEY_TECHNOLOGY_GROUP,
} from "@features/labs-feature/constants";

export const labPracticePalette = [
  "#692CB7",
  "#3A56D2",
  "#17BFD6",
  "#15AAA2",
  "#5FA58C",
  "#48E4A3",
  "#D0CF00",
  "#F8C128",
  "#E48543",
  "#BE4939",
  "#E48B94",
  "#BA6AAD",
];

export type LabPracticeTitleData = {
  color: string;
  title: string;
};

export const LabCards = ({
  labs,
  handleOpenLabDetails,
  handleSelectLab,
  classes,
  currentSearchFilter,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
}: {
  labs: LabDto[];
  handleOpenLabDetails: () => void;
  handleSelectLab: (lab: LabDto) => void;
  classes: any;
  currentSearchFilter: SearchFilter;
  isFetchingNextPage: boolean | undefined;
  hasNextPage: boolean | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<PaginatedResultDto_1OfLabDto, any>>;
}) => {
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();

  const uniqueLabPractices = queryClient.getQueryData<string[]>([
    CACHE_KEY_LABS,
    CACHE_KEY_TECHNOLOGY_GROUP,
  ]);

  let labPracticeTitleData: LabPracticeTitleData[] = [];
  if (uniqueLabPractices && uniqueLabPractices?.length > 0) {
    const practiceTitleData: LabPracticeTitleData[] = [];
    uniqueLabPractices.forEach((practice, index) => {
      practiceTitleData[index] = {
        color: labPracticePalette[index],
        title: practice,
      };
    });
    labPracticeTitleData = practiceTitleData;
  }

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage().then();
    }
  }, [hasNextPage, inView, fetchNextPage]);

  const getRefByIndex = (labs: LabDto[], index: number) => {
    const currentRef =
      labs.length >= 4 && labs.length - 1 === index ? ref : undefined;
    return currentRef;
  };

  if (labs.length === 0) {
    return <></>;
  }

  return (
    <Grid container spacing={3} mt={-2}>
      {labs.map((lab, index) => {
        return (
          <Grid
            item
            container
            ref={getRefByIndex(labs, index)}
            key={lab.id}
            sm={6}
            md={4}
            lg={4}
          >
            <LabCard
              lab={lab}
              currentSearchFilter={currentSearchFilter}
              classes={classes}
              handleOpenLabDetails={handleOpenLabDetails}
              labPracticeTitleData={labPracticeTitleData}
              handleSelectLab={handleSelectLab}
            />
          </Grid>
        );
      })}
      {isFetchingNextPage && (
        <Grid item container xs={12} justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};
