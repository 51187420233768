import { Card, CardActionArea, CardContent, useTheme } from "@mui/material";
import React from "react";
import { LabDto, SearchFilter } from "@lib/ShiOneClient";
import { LabPracticeTitleData } from "./LabCards";
import { LabsOnDemandIndicator } from "./LabsOnDemandIndicator";
import LabCardHeader from "@features/labs-feature/components/LabCardHeader";

const css = (theme: any) => ({
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: 180,
    width: 500,
  },
  content: {
    height: "100%",
    zIndex: 1,
  },
  logoContent: {
    // @ts-ignore
    marginTop: theme.spacing(0.5),
    // @ts-ignore
    paddingBottom: theme.spacing(2),
    height: "inherit",
    paddingTop: "5px",
  },
});

export default function LabCard({
  lab,
  currentSearchFilter,
  handleOpenLabDetails,
  handleSelectLab,
  labPracticeTitleData,
  classes,
}: {
  lab: LabDto;
  currentSearchFilter: SearchFilter;
  handleOpenLabDetails: () => void;
  handleSelectLab: (lab: LabDto) => void;
  labPracticeTitleData: LabPracticeTitleData[];
  classes: any;
}) {
  const theme = useTheme();
  const styles = css(theme);

  return (
    <Card sx={styles.cardRoot}>
      <CardActionArea
        sx={styles.content}
        onClick={() => {
          handleOpenLabDetails();
          handleSelectLab(lab);
        }}
        data-telemetry-name={
          currentSearchFilter.filters || currentSearchFilter.searchTerm
            ? `filter result: ${lab.productName}`
            : ""
        }
      >
        {lab?.practice &&
          lab.practice.slice(0, lab.practice.length).map((practice, _index) => {
            if (practice !== "") {
              return (
                <LabCardHeader
                  key={`${lab.id}`}
                  practice={practice}
                  lab={lab}
                  labPracticeTitleData={labPracticeTitleData}
                />
              );
            }
            return null;
          })}
        <CardContent sx={styles.logoContent} style={{ paddingTop: "5px" }}>
          {lab.isOnDemandLab && <LabsOnDemandIndicator />}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
