import React from "react";
import AssessmentPlatformInsights from "@features/assessments-feature/components/platform-insights/AssessmentPlatformInsights";
import Box from "@mui/material/Box";

const PlatformInsightsExport = () => {
  return (
    <Box className={"assessment-break"} sx={{ zoom: "80%" }}>
      <AssessmentPlatformInsights />
    </Box>
  );
};

export default PlatformInsightsExport;
