import React from "react";
import assessmentViewsMap from "@features/assessments-feature/utils/assessmentViewsMap";
import useGetAssessmentOverview from "@features/assessments-feature/hooks/useGetAssessmentOverview";
import { useParams } from "react-router-dom";
import AssessmentOverviewTargetMaturityBreakdown from "@features/assessments-feature/components/overview/AssessmentOverviewTargetMaturityBreakdown";
import Box from "@mui/material/Box";

const TargetMaturityBreakdownExport = () => {
  const { program } = useParams();
  const assessmentProgram = assessmentViewsMap.get(program ?? "")?.id;
  const { data: sprData, isLoading } =
    useGetAssessmentOverview(assessmentProgram);
  return (
    <Box
      className={"assessment-break"}
      sx={{ "& .MuiCardContent-root": { height: 540 } }}
    >
      <AssessmentOverviewTargetMaturityBreakdown
        loading={isLoading}
        data={sprData?.targetMaturityBreakdown}
        program={assessmentProgram}
      />
    </Box>
  );
};

export default TargetMaturityBreakdownExport;
