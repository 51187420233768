import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AuthorizationClient, ClaimDto } from "@lib/ShiOneClient";
import { useApi } from "../v2/src/utils";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import {
  claimTypes,
  ClaimTypes,
  PermissionActionTypes,
  PermissionResourceTypes,
  RoleTypes,
  roleTypes,
  ShiOnePermission,
} from "./authorizationTypes";

const defaultClaims = [] as ClaimDto[];

export const ClaimsContext = createContext({
  claims: defaultClaims,
  refetchClaims: () => {},
  getClaimValue: (_claim: ClaimTypes): string | undefined => undefined,
  hasRoleIn: (..._roles: RoleTypes[]): boolean => false,
  hasLegacyPermission: (
    _resource: PermissionResourceTypes,
    _action: PermissionActionTypes,
  ): boolean => false,
  hasPermission: (_permission: ShiOnePermission): boolean => false,
  isCustomerAdmin: (): boolean => false,
  isShiAdmin: (): boolean => false,
  isShi: (): boolean => false,
  isVirtualAdmin: (): boolean => false,
  isGuestUser: (): boolean => false,
});

export type HasPermissionFunctionType = (
  permission: ShiOnePermission,
) => boolean;

export const ClaimsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [claims, setClaims] = useState<ClaimDto[]>(defaultClaims);
  const { isAuthenticated } = useAuth0();

  let api = useApi(AuthorizationClient);

  let response = useQuery(
    ["claims"],
    isAuthenticated ? () => api.getUserClaims() : () => null,
  );

  const refetchClaims = useCallback(() => {
    response.refetch({}).then();
  }, [response]);

  useEffect(() => {
    let data = response.data ?? [];
    setClaims(data);
  }, [response.data]);

  useEffect(() => {
    if (isAuthenticated) {
      refetchClaims();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const providerValues = useMemo(() => {
    const getClaimValue = (claimType: ClaimTypes) => {
      let claim = claims.find(
        (claim) => claim.claimType === claimTypes[claimType],
      );
      return claim ? claim.claimValue : undefined;
    };

    const getClaims = (claimType: ClaimTypes) => {
      return claims.filter(
        (claim) => claim.claimType === claimTypes[claimType],
      );
    };

    const hasRoleIn = (...roles: RoleTypes[]) => {
      let hasRole = false;
      let userRoles = getClaimValue("roles") ?? "0";
      let userRolesInt = parseInt(userRoles);
      if (isNaN(userRolesInt)) {
        return hasRole;
      }
      roles.forEach((role) => {
        if ((roleTypes[role] & userRolesInt) === roleTypes[role]) {
          hasRole = true;
        }
      });
      return hasRole;
    };

    const isGuestUser = () => {
      return !!getClaimValue("isGuestUser");
    };

    const isCustomerAdmin = () => {
      return hasRoleIn("CustomerAdmin");
    };

    const isShiAdmin = () => {
      return hasRoleIn("ShiAdmin");
    };

    const isShi = () => {
      return !!getClaimValue("isShi");
    };

    const isVirtualAdmin = () => {
      return hasRoleIn("ShiImpersonateVirtualAdmin");
    };

    const hasLegacyPermission = (
      resource: PermissionResourceTypes,
      action: PermissionActionTypes,
    ) => {
      let hasLegacyPermission: boolean;
      const permissions = getClaims("legacyPermission").map(
        (claim) => claim.claimValue ?? "",
      );

      hasLegacyPermission = permissions.some((p) => {
        return p.indexOf(resource) >= 0 && p.indexOf(action) >= 0;
      });
      return hasLegacyPermission;
    };

    const hasPermission = (permission: ShiOnePermission) => {
      let hasPermission: boolean;
      const permissions = getClaims("permission").map(
        (claim) => claim.claimValue ?? "",
      );

      hasPermission = permissions.some((p) => {
        return p === permission;
      });
      return hasPermission;
    };
    return {
      claims,
      refetchClaims,
      getClaimValue,
      hasRoleIn: hasRoleIn,
      hasLegacyPermission,
      hasPermission,
      isCustomerAdmin,
      isShiAdmin,
      isShi,
      isVirtualAdmin,
      isGuestUser,
    };
  }, [claims, refetchClaims]);

  return (
    <ClaimsContext.Provider value={providerValues}>
      {children}
    </ClaimsContext.Provider>
  );
};

export function useClaims() {
  return useContext(ClaimsContext);
}
