import React from "react";
import { Tooltip, useTheme } from "@mui/material";
import { LabDto } from "@lib/ShiOneClient";
import { UserOnDemandLabItem } from "@features/labs-feature/utils/labTypes";
import { LabsStyles } from "@features/labs-feature/components/LabsStyles";

export const LogoContainer = ({
  selectedLab,
  maxIconsToDisplay,
}: {
  selectedLab: LabDto | UserOnDemandLabItem;
  maxIconsToDisplay?: number;
}) => {
  const theme = useTheme();
  const styles = LabsStyles(theme);
  const apiEndpoint = import.meta.env.API_ENDPOINT;

  if (!selectedLab || !selectedLab.partners) return <></>;
  return (
    <>
      {selectedLab.partners?.length > 0 &&
        selectedLab.partners
          .slice(0, maxIconsToDisplay)
          .map((partner, partnerIndex) => {
            if (partner.logo) {
              return (
                <Tooltip
                  key={"tooltip" + partner.partner + selectedLab.id}
                  sx={styles.tooltip}
                  placement="top"
                  title={partner.partner}
                  arrow
                >
                  <img
                    key={"img" + partner.partner + partnerIndex}
                    style={styles.imageBox as React.CSSProperties}
                    src={`${apiEndpoint}${partner.logo}`}
                    alt={partner.partner}
                  />
                </Tooltip>
              );
            }
            return null;
          })}
    </>
  );
};
