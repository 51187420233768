import React from "react";
import { ListItemIcon, MenuItem, MenuList } from "@mui/material";
import { clearImpersonate } from "shared-ui/src/utils/clientmanagement";
import { isValidShiProfile } from "../functions/shiUserCheck";
import { useAuth0 } from "@auth0/auth0-react";
import { Logout } from "@mui/icons-material";
import { useSelector } from "react-redux";

export default function SignOutMenuItem({ classes }) {
  const userProfileResponse = useSelector(
    (state) => state.profile.userProfileResponse
  );

  const shiProfile = userProfileResponse.shiProfile;

  const { logout, user } = useAuth0();

  if (user.sub.startsWith("samlp|")) {
    return (
      <MenuList component="nav">
        <MenuItem
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: window.location.origin + "/logout/saml",
              },
            })
          }
          name="logout-button"
        >
          <ListItemIcon sx={classes.menuIcon}>
            <Logout />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </MenuList>
    );
  }

  return (
    <MenuList component="nav">
      {import.meta.env.DIST_ENV !== "demo" && isValidShiProfile(shiProfile) ? (
        <MenuItem onClick={() => clearImpersonate()}>
          <ListItemIcon className={classes.menuIcon}>
            <Logout />
          </ListItemIcon>
          <span>Return to SHI Portal</span>
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: window.location.origin + "/logout",
              },
            })
          }
          name="logout-button"
        >
          <ListItemIcon className={classes.menuIcon}>
            <Logout />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      )}
    </MenuList>
  );
}
