import {
  PlatformInsightCapability,
  PlatformInsightProduct,
} from "@lib/ShiOneClient";
import { GroupedProduct } from "@features/assessments-feature/types/PlatformInsightsProductsType";

export function groupProductsByCapability(products: PlatformInsightProduct[]) {
  const productMap: Map<string, GroupedProduct> = new Map();

  products?.forEach((item) => {
    if (!item.productId) return;

    if (!productMap.has(item.productId)) {
      productMap.set(item.productId, {
        productName: item.productName || "",
        hasUsingTools: false,
        platformName: item.platformName || "",
        logo: item.logo || "",
        capabilitiesWithKnownProducts: [],
        capabilitiesWithOtherProducts: [],
      });
    }
    const groupedProduct = productMap.get(item.productId);
    if (groupedProduct && item.capabilities) {
      item.capabilities.forEach((capability) => {
        const capabilityId = capability.capabilityId;

        const capabilityExistsInKnownProducts =
          groupedProduct.capabilitiesWithKnownProducts.some(
            (capability) => capability.capabilityId === capabilityId,
          );
        const capabilityExistsInOtherProducts =
          groupedProduct.capabilitiesWithOtherProducts.some(
            (capability) => capability.capabilityId === capabilityId,
          );

        if (item.isKnownProduct) {
          addCapability(
            groupedProduct,
            capability,
            capabilityExistsInKnownProducts,
            true,
          );
          groupedProduct.hasUsingTools = true;
        } else {
          addCapability(
            groupedProduct,
            capability,
            capabilityExistsInOtherProducts,
            false,
          );
        }
      });
    }
  });

  const productArray = Array.from(productMap.values());
  productArray.sort((x, y) => {
    const xIsUsing = x.hasUsingTools;
    const yIsUsing = y.hasUsingTools;

    // If both are using or both are not using, their relative order doesn't change
    if (xIsUsing === yIsUsing) {
      return 0;
    }

    // If x is using and y is not using, x comes first
    return xIsUsing ? -1 : 1;
  });

  return productArray;
}

const addCapability = (
  groupedProduct: GroupedProduct,
  capability: PlatformInsightCapability,
  capabilityExists: boolean,
  knownProduct: boolean,
) => {
  if (!capabilityExists) {
    const targetArray = knownProduct
      ? groupedProduct.capabilitiesWithKnownProducts
      : groupedProduct.capabilitiesWithOtherProducts;
    targetArray.push({
      ...capability,
      currentlyUsingTools: capability.currentlyUsingProductNames || [],
    });
  }
};
