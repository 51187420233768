import {
  CloudConsumptionHistoryWidgetClient,
  CostAnalyticProviders,
  PageLayoutWidgetDto,
  WidgetContentResponseDto_1OfGetCloudConsumptionHistoryResponse
} from "@lib/ShiOneClient";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import {
  timeOptionKeys,
  timeOptions,
  timeOptionTypes
} from "@widgets/utils/DateRanges";
import { useApi } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import { ToggleButtonGroup } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  Legend,
  LinearScale,
  Tooltip
} from "chart.js";
import { DateTime } from "luxon";
import React from "react";
import { Cloud } from "@mui/icons-material";
import { numberFormatter } from "@features/assets-feature/utils/assetInventoryFunctions";
import { graphColors, NoContent, WidgetWrapper } from "shared-ui";

Chart.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

export default function CloudConsumptionHistoryWidget({
                                                        pageLayoutWidget
                                                      }: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const filterKey = "cloud-consumption-history-widget";
  const { currentFilterValue, setFilter } = useWidgetFilterUserConfig(
    pageLayoutWidget.widgetId!,
    filterKey,
    timeOptionKeys.threeMonth
  );

  const { data } = useGetConsumptionHistoryData(
    currentFilterValue as timeOptionTypes
  );

  let timeOptionsFiltered = Object.keys(timeOptions).filter((to) => {
    return to === timeOptionKeys.threeMonth || to === timeOptionKeys.sixMonth;
  });

  function useGetConsumptionHistoryData(currentFilterValue: timeOptionTypes) {
    const api = useApi(CloudConsumptionHistoryWidgetClient);
    let dateFrom = timeOptions[currentFilterValue].dateFrom;
    let dateTo = timeOptions[currentFilterValue].dateTo;
    return useQuery([filterKey, dateFrom, dateTo], () =>
      api.getCloudConsumptionHistory(dateFrom, dateTo)
    );
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          callback: function(value: any) {
            return "$" + numberFormatter.format(value);
          }
        }
      }
    },
    plugins: {
      legend: {
        position: "bottom" as const
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const label = context.dataset.label || "";
            const value = "$" + context.formattedValue;
            return `${label}: ${value}`;
          },
          footer: function(tooltipItems: any) {
            let total = 0;
            tooltipItems?.forEach((tooltipItem: any) => {
              tooltipItem?.dataset?.data[tooltipItem.dataIndex] && (total += tooltipItem.dataset.data[tooltipItem.dataIndex]);
            });
            return `Total: $${numberFormatter.format(total)}`;
          }
        }
      }
    },
    interaction: {
      mode: "index" as const,
      intersect: false
    },
    parsing: {
      xAxisKey: "key",
      yAxisKey: "value"
    }
  };

  function getProviderLabel(provider: string | undefined): string {
    switch (provider) {
      case "aws":
        return "AWS";
      case "azure":
        return "Azure";
      case "gcp":
        return "GCP";
      case "azureDirect":
        return "Azure Direct";
      default:
        return "Other";
    }
  }

  function getChartData(
    data:
      | WidgetContentResponseDto_1OfGetCloudConsumptionHistoryResponse
      | undefined
  ): ChartData<"bar", number[], string | undefined> {
    if (!data?.data?.cloudConsumptionHistory) {
      return { labels: [], datasets: [] };
    }
    // Create the initial list of datasets needed by the chart
    let datasets: any[] = [];
    for (
      let i = 0;
      i <
      Object.keys(data?.data?.cloudConsumptionHistory[0].monthlyProviderAmount!)
        .length;
      i++
    ) {
      const provider = Object.keys(
        data?.data?.cloudConsumptionHistory[0].monthlyProviderAmount!
      )[i];
      datasets.push({
        label: getProviderLabel(provider as CostAnalyticProviders),
        data: new Array(data?.data?.cloudConsumptionHistory.length).fill(0),
        borderWidth: 1,
        stack: "stack",
        backgroundColor: graphColors[i]
      });
    }
    // For each month, get the total amount for each provider and update its respective dataset
    data?.data?.cloudConsumptionHistory.forEach((cch, index) => {
      Object.keys(cch.monthlyProviderAmount!).forEach((provider) => {
        const dataset = datasets.find(
          (d) => d.label === getProviderLabel(provider as CostAnalyticProviders)
        );
        if (dataset) {
          dataset.data[index] = cch.monthlyProviderAmount![provider];
        }
      });
    });
    return {
      labels: data?.data?.cloudConsumptionHistory.map((x) =>
        DateTime.fromJSDate(x.monthStart!).toUTC().toFormat("MMM")
      ),
      datasets: datasets
    };
  }

  const preparedData = getChartData(data);
  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      headerAction={
        <ToggleButtonGroup
          color="secondary"
          exclusive
          value={currentFilterValue}
          onChange={(e, value) => {
            if (value === null) return;
            setFilter(value);
          }}
        >
          {timeOptionsFiltered.map((to) => {
            return (
              <ToggleButton style={{ lineHeight: 1 }} key={to} value={to}>
                {to}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      }
      isEmpty={preparedData?.datasets?.length === 0}
    >
      {preparedData?.datasets?.length > 0 ? (
        <div>
          <Bar data={preparedData} options={chartOptions} />
        </div>
      ) : (
        <NoContent
          containerHeight={350}
          icon={<Cloud />}
          header={"No Cloud Consumption Yet For This Month"}
          body={
            "Wait for a full day of the month to be completed or report an incident if this remains empty"
          }
        />
      )}
    </WidgetWrapper>
  );
}
