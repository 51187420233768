import React from "react";
import { Collapse, Grid, Typography } from "@mui/material";
import CapabilityTable from "@features/assessments-feature/components/platform-insights/CapabilityTable";
import ToolsTypeIcon from "@features/assessments-feature/components/ToolsTypeIcon";
import {
  CapabilityWithProduct,
  ToolsType,
} from "@features/assessments-feature/types/PlatformInsightsProductsType";
import CapabilityRows from "@features/assessments-feature/components/platform-insights/CapabilityRows";

const PlatformInsightsDetails = ({
  isExpand,
  currentProduct,
  capabilitiesWithKnownProduct,
  capabilitiesWithOtherProduct,
  handleCompareDialog,
}: {
  isExpand: boolean;
  currentProduct: string;
  capabilitiesWithKnownProduct: CapabilityWithProduct[];
  capabilitiesWithOtherProduct: CapabilityWithProduct[];
  handleCompareDialog: (
    state: boolean,
    currentCapability: CapabilityWithProduct
  ) => void;
}) => {
  return (
    <Collapse in={isExpand} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
      <Grid container direction={"column"} gap={3} pt={3} px={10} pb={4}>
        {capabilitiesWithKnownProduct.length !== 0 && (
          <CapabilityTable
            isKnownProduct
            header={
              <>
                <ToolsTypeIcon type={ToolsType.KnownTools} />
                <Typography component="span" variant={"subtitle1"} ml={0.8}>
                  Currently using
                </Typography>
              </>
            }
          >
            {capabilitiesWithKnownProduct.map((capability) => (
              <CapabilityRows
                key={capability.capabilityId + "-known-table-row"}
                capability={capability}
                currentProduct={currentProduct}
                handleCompareDialog={handleCompareDialog}
              />
            ))}
          </CapabilityTable>
        )}
        {capabilitiesWithOtherProduct.length !== 0 && (
          <CapabilityTable
            header={
              <>
                <ToolsTypeIcon type={ToolsType.OtherTools} />
                <Typography component="span" variant={"subtitle1"} ml={0.8}>
                  Recommended
                </Typography>
              </>
            }
          >
            {capabilitiesWithOtherProduct.map((capability) => (
              <CapabilityRows
                key={capability.capabilityId + "-other-table-row"}
                capability={capability}
                currentProduct={currentProduct}
                handleCompareDialog={handleCompareDialog}
              />
            ))}
          </CapabilityTable>
        )}
      </Grid>
    </Collapse>
  );
};

export default PlatformInsightsDetails;
