import { AssessmentProgram } from "@lib/ShiOneClient";
import {
  aiReadinessBreakdown,
  OverviewDemoData,
  sprBreakDowns,
} from "../utils/overviewDemoData";
import { sampleSprMaturityChartResponse } from "@features/assessments-feature/utils/maturityChartDemoData";
import { sampleAiReadinessMaturityChartResponse } from "@features/assessments-feature/utils/aiReadinessMaturityChartDemoData";

export const handleOverviewDemoData = (program: AssessmentProgram) => {
  switch (program) {
    case AssessmentProgram.SecurityPostureReview:
      return OverviewDemoData(sprBreakDowns);
    case AssessmentProgram.AiReadiness:
      return OverviewDemoData(aiReadinessBreakdown);
    default:
      return null;
  }
};

export const handleMaturityDemoData = (program: AssessmentProgram) => {
  switch (program) {
    case AssessmentProgram.SecurityPostureReview:
      return sampleSprMaturityChartResponse;
    case AssessmentProgram.AiReadiness:
      return sampleAiReadinessMaturityChartResponse;
    default:
      return null;
  }
};
