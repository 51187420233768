import { Autocomplete, Button, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  asDto,
  BaseWidgetProps,
  ItemBox,
  ItemBoxConfiguration,
  KeyInformationWidgetContainer,
  WidgetWrapper,
} from "shared-ui";
import {
  AssetsWarehouseStockWidgetClient,
  WidgetContentResponseDto_1OfGetWarehouseAggregatedStockResponse,
} from "@lib/ShiOneClient";
import {
  AddCircleOutlineOutlined,
  CheckCircleOutlineOutlined,
  ErrorOutline,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { useApi } from "../../utils";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { useQuery } from "@tanstack/react-query";

function CardActions() {
  return (
    <Button href={AppendIfPrefixExists("/assets/warehouse/stock")}>
      View Warehouse Stock
    </Button>
  );
}

const placeholderData = {
  success: true,
  isEmptyDataSet: false,
  data: {
    incomingAssets: 0,
    transferringAssets: 0,
    assetsAvailableToTransfer: 0,
    productsAtLevelRisk: 0,
    projectOptions: [""],
  },
};

function transformFunction(
  rawData:
    | WidgetContentResponseDto_1OfGetWarehouseAggregatedStockResponse
    | undefined
): ItemBoxConfiguration[] {
  return [
    {
      amount: rawData?.data?.incomingAssets,
      label: "Incoming Assets",
      icon: <AddCircleOutlineOutlined fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.transferringAssets,
      label: "Outgoing Assets",
      icon: <RemoveCircleOutlineOutlined fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.assetsAvailableToTransfer,
      label: "Assets Available to Transfer",
      icon: <CheckCircleOutlineOutlined fontSize={"large"} />,
    },
    {
      amount: rawData?.data?.productsAtLevelRisk,
      label: "Products at Inventory Level Risk",
      icon: <ErrorOutline fontSize={"large"} color={"warning"} />,
    },
  ];
}

const useGetAssetsWarehouseWidgetData = (projectId: { projectId: string }) => {
  const api = useApi(AssetsWarehouseStockWidgetClient);

  const queryFunc = async () => {
    return await api.getAssetsWarehouseStockWidgetData(projectId);
  };

  return useQuery({
    queryKey: ["assets-warehouse-stock-widget", projectId],
    queryFn: queryFunc,
    placeholderData: asDto(placeholderData),
  });
};

const AssetsWarehouseStockWidget: React.FC<BaseWidgetProps> = ({
  pageLayoutWidget,
}) => {
  const [projectId, setProjectId] = useState("");
  const [projectOptions, setProjectOptions] = useState([""]);
  const { data, isLoading, isError, isPlaceholderData } =
    useGetAssetsWarehouseWidgetData(projectId);
  const [responseData, setResponseData] = useState<ItemBoxConfiguration[]>();

  useEffect(() => {
    if (data) {
      if (projectOptions.length === 1) {
        setProjectOptions(data?.data?.projectOptions);
      }
      setResponseData(transformFunction(data));
    }
  }, [data]);

  const handleProjectChange = (value: string) => {
    setProjectId(value);
  };

  return (
    <WidgetWrapper
      headerAction={
        <FormControl fullWidth className={"min-w-44"}>
          {projectOptions.length > 1 && (
            <Autocomplete
              options={projectOptions}
              value={projectId}
              onChange={(_event, newValue) => {
                handleProjectChange(newValue || "");
              }}
              getOptionLabel={(option) => (option ? option.toUpperCase() : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Project"
                  variant="outlined"
                  placeholder="All Projects"
                />
              )}
              isOptionEqualToValue={(option, value) => option === value}
            />
          )}
        </FormControl>
      }
      pageLayoutWidget={pageLayoutWidget}
      cardActions={<CardActions />}
      error={isError}
      isEmpty={data?.length === 0}
    >
      <KeyInformationWidgetContainer>
        {responseData?.map((item, index) => (
          <ItemBox
            key={typeof item.label === "string" ? item.label : `label-${index}`}
            amount={item.amount}
            label={item.label}
            icon={item.icon}
            loading={isPlaceholderData || isLoading}
            error={isError}
            tileActions={item.tileActions}
          />
        ))}
      </KeyInformationWidgetContainer>
    </WidgetWrapper>
  );
};

export default AssetsWarehouseStockWidget;
