import React from "react";
import { Autocomplete, Chip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { graphColors } from "shared-ui";
import { CapabilityInsightsProduct } from "@lib/ShiOneClient";

const ComparingProductsAutocomplete = ({
  value,
  options,
  productsMap,
  handleComparingProducts,
}: {
  value: string[];
  options: string[];
  productsMap: Map<string, CapabilityInsightsProduct>;
  handleComparingProducts: (event: any, value: string[]) => void;
}) => {
  return (
    <Autocomplete
      fullWidth
      multiple
      id="comparing-products"
      noOptionsText={
        value.length > 0
          ? "No Options"
          : "No options. Please make sure you have selected a capability"
      }
      forcePopupIcon={false}
      value={value}
      onChange={handleComparingProducts}
      options={options}
      getOptionLabel={(option) =>
        productsMap.get(option)?.productName ?? "Unknown"
      }
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        const product = productsMap.get(option);
        return (
          <li key={key} {...optionProps}>
            <img
              src={product?.productLogo}
              alt={product?.productName}
              height={"16px"}
              style={{ objectFit: "contain", maxWidth: "64px" }}
            />
            <Typography component="span" variant={"body2"} mx={1}>
              {product?.productName}
            </Typography>
          </li>
        );
      }}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select comparing products..."
          sx={{
            "& fieldset": { border: "none" },
          }}
        />
      )}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });
          const product = productsMap.get(option);
          return (
            <Chip
              variant="outlined"
              sx={{ borderColor: graphColors[index] }}
              label={
                <>
                  <img
                    src={product?.productLogo}
                    alt={product?.productName}
                    height={"16px"}
                    style={{ objectFit: "contain", maxWidth: "64px" }}
                  />
                  <Typography component="span" variant={"body2"} mx={1}>
                    {product?.productName}
                  </Typography>
                </>
              }
              key={key}
              {...tagProps}
            />
          );
        })
      }
    />
  );
};

export default ComparingProductsAutocomplete;
