import {
  Button,
  Dialog,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";

interface OnePagerDialogProps {
  pdf: string;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
}

const OnePagerDialog: React.FC<OnePagerDialogProps> = ({
  pdf,
  dialogOpen,
  setDialogOpen,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      sx={{
        "& .MuiDialog-paperFullscreen": {
          height: "93%",
          border: "none",
          maxWidth: "60%",
        },
        "& .MuiDialog-paperFullWidth": {
          height: "93%",
          maxWidth: "60%",
        },
      }}
      fullWidth
    >
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <iframe
          style={{ display: "block" }}
          height="100%"
          width="100%"
          title="Learn More"
          src={pdf}
        />
      </div>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          color={"secondary"}
          onClick={() => setDialogOpen(false)}
          endIcon={<CancelIcon />}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OnePagerDialog;
