import { Typography } from "@mui/material";
import { maturityLevelColors } from "@features/assessments-feature/utils/assessmentsColors";
import { getMaturityIcon } from "@features/assessments-feature/helpers/assessmentMaturityChartHelpers";
import React from "react";

const MaturityLabelWithIcon = ({
  maturityValue,
}: {
  maturityValue: string;
}) => {
  return (
    <Typography
      component={"div"}
      display={"flex"}
      alignItems="center"
      variant={"body2"}
      color={maturityLevelColors[maturityValue]}
    >
      {getMaturityIcon(maturityValue)}
      {maturityValue}
    </Typography>
  );
};

export default MaturityLabelWithIcon;
