import React from "react";
import Box from "@mui/material/Box";
import AssessmentMaturityChartView from "@features/assessments-feature/components/maturity-chart/AssessmentMaturityChartView";
import { assessmentTabViewMode } from "@features/assessments-feature/constants";

const MaturityChartGridExport = () => {
  return (
    <Box
      className={"assessment-break"}
      sx={{
        "& .MuiDataGrid-root": { zoom: "80%" },

        "& .MuiDataGrid-virtualScroller": {
          overflow: "visible",
          overflowY: "visible !important",
        },
        "& .MuiDataGrid-virtualScrollerContent": { height: "unset !important" },
        "& .MuiDataGrid-virtualScrollerRenderZone": { position: "static" },
      }}
    >
      <AssessmentMaturityChartView viewMode={assessmentTabViewMode.table} />
    </Box>
  );
};

export default MaturityChartGridExport;
