import React from "react";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";

const ProductLogosList = ({
  tools,
  toolsLogos,
  sx,
}: {
  tools?: string[];
  toolsLogos?: string[];
  sx?: React.CSSProperties;
}) => {
  return (
    <Box
      display={"flex"}
      gap={3}
      flexWrap={"wrap"}
      width={"100%"}
      alignItems="center"
      sx={sx}
    >
      {tools?.map((tool, index) => {
        const key = tool + index;
        const imgUrl = toolsLogos?.[index] ?? "";
        if (imgUrl)
          return (
            <Tooltip key={key} title={tool}>
              <img
                height="24px"
                src={imgUrl}
                alt={tool}
                style={{ objectFit: "contain", maxWidth: "80px" }}
              />
            </Tooltip>
          );
        else return tool;
      })}
    </Box>
  );
};

export default ProductLogosList;
