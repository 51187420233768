import { Backdrop, Grid, Stack, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import BackHandIcon from "@mui/icons-material/BackHand";
import React from "react";

const DemoModeOverlay = () => {
  return (
    <>
      <Backdrop
        open
        sx={{
          position: "absolute",
          zIndex: 1,
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
        }}
      />

      <Grid
        container
        justifyContent={"center"}
        sx={{
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: 2,
        }}
      >
        <Grid item alignSelf={"center"}>
          <Stack
            direction="column"
            justifyContent="center"
            textAlign="center"
            alignItems="center"
          >
            <BackHandIcon color={"error"} fontSize={"large"} />
            <Typography variant={"h6"} color={"textSecondary"}>
              Demo Mode
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
export default DemoModeOverlay;
