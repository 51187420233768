import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ExportComponent } from "@features/assessments-feature/types/TabTypes";

export enum PrintOrientation {
  Portrait,
  Landscape,
}
interface AssessmentExportContextState {
  isPrint: boolean;
  handlePrintMode: (value: boolean) => void;
  printLayout: PrintOrientation;
  handlePrintLayout: (value: PrintOrientation) => void;
  componentsToPrint: ((() => React.ReactNode) | undefined)[] | null;
  setIsComponentPrint: (value: { [key: string]: any }) => void;
  isComponentPrint: { [key: string]: boolean };
}

const AssessmentExportContext = createContext<
  AssessmentExportContextState | undefined
>(undefined);

export function useAssessmentExportContext() {
  const context = useContext(AssessmentExportContext);
  if (context === undefined) {
    throw new Error(
      "AssessmentExportContext must be used within a AssessmentExportContextProvider"
    );
  }
  return context;
}

interface Props {
  exportLists: ExportComponent[];
  children: React.ReactNode;
}

const AssessmentExportContextProvider = ({ exportLists, children }: Props) => {
  const [isPrint, setIsPrint] = useState<boolean>(false);
  const [printLayout, setPrintLayout] = useState<PrintOrientation>(
    PrintOrientation.Portrait
  );
  const [isComponentPrint, setIsComponentPrint] = useState<{
    [key: string]: boolean;
  }>({});
  const [componentsToPrint, setComponentsToPrint] = useState<
    ((() => React.ReactNode) | undefined)[] | null
  >(null);

  useEffect(() => {
    let componentList: ((() => React.ReactNode) | undefined)[] = [];
    Object.keys(isComponentPrint).forEach((component) => {
      if (isComponentPrint[component]) {
        componentList.push(
          exportLists.find((tab) => tab.name === component)?.component
        );
      }
    });
    setComponentsToPrint(componentList);
  }, [isComponentPrint]);

  const handlePrintMode = (value: boolean) => {
    setIsPrint(value);
  };
  const handlePrintLayout = (value: PrintOrientation) => {
    setPrintLayout(value);
  };

  const value = useMemo(
    () => ({
      componentsToPrint,
      isPrint,
      handlePrintMode,
      printLayout,
      handlePrintLayout,
      setIsComponentPrint,
      isComponentPrint,
    }),
    [isPrint, printLayout, isComponentPrint, componentsToPrint]
  );

  return (
    <AssessmentExportContext.Provider value={value}>
      {children}
    </AssessmentExportContext.Provider>
  );
};

export default AssessmentExportContextProvider;
