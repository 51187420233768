import React from "react";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import { SkeletonWrapper } from "shared-ui";
import { getMaturityIcon } from "@features/assessments-feature/helpers/assessmentMaturityChartHelpers";
import ErrorIcon from "@mui/icons-material/Error";
import SnackbarMessage from "shared-ui/src/theme/extensions/SnackbarMessage";
import { CapabilityWithCategories } from "@lib/ShiOneClient";

const CapabilityInsightSelect = ({
  error,
  loading,
  capabilities,
  capabilityMap,
  capabilitySelect,
  setCapabilitySelectChange,
}: {
  error: boolean;
  loading: boolean;
  capabilities: string[];
  capabilityMap: Map<string, CapabilityWithCategories>;
  capabilitySelect: string;
  setCapabilitySelectChange: (value: string) => void;
}) => {
  const theme = useTheme();

  const getMaturityFromCapabilityName = (name: string) => {
    return capabilityMap.get(name)?.maturity;
  };

  const handleChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setCapabilitySelectChange(value);
  };
  function getStyles(name: string, capabilitySelect: string, theme: Theme) {
    return {
      fontWeight:
        capabilitySelect === name
          ? theme.typography.fontWeightMedium
          : theme.typography.fontWeightRegular,
    };
  }
  return (
    <Box display={"flex"} alignItems={"center"} gap={2} ml={2} mt={2}>
      <Typography component={"span"} color={"textSecondary"}>
        Capability
      </Typography>
      {error && (
        <Tooltip title={"There is an error loading the data"}>
          <ErrorIcon color="error" />
        </Tooltip>
      )}
      <SkeletonWrapper loading={loading}>
        <FormControl sx={{ width: 300, bgcolor: "background.paper" }}>
          <Select
            size={"small"}
            displayEmpty
            value={capabilitySelect}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected)
                return (
                  <>
                    {getMaturityIcon(
                      getMaturityFromCapabilityName(selected) || "",
                    )}
                    <Typography component={"span"} variant={"subtitle1"}>
                      {selected}
                    </Typography>
                  </>
                );
              else
                return (
                  <Typography
                    variant={"body1"}
                    fontStyle={"italic"}
                    color={"action.disabled"}
                  >
                    Select a capability
                  </Typography>
                );
            }}
            inputProps={{ "aria-label": "capability select" }}
          >
            <MenuItem disabled value="">
              <em>Select a capability</em>
            </MenuItem>
            {capabilities.map((capability) => (
              <MenuItem
                key={capability}
                value={`${capability}`}
                style={getStyles(capability, capabilitySelect, theme)}
              >
                {getMaturityIcon(
                  getMaturityFromCapabilityName(capability) || "",
                )}
                {capability}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </SkeletonWrapper>
      {!loading && capabilities.length === 0 && (
        <SnackbarMessage
          variant={"warning"}
          message={"Capability Insights data is currently unavailable."}
        />
      )}
    </Box>
  );
};

export default CapabilityInsightSelect;
