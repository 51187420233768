import React from "react";
import { useClaims } from "../auth/ClaimsProvider";
import CustomerExperienceTwoColumnDashboard from "../v2/src/dashboards/CustomerExperienceTwoColumnDashboard";

const HomeDashboard = () => {
  const { isGuestUser } = useClaims();
  if (isGuestUser()) {
    return (
      <CustomerExperienceTwoColumnDashboard
        dashboardId={"guest-user-home-dashboard"}
      />
    );
  }
  return (
    <CustomerExperienceTwoColumnDashboard
      dashboardId={"customer-experience-home-dashboard"}
    />
  );
};

export default HomeDashboard;
