import { Grid, Typography } from "@mui/material";
import React from "react";
import { ResponseSubcategory } from "@lib/ShiOneClient";
import MaturityChartCapability from "@features/assessments-feature/components/maturity-chart/MaturityChartCapability";
import { useAssessmentExportContext } from "@features/assessments-feature/contexts-providers/AssessmentExportContextProvider";

export default function MaturityChartSubcategory({
  subcategory,
}: {
  subcategory: ResponseSubcategory;
}) {
  const { isPrint } = useAssessmentExportContext();
  const tilePadding = isPrint ? 1 : 2;
  return (
    <Grid
      container
      flexDirection={"column"}
      flexWrap={"nowrap"}
      bgcolor={"background.default"}
      sx={{
        height: "100%",
        width: "100%",
        outline: isPrint ? "0.8px gray solid" : undefined,
      }}
    >
      <Grid item>
        <Typography
          variant={"body1"}
          align={"center"}
          color={"textSecondary"}
          py={isPrint ? 1 : 3}
          fontWeight={isPrint ? 500 : 400}
        >
          {subcategory.name}
        </Typography>
      </Grid>
      <Grid
        item
        container
        gap={tilePadding}
        sx={{ px: tilePadding, pb: tilePadding, height: "100%" }}
      >
        {subcategory?.responseCapabilities?.map((capability, index) => {
          const key = (capability.name ?? "") + "-" + index;
          return <MaturityChartCapability key={key} capability={capability} />;
        })}
      </Grid>
    </Grid>
  );
}
