import React, { forwardRef, HTMLProps, ReactNode, Ref } from "react";

// Define the props type
interface ItemProps extends HTMLProps<HTMLElement> {
  children?: ReactNode;
}

export const Item = forwardRef<HTMLElement, ItemProps>(
  ({ children, ...props }, ref: Ref<HTMLElement>) => {
    return (
      <div className={props?.className} {...props} ref={ref}>
        {children}
      </div>
    );
  }
);
