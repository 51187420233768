import _ from "lodash";

export const SitePaths = [
  "logoutsaml",
  "passwordlessLogin",
  "dashboard",
  "my-support",
  "noprofile",
  "invalidurl",
  "register",
  "knowledgebase",
  "my-company",
  "support-center",
  "logincomplete",
  "callback",
  "logout",
  "settings",
  "attachment",
  "manageclients",
  "kb-admin",
  "demos",
  "knowledgebase",
  "shi",
  "shi-internal",
  "shi-internal/propensity",
  "shi-internal/renewal",
  "assets-dashboard",
  "assets",
  "warehouse",
  "orders",
  "integration-center",
  "api",
  "welcome",
  "welcomeCallback",
  "",
  "userprofile",
  "organizationprofile",
  "services",
  "successplan",
  // unused but reserved:
  "cloud",
  "shione",
  "shi1",
  "sh1",
  "shicorp",
  "shient",
  "home",
  "support",
  "faq",
  "dashboards",
  "help",
  "blog",
  "news",
  "store",
  "shop",
  "admin",
  "cost-centers",
  "cisco",
  "support-centerV2",
  "labs",
  "message-center",
  "valuestreams",
  "customersuccess",
  "success",
  "customer-experience-support-dashboard",
  "customer-experience-services-dashboard",
];
export const AppendIfPrefixExists = (path: string) => {
  const pathNames =
    window.location.pathname[0] === "/"
      ? window.location.pathname.substring(1).split("/")
      : window.location.pathname.split("/");
  const isBaseSite = _.includes(SitePaths, pathNames[0]);

  // Don't prefix an absolute path or if we're on the base site.
  if (isBaseSite || path.startsWith("http") || path.startsWith("https")) {
    return path;
  } else {
    return "/" + pathNames[0] + path;
  }
};
