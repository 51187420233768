import { faker } from "@faker-js/faker";
import {
  AssessmentOverviewDto,
  AssessmentSummary,
  CapabilityAreas,
  TargetMaturityBreakdown,
} from "@lib/ShiOneClient";

function getCurrentMaturity() {
  return faker.datatype.number({ min: 0, max: 5 });
}

function getTargetMaturity() {
  return faker.datatype.number({ min: 3, max: 5 });
}

export const sprBreakDowns = [
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Incident Response & Management",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Network Security",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Host Security",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Identity & Access Management",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "System Management",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Data Protection",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Email & Awareness",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Network Infrastructure",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Application Security",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Compliance",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Cloud Management & Protection",
  }),
];

export const aiReadinessBreakdown = [
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Policies & Protocols",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Organizational Culture",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "AI Literacy",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Business Use Cases",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Financial Models",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Governance & Risk",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Data Integration & Availability",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Data Management",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Data & ML Operations",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Data Literacy & Utilization",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Data Integration",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Infrastructure Strategy",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Governance & Risk",
  }),
  new TargetMaturityBreakdown({
    currentMaturity: getCurrentMaturity(),
    targetMaturity: getTargetMaturity(),
    subCategory: "Threat Awareness",
  }),
];

const currentMaturityScore = (breakDownList: TargetMaturityBreakdown[]) =>
  breakDownList.reduce(
    (accum, currentValue) => accum + currentValue.currentMaturity!,
    0
  );

export const OverviewDemoData = (breakDownList: TargetMaturityBreakdown[]) =>
  new AssessmentOverviewDto({
    targetMaturityBreakdown: breakDownList,
    summary: new AssessmentSummary({
      maturityScore: Number.parseFloat(
        (
          currentMaturityScore(breakDownList) / breakDownList.length
        ).toLocaleString("en-US", {
          maximumFractionDigits: 1,
          minimumFractionDigits: 0,
        }),
      ),
      capabilityAreas: new CapabilityAreas({
        optimizedAreas: faker.datatype.number({ min: 0, max: 50 }),
        operationalAreas: faker.datatype.number({ min: 0, max: 40 }),
        functionalAreas: faker.datatype.number({ min: 0, max: 35 }),
        evaluatingAreas: faker.datatype.number({ min: 0, max: 60 }),
        areasWithGaps: faker.datatype.number({ min: 0, max: 20 }),
        unknown: faker.datatype.number({ min: 0, max: 50 }),
      }),
      recommendations: faker.datatype.number({ min: 0, max: 50 }),
    }),
  });
