import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import DashboardConfiguration from "../DashboardConfiguration";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { DashboardCustomize } from "@mui/icons-material";
import { PageLayoutDto } from "../../generated/models";

function useCloneConfiguration(pageConfiguration: PageLayoutDto | undefined) {
  const [localPageConfiguration, setLocalPageConfiguration] = React.useState<
    PageLayoutDto | undefined
  >(undefined);

  useEffect(() => {
    const clonedPageConfiguration = _.cloneDeep(pageConfiguration);
    setLocalPageConfiguration(clonedPageConfiguration);
  }, [pageConfiguration, setLocalPageConfiguration]);

  return { localPageConfiguration, setLocalPageConfiguration };
}

export function DashboardConfigurationDialog({
  pageConfiguration,
  setPageConfiguration,
}: {
  pageConfiguration: PageLayoutDto | undefined;
  setPageConfiguration: (pageLayoutDto: PageLayoutDto) => void;
}) {
  const [showDashboardConfiguration, setShowDashboardConfiguration] =
    useState(false);
  const { localPageConfiguration, setLocalPageConfiguration } =
    useCloneConfiguration(pageConfiguration);

  if (!pageConfiguration) {
    return <></>;
  }

  function onSaveClick() {
    if (localPageConfiguration) {
      setPageConfiguration(localPageConfiguration);
    }
    setShowDashboardConfiguration(false);
  }

  function onCancelClick() {
    const originalPageConfiguration = _.cloneDeep(pageConfiguration);
    setLocalPageConfiguration(originalPageConfiguration);
    setShowDashboardConfiguration(false);
  }
  const handleDashboardName = (dashboardId: string | undefined) => {
    let name: string = "";
    if (dashboardId) {
      const nameArray = dashboardId.split("-").slice(2);
      name = nameArray.join(" ");
    }
    return name;
  };
  return (
    <div>
      <Box sx={{ textAlign: "center", marginTop: 4 }}>
        <Button
          startIcon={<DashboardCustomize />}
          variant="outlined"
          color="inherit"
          onClick={() => setShowDashboardConfiguration(true)}
        >
          Customize Dashboard
        </Button>
      </Box>
      <Dialog
        open={showDashboardConfiguration}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>
          <Box>
            <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
              Customize {handleDashboardName(pageConfiguration.pageLayoutId)}
            </Typography>
            <Typography variant="body2">
              Select and organize widgets you want to see on your dashboard
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DashboardConfiguration
            pageConfiguration={localPageConfiguration}
            setPageConfiguration={setLocalPageConfiguration}
          />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button color="inherit" onClick={onCancelClick}>
            Cancel
          </Button>
          <Button color="primary" onClick={onSaveClick}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
