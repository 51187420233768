import React, { useEffect, useLayoutEffect, useReducer, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Drawer,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { GetBearerToken } from "../../../../../auth/BearerToken";
import { combineReducers } from "./combineReducers";
import { mappingActions, mappingReducer } from "./mappingReducer";
import { excelSheetActions, excelSheetReducer } from "./excelSheetReducer";
import useMappingEngineSchema from "../hooks/useMappingEngineSchema";
import Row from "./Row";
import ExcelSheetRow from "./ExcelSheetRow";
import FileUpload from "./FileUpload";
import { useClaims } from "../../../../../auth/ClaimsProvider";
import JsonView from "./JsonView";
import { ExcelSheet, MappingRow } from "./types";
import NotesIcon from "@mui/icons-material/Notes";
import {
  MappingDto,
  MappingEngineClient,
  MappingRowsResultDto,
} from "@lib/ShiOneClient";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import { useMutation } from "@tanstack/react-query";
import { Save } from "@mui/icons-material";

const initState = {
  mappings: [],
  excelSheets: [],
};

const rootReducer = combineReducers({
  mappings: mappingReducer,
  excelSheets: excelSheetReducer,
});

const api = new MappingEngineClient(
  ApiClientConfig,
  import.meta.env.API_ENDPOINT
);

export const mappingValue = (
  state: { [x: string]: any; mappings?: any; excelSheets?: any },
  uploadResponse: MappingRowsResultDto | undefined,
  isPublished: boolean
) => {
  const validItems = state.mappings.filter(
    (item: { valid: boolean }) => item.valid
  );
  const validSheets = state.excelSheets.filter(
    (item: { valid: boolean }) => item.valid
  );

  const mappingJson = {
    status: isPublished ? "Published" : "Draft",
    fileType: uploadResponse?.fileType,
    excelSheets: validSheets.map((item: ExcelSheet) => ({
      locationPattern: item.locationPattern,
      locationKey: item.locationKey,
      sourceSheetName: item?.sourceSheetName,
    })),

    mappings: validItems.map((item: { [key: string]: any }) => {
      const mappingObj: { [key: string]: any } = {
        sourceColumn: item.column,
        locationKey: item.excelSheet.locationKey,
        targetColumn: item.mappedCol.name,
        targetColumnType: item.mappedCol.type,
        naOverride: item.naOverride,
        sourceSheetName: item.excelSheet.sourceSheetName,
      };

      if (item.mappedColDateFormat) {
        mappingObj.sourceColDateFormat = item.mappedColDateFormat;
      }
      return mappingObj;
    }),
  };

  return JSON.stringify(mappingJson, null, 2);
};

export default function Mapping() {
  const { id: schemaId } = useParams();
  const { isShiAdmin } = useClaims();
  const [state, dispatch] = useReducer(rootReducer, initState);
  const [files, setFiles] = useState([]);
  const [uploadResponse, setUploadResponse] = useState<
    MappingRowsResultDto | undefined
  >(undefined);
  const [token, setToken] = useState(null);
  const [published, setPublished] = useState(false);
  const [description, setDescription] = useState("");
  const [jsonShowing, setJsonShowing] = useState(false);
  const [mappingSchemaId, setMappingSchemaId] = useState(-1);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { data } = useMappingEngineSchema({
    url: "https://shionestorage.blob.core.windows.net/shione/sample-mapping-schema.json",
  });

  const mutation = useMutation({
    mutationFn: async () => {
      const excelRowList = uploadResponse?.excelRowsList?.map((item) => {
        return JSON.stringify({
          columnName: item.columnName,
          sheetName: item.sheetName,
          exampleData: item.exampleData,
        });
      });

      return await api.createUpdateMapping(
        mappingSchemaId,
        Number.parseInt(schemaId!),
        1,
        description ?? "",
        mappingValue(state, uploadResponse, published),
        published,
        uploadResponse?.guid,
        uploadResponse?.fileType,
        // @ts-ignore
        excelRowList,
        uploadResponse?.csvRowsList ?? []
      );
    },
    onSuccess: (updatedMapping: MappingDto) => {
      setMappingSchemaId(updatedMapping.id!);
      setIsSuccess(true);
      setError(false);
    },
    onError: () => {
      setIsSuccess(false);
      setError(true);
    },
  });

  useLayoutEffect(() => {
    api.getMappingBySchemaId(Number.parseInt(schemaId!)).then((mappingDto) => {
      if (mappingDto) {
        setMappingSchemaId(mappingDto.id!);
        const jsonData = JSON.parse(mappingDto.mapping!);
        const mappingFileType = jsonData["fileType"];
        if (mappingFileType === "Excel") {
          const mappingExcelSheets = jsonData["excelSheets"];
          const mappings = jsonData["mappings"];
          const status = jsonData["status"];
          const isPublished = status !== "Draft";

          setDescription(mappingDto?.description ?? "");
          setPublished(isPublished);
          const uploadResponse = mappingDto.uploadResponse!;
          setUploadResponse(uploadResponse);

          const newMappedExcelSheets: {
            type: excelSheetActions;
            locationKey: any;
            locationPattern: any;
            sourceSheetName: any;
            id: any;
          }[] = [];
          mappingExcelSheets.forEach((item: ExcelSheet) => {
            const newExcelSheet = {
              type: excelSheetActions.populate_excel,
              locationKey: item.locationKey,
              locationPattern: item.locationPattern,
              sourceSheetName: item.sourceSheetName,
              id: uuidv4(),
            };

            newMappedExcelSheets.push(newExcelSheet);
            dispatch(newExcelSheet);
          });

          mappings.forEach((item: { [key: string]: any }) => {
            const excelSheet = newMappedExcelSheets?.filter(
              (sheet) => sheet.sourceSheetName === item?.sourceSheetName
            );
            const newItem = {
              type: mappingActions.populate_mapping,
              mappingColName: item.targetColumn,
              mappingColType: item.targetColumnType,
              sourceColDateFormat: item?.sourceColDateFormat,
              column: item.sourceColumn,
              naOverride: item.naOverride,
              excelSheetLocationKey: item.locationKey,
              excelSourceSheetName: excelSheet[0]?.sourceSheetName,
              excelSheetId: excelSheet[0]?.id,
              excelSheetPattern: excelSheet[0]?.locationPattern,
            };
            dispatch(newItem);
          });
        }
      }
    });
  }, [schemaId]);

  useEffect(() => {
    (async () => {
      // @ts-ignore
      setToken(await GetBearerToken());
    })();
  }, []);

  if (!isShiAdmin()) {
    return null;
  }
  const handleDesc = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDescription(e.target.value);
  };

  return (
    <>
      <Snackbar
        open={isSuccess || error}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={1500}
        onClose={() => {
          setIsSuccess(false);
          setError(false);
        }}
      >
        <Alert
          severity={!error ? "success" : "error"}
          onClose={() => {
            setIsSuccess(false);
            setError(false);
          }}
        >
          {!error ? "Successfully updated mapping" : "Error updating mapping"}
        </Alert>
      </Snackbar>
      <div className="flex justify-between">
        <div>
          <TextField
            label="Description"
            className="mr-4"
            onChange={handleDesc}
            value={description}
            size={"small"}
          />
          <FormControl className="min-w-32">
            <InputLabel id="version-label">Version</InputLabel>
            <Select
              fullWidth
              label="Version"
              variant="outlined"
              disabled
              defaultValue={1}
              labelId="version-label"
              size={"small"}
            >
              <MenuItem value={1}>1</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            className={"ml-2"}
            control={
              <Switch
                color={"secondary"}
                checked={published}
                onChange={() => setPublished(!published)}
                name="Published"
              />
            }
            label="Published"
          />
        </div>
        <div className={"flex gap-2"}>
          <Button
            variant={"outlined"}
            onClick={() => setJsonShowing(!jsonShowing)}
            startIcon={<NotesIcon />}
            color={"inherit"}
          >
            {jsonShowing ? "Hide JSON" : "Show Json"}
          </Button>
          <Button
            startIcon={<Save />}
            onClick={() => mutation.mutate()}
            variant={"outlined"}
          >
            Save
          </Button>
        </div>
      </div>
      <FileUpload
        files={files}
        setFiles={setFiles}
        setUploadResponse={setUploadResponse}
        // @ts-ignore
        token={token}
      />
      {uploadResponse?.fileType === "Excel" && (
        <Card className="mt-4">
          <Typography variant="h6" className="text-center pt-4">
            Available Sheets
          </Typography>
          {state.excelSheets.map((sheet: ExcelSheet) => (
            <ExcelSheetRow
              key={sheet.id}
              sheet={sheet}
              dispatch={dispatch}
              uploadResponse={uploadResponse}
            />
          ))}
          <Button
            onClick={() => dispatch({ type: excelSheetActions.add_excel })}
            startIcon={<AddIcon />}
          >
            Add Sheet
          </Button>
        </Card>
      )}
      <div className="flex justify-between">
        <div className="mt-8 grow">
          {state.mappings.map((mapping: MappingRow) => (
            <Row
              key={mapping.id}
              mapping={mapping}
              dispatch={dispatch}
              data={data}
              state={state}
              // @ts-ignore
              uploadResponse={uploadResponse}
            />
          ))}

          {uploadResponse && (
            <Button
              onClick={() => dispatch({ type: mappingActions.add_mapping })}
              startIcon={<AddIcon />}
            >
              Add Row
            </Button>
          )}
        </div>
        <Drawer
          anchor={"right"}
          open={jsonShowing}
          onClose={() => setJsonShowing(false)}
          keepMounted
        >
          <JsonView
            state={state}
            // @ts-ignore
            uploadResponse={uploadResponse}
            isPublished={published}
          />
        </Drawer>
      </div>
    </>
  );
}
