import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getMaturityChartCategoryIcon } from "@features/assessments-feature/helpers/assessmentMaturityChartHelpers";
import MaturityChartSubcategory from "@features/assessments-feature/components/maturity-chart/MaturityChartSubcategory";
import { ResponseCategory } from "@lib/ShiOneClient";

const MaturityChartCards = ({
  responseCategories,
}: {
  responseCategories?: ResponseCategory[];
}) => {
  return (
    <>
      {responseCategories?.map((category) => {
        return (
          <Accordion key={category.name} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {getMaturityChartCategoryIcon(category.name ?? "")}
              <Typography variant={"body1"} color={"textSecondary"}>
                {category.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container flexDirection={"row"} gap={1} columns={16}>
                {category?.responseSubcategories?.map((subcategory, index) => {
                  const key = (subcategory.name ?? "") + index;
                  return (
                    <MaturityChartSubcategory
                      key={key}
                      subcategory={subcategory}
                    />
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default MaturityChartCards;
