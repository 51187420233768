import {
  assessmentTabIds,
  assessmentTabViewMode,
} from "@features/assessments-feature/constants";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import TableRowsIcon from "@mui/icons-material/TableRows";
import TableChartIcon from "@mui/icons-material/TableChart";
import React from "react";

export const maturityChartViewMode = [
  {
    label: assessmentTabViewMode.card,
    icon: <ViewAgendaIcon color={"inherit"} fontSize={"small"} />,
  },
  {
    label: assessmentTabViewMode.table,
    icon: <TableRowsIcon color={"inherit"} fontSize={"small"} />,
  },
];

export const recsViewMode = [
  {
    label: assessmentTabViewMode.list,
    icon: <TableRowsIcon color={"inherit"} fontSize={"small"} />,
  },
  {
    label: assessmentTabViewMode.table,
    icon: <TableChartIcon color={"inherit"} fontSize={"small"} />,
  },
];

export const assessmentTabViewModeMap: Map<
  assessmentTabIds,
  assessmentTabViewMode
> = new Map<assessmentTabIds, assessmentTabViewMode>([
  [assessmentTabIds.maturityChart, assessmentTabViewMode.card],
  [assessmentTabIds.recommendations, assessmentTabViewMode.list],
]);
