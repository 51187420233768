import { NavItemType } from "../../NavigationTypes";

type KnowledgeBaseMenuParameters = {
  knowledgebase: any;
  showKnowledgeBase?: boolean;
};

export function KnowledgeBaseMenu({
  knowledgebase,
  showKnowledgeBase,
}: KnowledgeBaseMenuParameters): NavItemType {
  const { contentUnits } = knowledgebase;
  const kbUnits = ((contentUnits as []) ?? [])
    .sort((a: any, b: any) => a.title - b.title)
    .map((cu: any) => {
      return {
        id: "knowledgeBaseUnit-" + cu.identifier,
        type: "Link",
        displayName: cu.title,
        path: cu.url,
        target: "_blank",
        visible: true,
      };
    });
  return {
    id: "knowledgeBase",
    type: "Group",
    displayName: "Knowledge Base",
    visible: showKnowledgeBase && kbUnits.length > 0,
    children: [...kbUnits],
  };
}
