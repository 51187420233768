import React, { memo, useCallback } from "react";
import {
  Button,
  Card,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  EditOff,
  ErrorOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import DateFormatAutocomplete from "./DateFormatAutoComplete";
import { mappingActions } from "./mappingReducer";
import { MappingRow } from "./types";
import { MappingRowsResultDto } from "@lib/ShiOneClient";
import MemoMappedAutoComplete from "./MappedColAutoComplete";
import MemoExcelColumnAutoComplete from "./ExcelColumnAutoComplete";

const MemoRowDelete = memo(function RowDelete(props: {
  editMode: boolean;
  onClick: () => any;
}) {
  return (
    <>
      {props.editMode && (
        <IconButton color="error" onClick={props.onClick}>
          <DeleteIcon />
        </IconButton>
      )}
    </>
  );
});

const MemoExcelSheetSelect = memo(function ExcelSheetSelect(props: {
  item: MappingRow;
  onChange: (e: any) => void;
  renderValue: (item: any) => any;
  excelSheets: any;
  element: (excelSheet: any) => React.JSX.Element;
}) {
  return (
    <Select
      fullWidth
      displayEmpty
      variant="outlined"
      disabled={props.item.naOverride}
      onChange={props.onChange}
      name="excelSheet"
      value={props.item.excelSheet}
      renderValue={props.renderValue}
    >
      <MenuItem value="" disabled>
        Select a Sheet
      </MenuItem>
      {props.excelSheets.map(props.element)}
    </Select>
  );
});

const MemoNaOverrideSelect = memo(function NaOverrideSelect(props: {
  item: MappingRow;
  onChange: () => any;
}) {
  return (
    <FormControlLabel
      control={
        <Switch checked={props.item.naOverride} onChange={props.onChange} />
      }
      label="N/A Override"
    />
  );
});

export default function Row({
  mapping,
  dispatch,
  data,
  uploadResponse,
  state,
}: {
  mapping: MappingRow;
  dispatch: any;
  data: any;
  uploadResponse: MappingRowsResultDto;
  state: any;
}) {
  const handleMappingChange = useCallback(
    (e: { target: { name: string; value: any } }, id: string) => {
      dispatch({
        type: mappingActions.update_mapping,
        name: e.target.name,
        value: e.target.value,
        id,
      });
    },
    []
  );

  const handleAutoCompleteMappingChange = useCallback(
    (name: string, value: any, id: string) => {
      dispatch({
        type: mappingActions.update_mapping,
        name,
        value,
        id,
      });
    },
    []
  );

  const toggleEditMode = useCallback(
    (item: { editMode: boolean; id: string }) => {
      dispatch({
        type: mappingActions.update_mapping,
        name: "editMode",
        value: !item.editMode,
        id: item.id,
      });
    },
    []
  );

  const excelEditMode = (item: MappingRow) => {
    if (item.editMode) {
      return (
        <>
          <MemoExcelSheetSelect
            item={item}
            onChange={(e) => handleMappingChange(e, item.id)}
            renderValue={(item) => item.sourceSheetName}
            excelSheets={state.excelSheets}
            element={(excelSheet) =>
              excelSheet.valid && (
                <MenuItem key={excelSheet.id} value={excelSheet}>
                  {excelSheet.locationKey}
                </MenuItem>
              )
            }
          />
          {item.excelSheet.locationKey.length > 0 && (
            <MemoExcelColumnAutoComplete
              item={item}
              uploadResponse={uploadResponse}
              handleMappingChange={handleMappingChange}
            />
          )}
          <MemoNaOverrideSelect
            item={item}
            onChange={() =>
              dispatch({ id: item.id, type: mappingActions.na_override })
            }
          />
        </>
      );
    }
    return (
      <div className="flex text-center grow hover:cursor-pointer">
        <Typography
          variant="body2"
          className="text-center"
          onClick={() => toggleEditMode(item)}
        >
          {item?.excelSheet.locationKey} - {item.column}
        </Typography>
      </div>
    );
  };

  return (
    <div className="flex pb-6 gap-3 w-full items-center">
      <Button
        startIcon={mapping.editMode ? <EditOff /> : <EditIcon />}
        onClick={() => toggleEditMode(mapping)}
      />
      <div>
        {mapping.valid ? (
          <VerifiedOutlined color="success" />
        ) : (
          <ErrorOutlined color="error" />
        )}
      </div>
      <Card className="w-4/12 grow">
        <div className="flex grow justify-between gap-3 p-2">
          {excelEditMode(mapping)}
        </div>
      </Card>
      <Card className="w-4/12 grow">
        {mapping.editMode && (
          <div className="flex gap-3 p-2">
            <MemoMappedAutoComplete
              mapping={mapping}
              data={data}
              handleMappingChange={handleMappingChange}
            />
            {mapping?.mappedCol?.type === "date" && (
              <DateFormatAutocomplete
                value={mapping.mappedColDateFormat}
                setValue={(e: { target: { defaultValue: any } }) => {
                  handleAutoCompleteMappingChange(
                    "mappedColDateFormat",
                    e.target.defaultValue,
                    mapping.id
                  );
                }}
              />
            )}
            <MemoRowDelete
              editMode={mapping.editMode}
              onClick={() =>
                dispatch({
                  type: mappingActions.delete_mapping,
                  id: mapping.id,
                })
              }
            />
          </div>
        )}
        {!mapping.editMode && (
          <div className="flex gap-4 p-2">
            <Typography variant="body2" className="text-center">
              <span>{mapping?.mappedCol?.name}</span>
              <span className={"ml-4"}>
                {mapping?.mappedCol?.type === "date" &&
                  mapping?.mappedColDateFormat &&
                  `Date format: ${mapping?.mappedColDateFormat}`}
              </span>
            </Typography>
          </div>
        )}
      </Card>
    </div>
  );
}
