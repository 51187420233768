import { useEffect, useRef, useState } from "react";

const UseGetFillerContainerWidth = () => {
  const [fillerWidth, setFillerWidth] = useState<number>(0);
  const cellRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleFillerWidth = () => {
      if (cellRef.current) {
        setFillerWidth(cellRef.current.clientWidth);
      }
    };
    const observer = new MutationObserver(() => {
      if (cellRef.current) {
        handleFillerWidth();

        // Set up ResizeObserver after the element is available
        const resizeObserver = new ResizeObserver(() => {
          handleFillerWidth();
        });
        resizeObserver.observe(cellRef.current);

        // Disconnect MutationObserver once we have the element
        observer.disconnect();

        // Cleanup ResizeObserver on unmount
        return () => {
          resizeObserver.disconnect();
        };
      }
    });
    // Start observing the document for changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }, []);
  return {
    cellRef,
    fillerWidth,
  };
};

export default UseGetFillerContainerWidth;
