import {
  AssessmentMaturityChartDto,
  ResponseCapability,
  ResponseCategory,
  ResponseSubcategory,
} from "@lib/ShiOneClient";

const businessCategory = new ResponseCategory({
  name: "Business",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "AI Literacy",
      responseCapabilities: [
        new ResponseCapability({
          name: "Generative AI Platform",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "AI Literacy",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Policies & Compliance",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "AI Enablement Program",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Culture & Community Engagement",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
    new ResponseSubcategory({
      name: "Business Use Cases",
      responseCapabilities: [
        new ResponseCapability({
          name: "Prioritized Business Use Cases",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Methodology for Evaluating Business Use Cases",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Technology Alignment",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
    new ResponseSubcategory({
      name: "Financial Models",
      responseCapabilities: [
        new ResponseCapability({
          name: "Prioritized Business Use Cases",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Methodology for Evaluating Business Use Cases",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Technology Alignment",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

const infraCategory = new ResponseCategory({
  name: "Infrastructure",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "Infrastructure Strategy",
      responseCapabilities: [
        new ResponseCapability({
          name: "Cluster Management",
          maturity: "Unknown",
          tools: ["None"],
          hasRecommendation: false,
        }),
        new ResponseCapability({
          name: "Accelerated Compute (CPU)",
          maturity: "Unknown",
          tools: ["None"],
          hasRecommendation: false,
        }),
        new ResponseCapability({
          name: "Model Inferencing",
          maturity: "Unknown",
          tools: ["None"],
          hasRecommendation: false,
        }),
        new ResponseCapability({
          name: "Model Training",
          maturity: "Unknown",
          tools: ["None"],
          hasRecommendation: false,
        }),
        new ResponseCapability({
          name: "Model Customization (Tuning)",
          maturity: "Unknown",
          tools: ["None"],
          hasRecommendation: false,
        }),
        new ResponseCapability({
          name: "HPC",
          maturity: "Unknown",
          tools: ["None"],
          hasRecommendation: false,
        }),
      ],
    }),
    new ResponseSubcategory({
      name: "Hybrid / Data Strategy",
      responseCapabilities: [
        new ResponseCapability({
          name: "High-bandwidth Networking",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "CPU Interconnect",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Implementation of software controls like PFC and ECN",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Optimal latency and throughput performance of data center networks",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Infrastructure optimization to manage power consumption in AI deployment",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Use of technologies that deliver more output while consuming less power",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Chilled Water Systems",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Sustainability",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
    new ResponseSubcategory({
      name: "Infrastructure Design",
      responseCapabilities: [
        new ResponseCapability({
          name: "Validated Designs",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

const dataCategory = new ResponseCategory({
  name: "Data",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "Data Integration & Availability",
      responseCapabilities: [
        new ResponseCapability({
          name: "High Performance Object Storage",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Distributed Storage Systems",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Data Integration",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "High Performance File Storage",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
    new ResponseSubcategory({
      name: "Data Management",
      responseCapabilities: [
        new ResponseCapability({
          name: "Data Lakehouse & Warehouse",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Data Policies and Standards",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Platform Monitoring and Alerting",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Data Quality Validation",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Security and Compliance",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Databases - Relational, Vector, NoSQL",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
    new ResponseSubcategory({
      name: "Data Literacy & Utilization",
      responseCapabilities: [
        new ResponseCapability({
          name: "Data Visualization",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Predictive Analytics",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Machine Learning",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "AI / Generative AI",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Data Readiness",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

const securityCategory = new ResponseCategory({
  name: "Security",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "Governance & Risk",
      responseCapabilities: [
        new ResponseCapability({
          name: "Regular data protection training for employees",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Implementation of advanced encryption or end-to-end encryption to protect data",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Compliance with data protection and privacy regulations",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Security, and governance standards",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Understanding and adherence to global data privacy standards",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
    new ResponseSubcategory({
      name: "Overall Security Posture",
      responseCapabilities: [
        new ResponseCapability({
          name: "Robust cybersecurity measures in place",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Ability to detect and prevent adversarial attacks on AI models",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Regular cybersecurity audits and reviews",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Clear communication lines for reporting and managing incidents",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Protocols in place for handling data breaches or other security incidents",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Regular testing and updating of incident response plans",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
    new ResponseSubcategory({
      name: "Financial Models",
      responseCapabilities: [
        new ResponseCapability({
          name: "Regular updates and training on emerging threats",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "High level of awareness of security threats specific to AI workloads",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

export const sampleAiReadinessMaturityChartResponse =
  new AssessmentMaturityChartDto({
    responseCategories: [
      businessCategory,
      dataCategory,
      infraCategory,
      securityCategory,
    ],
  });
