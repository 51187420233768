import React from "react";
import { Editor } from "@monaco-editor/react";
import { Button, useTheme } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { mappingValue } from "./Mapping";
import { MappingRowsResultDto } from "@lib/ShiOneClient";

export default function JsonView({
  state,
  uploadResponse,
  isPublished,
}: {
  state: any;
  uploadResponse: MappingRowsResultDto;
  isPublished: boolean;
}) {
  const currentTheme = useTheme();

  async function writeClipboardText(text: string) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      // @ts-ignore
      console.error(error.message);
    }
  }

  return (
    <div className={"flex"} style={{ height: "100%", width: "50rem" }}>
      <div>
        <Button
          onClick={() =>
            writeClipboardText(mappingValue(state, uploadResponse, isPublished))
          }
          startIcon={<ContentCopyIcon />}
        >
          Copy
        </Button>
      </div>

      <Editor
        height={"100%"}
        defaultLanguage="json"
        value={mappingValue(state, uploadResponse, isPublished)}
        theme={currentTheme.palette.mode === "dark" ? "vs-dark" : "light"}
        options={{
          readOnly: true,
          automaticLayout: true,
          folding: true,
          formatOnType: true,
          formatOnPaste: true,
          minimap: { enabled: true },
        }}
      />
    </div>
  );
}
