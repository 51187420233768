import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import {
  PageLayoutWidgetDto,
  ServiceRequestClient,
  ServiceRequestQueryType,
  SortOrder,
} from "@lib/ShiOneClient";
import {
  chipIt,
  getRequestStatusColor,
  linkToServiceRequest,
  standardizeDateFormat,
  useApi,
} from "../../utils";
import RequestsWidget from "@widgets/support/RequestsWidget";
import Box from "@mui/material/Box";
import { asDto, SkeletonWrapper, WidgetStylesType } from "shared-ui";
import { SortingOrder } from "../../../../components/mui-data-grid/constants";
import { NavLink, useLocation } from "react-router-dom";
import { useFeatureFlag } from "shared-ui/src/utils/FeatureFlagHelper";
import { Tooltip } from "@mui/material";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import useWarehouseOrderMap from "@features/assets-feature/hooks/warehouse/useWarehouseOrderMap";

const placeholderData = {
  totalCount: 5,
  serviceRequestDtos: [
    {
      id: 1,
      status: "New",
      title: "Test",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 2,
      status: "New",
      title: "Test",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 3,
      status: "New",
      title: "Test",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 4,
      status: "New",
      title: "Test",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 5,
      status: "New",
      title: "Test",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
  ],
};

const warehousePlaceholderData = {
  totalCount: 5,
  serviceRequestDtos: [
    {
      id: 1,
      status: "New",
      title: "Test",
      requestIdentifier: "",
      purchaseOrderNumber: "",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 2,
      status: "New",
      title: "Test",
      requestIdentifier: "",
      purchaseOrderNumber: "",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 3,
      status: "New",
      title: "Test",
      requestIdentifier: "",
      purchaseOrderNumber: "",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 4,
      status: "New",
      title: "Test",
      requestIdentifier: "",
      purchaseOrderNumber: "",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
    {
      id: 5,
      status: "New",
      title: "Test",
      requestIdentifier: "",
      purchaseOrderNumber: "",
      lastModifiedDate: "2023-10-02T10:26:03Z",
    },
  ],
};

function getColumnDefs(
  loading: boolean,
  error: boolean,
  styles: WidgetStylesType,
  pathname: string
) {
  return {
    sortedColumns: [
      { field: "lastModifiedDate", sort: SortingOrder.Descending },
    ],
    columnDefinitions: [
      {
        id: "title",
        headerName: "Title",
        sortable: true,
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <SkeletonWrapper loading={loading || error}>
              <Box sx={styles.twoLineHeightCell}>
                {linkToServiceRequest(params, pathname)}
              </Box>
            </SkeletonWrapper>
          );
        },
      },
      {
        id: "status",
        headerName: "Status",
        sortable: true,
        minWidth: 200,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <SkeletonWrapper loading={loading || error}>
              {chipIt(params, {
                backgroundColor: getRequestStatusColor(params.value),
                color: "white",
                borderColor: getRequestStatusColor(params.value),
              })}
            </SkeletonWrapper>
          );
        },
      },
      {
        id: "lastModifiedDate",
        headerName: "Last Updated",
        sortable: true,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <SkeletonWrapper loading={loading || error}>
              <span>{standardizeDateFormat(params)}</span>
            </SkeletonWrapper>
          );
        },
      },
    ],
  };
}

const navLinkPath = `/assets/warehouse/orders`;

const maxNumberOfRowsToFetch = 50;

const query = {
  query: {
    sortBy: "LastModifiedDate",
    sortOrder: SortOrder.Desc,
    termFilters: { ActiveOrComplete: "Active" },
    dateFilters: {},
    searchTerm: "",
    resultsFrom: 0,
    pageSize: maxNumberOfRowsToFetch,
  },
  serviceRequestQueryType: ServiceRequestQueryType.PrimaryContactRequests,
};

function useGetElasticServiceRequests() {
  const api = useApi(ServiceRequestClient);
  const { enabled: wareHousePickOrdersEnabled } = useFeatureFlag(
    "services.enable-warehouse-pick-orders"
  );

  const placeData = wareHousePickOrdersEnabled
    ? warehousePlaceholderData
    : placeholderData;

  return useQuery(
    [`my-requests-widget`],
    // @ts-ignore
    () => api.searchServiceRequests(query),
    {
      placeholderData: asDto(placeData),
    }
  );
}

export default function MyRequestsWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const [orders] = useWarehouseOrderMap();

  function getFFMyRequest(
    loading: boolean,
    error: boolean,
    styles: WidgetStylesType,
    pathname: string
  ) {
    return {
      sortedColumns: [
        { field: "lastModifiedDate", sort: SortingOrder.Descending },
      ],
      columnDefinitions: [
        {
          id: "title",
          headerName: "Title",
          sortable: true,
          minWidth: 150,
          flex: 1,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <SkeletonWrapper loading={loading || error}>
                <Box sx={styles.twoLineHeightCell}>
                  {linkToServiceRequest(params, pathname)}
                </Box>
              </SkeletonWrapper>
            );
          },
        },
        {
          id: "requestIdentifier",
          headerName: "Request ID",
          flex: 0.5,
        },
        {
          id: "purchaseOrderNumber",
          headerName: "Purchase Order",
          flex: 0.5,
          renderCell: (params: GridRenderCellParams) => {
            const mapItem = orders.get(params.value);
            if (mapItem) {
              if (mapItem.size === 1) {
                const key = mapItem.keys().next().value;
                const currentItem = mapItem.get(key);
                return (
                  <Tooltip title={params.value} followCursor>
                    <NavLink
                      to={AppendIfPrefixExists(
                        `${navLinkPath}/${currentItem.uniqueIdentifier}`
                      )}
                    >
                      {params.value}
                    </NavLink>
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip title={params.value} followCursor>
                    <NavLink
                      to={AppendIfPrefixExists(
                        `${navLinkPath}?search=${params.value}`
                      )}
                    >
                      {params.value}
                    </NavLink>
                  </Tooltip>
                );
              }
            } else {
              return params.value;
            }
          },
        },
        {
          id: "status",
          headerName: "Status",
          sortable: true,
          flex: 0.4,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <SkeletonWrapper loading={loading || error}>
                {chipIt(params, {
                  backgroundColor: getRequestStatusColor(params.value),
                  color: "white",
                  borderColor: getRequestStatusColor(params.value),
                })}
              </SkeletonWrapper>
            );
          },
        },
        {
          id: "lastModifiedDate",
          headerName: "Last Updated",
          sortable: true,
          minWidth: 0.8,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <SkeletonWrapper loading={loading || error}>
                <span>{standardizeDateFormat(params)}</span>
              </SkeletonWrapper>
            );
          },
        },
      ],
    };
  }

  const location = useLocation();
  const { enabled: wareHousePickOrdersEnabled, loading } = useFeatureFlag(
    "services.enable-warehouse-pick-orders"
  );

  const getColsDef = wareHousePickOrdersEnabled
    ? getFFMyRequest
    : getColumnDefs;

  return (
    <RequestsWidget
      pageLayoutWidget={pageLayoutWidget}
      useQueryFunction={useGetElasticServiceRequests}
      columnDefinitionsFunction={(loading, error, styles) =>
        getColsDef(loading, error, styles, location.pathname)
      }
      loading={loading}
    />
  );
}
