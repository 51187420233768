import {
  IPlatformInsightCapability,
  IPlatformInsightProduct,
} from "@lib/ShiOneClient";

export enum ToolsType {
  KnownTools,
  OtherTools,
}

export interface CapabilityWithProduct extends IPlatformInsightCapability {
  currentlyUsingTools: string[];
}
export interface GroupedProduct extends IPlatformInsightProduct {
  capabilitiesWithKnownProducts: CapabilityWithProduct[];
  capabilitiesWithOtherProducts: CapabilityWithProduct[];
  hasUsingTools: boolean;
}
