import {
  AssessmentMaturityChartDto,
  ResponseCapability,
  ResponseCategory,
  ResponseSubcategory,
} from "@lib/ShiOneClient";

const endUserCategory = new ResponseCategory({
  name: "End User",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "Host Security",
      responseCapabilities: [
        new ResponseCapability({
          name: "EPP / EDR",
          maturity: "Unknown",
          tools: ["None"],
          hasRecommendation: false,
        }),
        new ResponseCapability({
          name: "MDM / IOT",
          maturity: "Unknown",
          tools: ["None"],
          hasRecommendation: false,
        }),
        new ResponseCapability({
          name: "FDE",
          maturity: "Unknown",
          tools: ["None"],
          hasRecommendation: false,
        }),
      ],
    }),
    new ResponseSubcategory({
      name: "Email & Awareness",
      responseCapabilities: [
        new ResponseCapability({
          name: "Secure Email Gateway (SEG) / API",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Phishing Campaign",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Security Awareness  Training (SAT)",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

const identityCategory = new ResponseCategory({
  name: "Identity",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "Identity & Access Management",
      responseCapabilities: [
        new ResponseCapability({
          name: "IAM",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "MFA",
          maturity: "Unknown",
          tools: ["None"],
        }),

        new ResponseCapability({
          name: "SSO",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "PAM",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "IGA",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

const operationCategory = new ResponseCategory({
  name: "Operations",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "System Management",
      responseCapabilities: [
        new ResponseCapability({
          name: "Asset Management",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Vulnerability Management",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Patch Management / 3rd Party Software Updates",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Endpoint Application Management",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Endpoint Firewall Management",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "FIM",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "CMDB",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

const monitoringAlertingCategory = new ResponseCategory({
  name: "Monitoring & Alerting",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "Incident Response & Management",
      responseCapabilities: [
        new ResponseCapability({
          name: "SIEM / SOAR",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "MDR",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "IR",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Penetration Testing / Risk Assessments / Red Team",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

const networkCategory = new ResponseCategory({
  name: "Network",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "Network Infrastructure",
      responseCapabilities: [
        new ResponseCapability({
          name: "Wireless",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "SD-WAN",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
    new ResponseSubcategory({
      name: "Network Security",
      responseCapabilities: [
        new ResponseCapability({
          name: "Firewalls / NGFW",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Threat Prevention / Intel / IDS",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Remote Access (VPN, SWG, etc.)",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "DNS Protection (End User)",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "SSL / TLS Decryption",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Network Access Control (NAC)",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Network Detection & Response (NDR)",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "DDoS Protection",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "SASE / ZTA Strategy ",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

const cloudCategory = new ResponseCategory({
  name: "Cloud",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "Cloud Management and Protection",
      responseCapabilities: [
        new ResponseCapability({
          name: "CASB",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "SaaS Security Posture Management (SSPM)",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Cloud Native Application Protection Platform (CNAPP)",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Cloud Workload Protection",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "CSPM",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

const govComplianceCategory = new ResponseCategory({
  name: "Governance & Compliance",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "Compliance",
      responseCapabilities: [
        new ResponseCapability({
          name: "TLS/SSL Certificate Management",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "3rd Party / Vendor Review & Tracking / Supply Chain",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "GRC",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

const dataSecurityCategory = new ResponseCategory({
  name: "Data Security",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "Data Protection",
      responseCapabilities: [
        new ResponseCapability({
          name: "Data Governance",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Data Discovery",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Data Classification",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Data Loss Prevention (DLP)",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Digital Rights Management",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "Backup / Recovery",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

const applicationCategory = new ResponseCategory({
  name: "Application",
  responseSubcategories: [
    new ResponseSubcategory({
      name: "Application Security",
      responseCapabilities: [
        new ResponseCapability({
          name: "WAF",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "SCM",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "CICD",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "SCA/SAST/DAST",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "API Security",
          maturity: "Unknown",
          tools: ["None"],
        }),
        new ResponseCapability({
          name: "RASP",
          maturity: "Unknown",
          tools: ["None"],
        }),
      ],
    }),
  ],
});

export const sampleSprMaturityChartResponse = new AssessmentMaturityChartDto({
  responseCategories: [
    endUserCategory,
    identityCategory,
    operationCategory,
    monitoringAlertingCategory,
    networkCategory,
    cloudCategory,
    govComplianceCategory,
    dataSecurityCategory,
    applicationCategory,
  ],
});
