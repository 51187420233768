import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import {
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import HarveyBallMapKey from "@features/assessments-feature/components/spr-harveyball/HarveyBallMapKey";
import { SortableItem } from "./SortableItem";
import { Item } from "./Item";
import Box from "@mui/material/Box";
import { HarveyBallProducts, HarveyBallRows } from "@lib/ShiOneClient";
import { goodToBadColors } from "shared-ui";
import ShiLogo from "@images/shi-logo.svg?react";
import { useHarveyBallCapability } from "@features/assessments-feature/hooks/useHarveyBallCapability";
import useGetScrollbarWidth from "@features/assessments-feature/hooks/useGetScrollbarWidth";
import useGetFillerContainerWidth from "@features/assessments-feature/hooks/useGetFillerContainerWidth";
import { ShiSpinnerIcon } from "shared-ui/src/components/ShiSpinnerIcon";

type ProductScore = {
  [key: string]: number | string;
};

// Define the type for the outer object
type ProductScores = {
  [category: string]: ProductScore;
};

type Rows = {
  [key: string]: HarveyBallRows[];
};

export default function HarveyBall({
  capabilityId,
  open,
  setIsOpen,
  actionTitle,
  filteredProducts = [],
}: {
  capabilityId: string;
  open: boolean;
  setIsOpen: (value: boolean) => void;
  actionTitle: string;
  filteredProducts?: string[];
}) {
  const { data, isLoading } = useHarveyBallCapability(capabilityId);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [items, setItems] = useState<HarveyBallProducts[]>([]);
  const [productScores, setProductScores] = useState<ProductScores>();
  const [rows, setRows] = useState<Rows>({});

  const { containerRef, scrollbarWidth } = useGetScrollbarWidth();
  const { cellRef, fillerWidth } = useGetFillerContainerWidth();
  useEffect(() => {
    if (!isLoading && data) {
      let products = data.harveyBallProductsCollection ?? [];
      const scores = data.harveyBallScoresCollection ?? [];
      const productRows = data.harveyBallRowsCollection ?? [];

      if (filteredProducts.length > 0) {
        products = products.filter((product) =>
          filteredProducts.includes(product?.productName ?? ""),
        );
      }
      setItems(products);
      const result: ProductScores = {};

      scores?.forEach((score) => {
        if (
          score.featureName &&
          score.productId !== undefined &&
          score.score !== undefined
        ) {
          if (!result[score.featureName]) {
            result[score.featureName] = {};
          }
          result[score.featureName][score.productId] = score.score;
        }
      });

      const rowsResult: Rows = {};

      productRows?.forEach((row) => {
        if (!rowsResult[row.sectionName!]) {
          rowsResult[row.sectionName!] = [row];
        } else {
          rowsResult[row.sectionName!].push(row);
        }
      });

      setProductScores(result);
      setRows(rowsResult);
    }
  }, [isLoading]);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const genSections = (sectionNameKey: string) => {
    const showHbc = rows[sectionNameKey].length > 1;

    return (
      <div>
        <Typography variant={"h6"} fontWeight={400} pt={3} pb={2}>
          {sectionNameKey}
        </Typography>
        <Box
          border={(theme) => "1px solid" + theme.palette.border}
          borderBottom={"none"}
        >
          {rows[sectionNameKey]?.map((item) => (
            <div className={"bg-table-header p-0"}>
              {showHbc && (
                <div
                  className={
                    "p-4 border-solid border-border border-0 border-b-[1px] "
                  }
                >
                  {item.harveyBallCategory}
                </div>
              )}
              <div className={"flex flex-1 flex-col bg-white "}>
                {item.relatedFeaturesCollection?.map((i) =>
                  genComponents(i.featureName!),
                )}
              </div>
            </div>
          ))}
        </Box>
      </div>
    );
  };

  const genComponents = (relatedFeature: string) => {
    return (
      <Table>
        <TableRow>
          <TableCell ref={cellRef} className={"pl-4 w-[21rem] min-w-[8rem]"}>
            {relatedFeature}
          </TableCell>
          {items?.map((i) => genHarveyBalls(relatedFeature, i.productId!))}
        </TableRow>
      </Table>
    );
  };

  const genHarveyBalls = (featureName: string, productId: string) => {
    // @ts-ignore
    const score: number | string =
      productScores?.[featureName]?.[productId] ?? "-";

    switch (score.toString()) {
      case "1":
        return (
          <TableCell className={"min-w-[90px] w-auto text-center bg-red/10 "}>
            <Typography
              variant="h2"
              fontFamily={"Arial Unicode MS"}
              lineHeight={"24px"}
              color={goodToBadColors.bad}
            >
              ○
            </Typography>
          </TableCell>
        );
      case "2":
        return (
          <TableCell
            className={"min-w-[90px] w-auto text-center bg-orange-light/10"}
          >
            <Typography
              variant="h2"
              fontFamily={"Arial Unicode MS"}
              lineHeight={"24px"}
              color={goodToBadColors.warning}
            >
              ◔
            </Typography>
          </TableCell>
        );
      case "3":
        return (
          <TableCell
            className={"min-w-[90px] w-auto text-center bg-orange-lighter/10"}
          >
            <Typography
              variant="h2"
              fontFamily={"Arial Unicode MS"}
              lineHeight={"24px"}
              color={goodToBadColors.ok}
            >
              ◑
            </Typography>
          </TableCell>
        );
      case "4":
        return (
          <TableCell className={"min-w-[90px] w-auto text-center bg-green/10"}>
            <Typography
              variant="h2"
              fontFamily={"Arial Unicode MS"}
              lineHeight={"24px"}
              color={goodToBadColors.good}
            >
              ◕
            </Typography>
          </TableCell>
        );
      case "5":
        return (
          <TableCell
            className={"min-w-[90px] w-auto text-center bg-green-dark/10"}
          >
            <Typography
              variant="h2"
              fontFamily={"Arial Unicode MS"}
              lineHeight={"24px"}
              color={goodToBadColors.best}
            >
              ●
            </Typography>
          </TableCell>
        );
      default:
        return (
          <TableCell className={"min-w-[90px] w-auto text-center"}>-</TableCell>
        );
    }
  };

  function handleDragStart(event: { active: any }) {
    const { active } = event;
    setActiveId(active.id);
  }

  function handleDragEnd(event: { active: any; over: any }) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items?.findIndex(
          (item) => item.productId === active.id,
        );
        const newIndex = items?.findIndex((item) => item.productId === over.id);

        return arrayMove(items!, oldIndex!, newIndex!);
      });
    }

    setActiveId(null);
  }

  const activeItem = items?.find((i) => i.productId === activeId);

  const genProductItem = (product?: HarveyBallProducts) => {
    if (product?.productLogo) {
      return (
        <div className={"flex flex-col"}>
          <div>
            <img
              src={product?.productLogo}
              className={"object-contain"}
              width={"88px"}
              height={"24px"}
              alt={product?.productName}
            />
          </div>
          <div className={"mt-2"}>
            <Typography variant={"caption"}>{product?.productName}</Typography>
          </div>
        </div>
      );
    }
    return product?.productName;
  };

  if (isLoading) {
    return (
      <div
        className={
          "fixed left-[calc(50%+88px)] top-[64px]  flex flex-col items-center gap-2"
        }
      >
        <ShiSpinnerIcon />
      </div>
    );
  }
  return (
    <Dialog open={open} className={"w-full"} fullWidth maxWidth={"lg"}>
      <DialogTitle>
        <div className={"flex flex-1 w-full items-center"}>
          <div>
            <Typography>Compare Solutions</Typography>
          </div>
          <div className={"self-end ml-auto"}>
            <HarveyBallMapKey />
          </div>
        </div>
      </DialogTitle>
      <div
        className={
          "bg-table-header flex  w-full border-solid border-border border-0 border-y-[1px] px-6"
        }
        style={{ paddingRight: scrollbarWidth + 24 }} //scrollbar + padding
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            // @ts-ignore
            items={items}
            strategy={horizontalListSortingStrategy}
          >
            <div className={"flex flex-1 w-full"}>
              <div style={{ minWidth: "8rem", width: fillerWidth }} />
              <div
                className={
                  "flex flex-1 shadow-[0.5px_1px_1px_0px_rgba(0,0,0,0.1),0px_4px_2px_0px_rgba(0,0,0,0.06)]"
                }
              >
                {items?.map((value) => (
                  <SortableItem
                    key={value.productId}
                    id={value.productId}
                    children={genProductItem(value)}
                    className={
                      "h-full w-[90px] md:grow lg:grow items-center py-2 text-center justify-center overflow-hidden border-border border-solid border-0 border-l-[.5px]"
                    }
                  />
                ))}
              </div>
            </div>
          </SortableContext>
          <DragOverlay dropAnimation={defaultDropAnimation}>
            {activeId ? (
              <Item
                className={
                  "border-solid border-border border-[1px] text-center flex h-full items-center justify-center min-w-[90px] bg-table-header shadow-2xl rounded cursor-grabbing"
                }
                children={genProductItem(activeItem)}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
      <DialogContent ref={containerRef}>
        {Object.keys(rows)?.map((row) => genSections(row))}
        <div className={"mt-4"}>
          <Typography variant={"caption"}>
            All ratings, evaluations, and assessments provided herein are
            exclusively the subjective opinions of SHI and are based on our own
            internal criteria, methodologies, and judgment. These ratings do not
            purport to reflect any industry-wide standards, benchmarks, or
            consensus. SHI expressly disclaims any representation or warranty,
            express or implied, as to the accuracy, completeness, or reliability
            of the ratings provided. Users of this information are advised that
            any reliance on these ratings is at their own risk, and SHI shall
            not be liable for any consequences arising from such reliance.
          </Typography>
        </div>

        <div className={"text-5xl"}>
          <SvgIcon
            inheritViewBox
            style={{ fontSize: "inherit" }}
            component={ShiLogo}
          />
        </div>
      </DialogContent>
      <DialogActions
        disableSpacing
        className={
          "border-solid border-border border-0 border-t-[1px] flex w-full"
        }
      >
        <div className={"flex-1"}>
          <Typography variant={"body1"} fontWeight={500}>
            {actionTitle}
          </Typography>
        </div>
        <div>
          <Button variant={"text"} onClick={() => setIsOpen(false)}>
            done
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
