import React, { useState } from "react";
import useGetAssessmentPlatformInsights from "@features/assessments-feature/hooks/useGetAssessmentPlatformInsights";
import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import LoadingOrError from "shared-ui/src/global-layout/LoadingOrError";
import { SkeletonWrapper } from "shared-ui";
import ProductItemsContainer from "@features/assessments-feature/components/platform-insights/ProductItemsContainer";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { ToolsType } from "@features/assessments-feature/types/PlatformInsightsProductsType";
import ToolsTypeIcon from "@features/assessments-feature/components/ToolsTypeIcon";
import useAssessmentDemoData from "@features/assessments-feature/hooks/useAssessmentDemoData";
import { useAssessmentViewContext } from "@features/assessments-feature/contexts-providers/AssessmentViewContextProvider";

const AssessmentPlatformInsights = () => {
  const { assessmentViewProps } = useAssessmentViewContext();
  const { isDemoMode } = useAssessmentDemoData();
  const currentDemo =
    assessmentViewProps && isDemoMode.get(assessmentViewProps.id);
  const { data, isLoading, isError, isPlaceholderData } =
    useGetAssessmentPlatformInsights(assessmentViewProps?.id);
  const loading = isLoading || isPlaceholderData;
  const [expandAll, setExpandAll] = useState<{ [key: string]: boolean }>({});

  if (!data || currentDemo || isError) {
    return (
      <LoadingOrError
        error
        message={
          isError
            ? "There is an issue loading the data."
            : "There are no products to display."
        }
      />
    );
  }

  const sortedPartners = Object.keys(data).sort((a, b) => {
    if (a === "Unknown") return 1;
    if (b === "Unknown") return -1;
    return a.localeCompare(b);
  });

  const handleExpandAll = (partner: string, state: boolean) => {
    setExpandAll({ ...expandAll, [partner]: state });
  };

  return (
    <>
      <Box
        display={loading ? "none" : "flex"}
        justifyContent={"end"}
        alignItems={"center"}
        mb={1}
        mr={1}
        gap={2}
      >
        <div>
          <ToolsTypeIcon type={ToolsType.KnownTools} />
          <Typography variant={"caption"} ml={0.8}>
            Currently using
          </Typography>
        </div>
        <div>
          <ToolsTypeIcon type={ToolsType.OtherTools} />
          <Typography variant={"caption"} ml={0.8}>
            Recommended
          </Typography>
        </div>
      </Box>
      {sortedPartners.map((partner: string) => {
        return (
          <Box pb={2} key={partner}>
            <Box
              px={2}
              bgcolor={"table.header"}
              border={(theme) => "1px solid" + theme.palette.border}
              borderBottom={"none"}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <SkeletonWrapper loading={loading}>
                <Typography py={1}>{partner}</Typography>
              </SkeletonWrapper>
              <Tooltip
                placement={"top"}
                followCursor
                title={expandAll[partner] ? "Close all" : "Expand all"}
              >
                <IconButton
                  disabled={loading}
                  aria-label={expandAll[partner] ? "close-all" : "expand-all"}
                  size={"small"}
                  sx={{ color: "text.secondary" }}
                  onClick={() =>
                    handleExpandAll(partner, !expandAll[partner] ?? false)
                  }
                >
                  {expandAll[partner] ? (
                    <UnfoldLess sx={{ fontSize: 16 }} />
                  ) : (
                    <UnfoldMore sx={{ fontSize: 16 }} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            <Divider />
            <ProductItemsContainer
              loading={loading}
              productList={data[partner]}
              expandAll={expandAll[partner]}
            />
          </Box>
        );
      })}
    </>
  );
};

export default AssessmentPlatformInsights;
