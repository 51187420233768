import React, { memo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { MappingRow } from "./types";
import { IMappingSchema } from "../hooks/useMappingEngineSchema";

const MemoMappedAutoComplete = memo(function MappedColAutocomplete({
  mapping,
  data,
  handleMappingChange,
}: {
  mapping: MappingRow;
  data: IMappingSchema;
  handleMappingChange: any;
}) {
  return (
    <Autocomplete
      fullWidth
      options={data.columns}
      getOptionLabel={(option) => option.name}
      value={mapping.mappedCol || null}
      onChange={(_event, newValue) => {
        handleMappingChange(
          { target: { name: "mappedCol", value: newValue } },
          mapping.id
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="Select a column" variant="outlined" />
      )}
    />
  );
});

export default MemoMappedAutoComplete;
