import React, { memo, useCallback } from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  ErrorOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";
import { excelSheetActions } from "./excelSheetReducer";
import { ExcelSheet } from "./types";
import { MappingRowsResultDto } from "@lib/ShiOneClient";

const MemoLocationPatternSelect = memo(function LocationPatternSelect(props: {
  onChange: (e: any) => void;
  value: any;
}) {
  return (
    <Select
      labelId="location-pattern-label"
      label="Location Pattern"
      variant="outlined"
      onChange={props.onChange}
      name="locationPattern"
      value={props.value}
    >
      <MenuItem value="exactMatch">Exact Match</MenuItem>
      <MenuItem value="startsWith">Starts With</MenuItem>
    </Select>
  );
});

export default function ExcelSheetRow({
  sheet,
  dispatch,
  uploadResponse,
}: {
  sheet: ExcelSheet;
  dispatch: any;
  uploadResponse: MappingRowsResultDto;
}) {
  const handleExcelSheetChange = useCallback(
    (e: { target: { name: any; value: any } }, id: any) => {
      dispatch({
        type: excelSheetActions.update_excel,
        name: e.target.name,
        value: e.target.value,
        id,
      });
    },
    []
  );

  return (
    <div className="flex pb-6 gap-3 mx-4 mt-4 items-center" key={sheet.id}>
      {sheet.valid ? (
        <VerifiedOutlined color="success" />
      ) : (
        <ErrorOutlined color="error" />
      )}
      <FormControl className="grow">
        <InputLabel id="source-sheet-label">Source Sheet Name</InputLabel>
        <Select
          labelId="source-sheet-label"
          label="Source Sheet Name"
          variant="outlined"
          onChange={(e) => handleExcelSheetChange(e, sheet.id)}
          name="sourceSheetName"
          value={sheet.sourceSheetName}
        >
          {[
            ...new Set(
              uploadResponse?.excelRowsList?.map((row) => row?.sheetName)
            ),
          ].map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className="grow">
        <InputLabel id="location-pattern-label">Location Pattern</InputLabel>
        <MemoLocationPatternSelect
          onChange={(e) => handleExcelSheetChange(e, sheet.id)}
          value={sheet.locationPattern}
        />
      </FormControl>
      <TextField
        label="Location Key"
        name="locationKey"
        value={sheet.locationKey}
        onChange={(e) => handleExcelSheetChange(e, sheet.id)}
      />
      <IconButton
        color="error"
        onClick={() =>
          dispatch({ type: excelSheetActions.delete_excel, id: sheet.id })
        }
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
}
