import React, { useState } from "react";
import { ResponseRecommendation } from "@lib/ShiOneClient";
import { Button, Grid, Link, ListItem, ListItemText } from "@mui/material";
import ColoredChips from "shared-ui/src/theme/extensions/ColoredChips";
import { impactColor } from "@features/assessments-feature/utils/assessmentsColors";
import { SkeletonWrapper } from "shared-ui";
import { fourLineHeightCell } from "../../../../theme/mixins/lineHeight";
import ProductLogosList from "@features/assessments-feature/components/priorities-recommendations/ProductLogosList";
import HarveyBall from "@features/assessments-feature/components/spr-harveyball/HarveyBall";
import { useAssessmentExportContext } from "@features/assessments-feature/contexts-providers/AssessmentExportContextProvider";

const ImpactsAndRecsItem = ({
  loading,
  recData,
  handleRecsDialogOpenState,
}: {
  loading: boolean;
  recData?: ResponseRecommendation;
  handleRecsDialogOpenState: (
    recsValue: ResponseRecommendation | undefined,
    openState: boolean,
  ) => void;
}) => {
  const { isPrint } = useAssessmentExportContext();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <ListItem divider>
        <Grid container alignItems={"center"} spacing={1}>
          {recData?.impact && (
            <Grid item xs={2} md={"auto"} pr={1.5}>
              <SkeletonWrapper loading={loading}>
                <ColoredChips
                  bgColor={impactColor[recData?.impact?.toLowerCase()]}
                  label={recData.impact}
                />
              </SkeletonWrapper>
            </Grid>
          )}
          <Grid item xs={10} md={4}>
            <SkeletonWrapper loading={loading}>
              <ListItemText
                primary={
                  <Link
                    sx={{ cursor: "pointer" }}
                    variant={"body1"}
                    onClick={() => handleRecsDialogOpenState(recData, true)}
                  >
                    {!!recData?.capability ? recData?.capability : "N/A"}
                  </Link>
                }
                secondary={recData?.recommendationsSummary}
                secondaryTypographyProps={{
                  component: "div",
                  sx: fourLineHeightCell,
                }}
              />
            </SkeletonWrapper>
          </Grid>
          <Grid item xs={12} md width={"100%"}>
            <ProductLogosList
              toolsLogos={recData?.otherToolsLogos}
              tools={recData?.otherTools}
              sx={{ justifyContent: "center" }}
            />
          </Grid>
          {!isPrint && (
            <Grid item container justifyContent="end" xs={12} md={2}>
              {recData?.hasHarveyBallData && (
                <div>
                  <Button variant={"text"} onClick={() => setIsOpen(true)}>
                    Compare Solutions
                  </Button>
                  <HarveyBall
                    actionTitle={recData?.capability ?? ""}
                    capabilityId={recData?.capabilityId ?? ""}
                    open={isOpen ?? false}
                    setIsOpen={setIsOpen}
                  />
                </div>
              )}
            </Grid>
          )}
        </Grid>
      </ListItem>
    </div>
  );
};

export default ImpactsAndRecsItem;
