import React from "react";
import { Card, Box, Typography, Divider } from "@mui/material";
import { timePeriodPriority } from "@features/assessments-feature/constants";
import ImpactsAndRecsItem from "@features/assessments-feature/components/priorities-recommendations/ImpactsAndRecsItem";
import { SkeletonWrapper } from "shared-ui";
import {
  PrioritizedRecommendation,
  ResponseRecommendation,
} from "@lib/ShiOneClient";

const PrioritiesRecsList = ({
  sortedPrioritizedRecs,
  handleRecsDialogOpenState,
  loading,
}: {
  sortedPrioritizedRecs: PrioritizedRecommendation[] | undefined;
  handleRecsDialogOpenState: (
    recsValue: ResponseRecommendation | undefined,
    open: boolean,
  ) => void;
  loading: boolean;
}) => {
  return (
    <Card>
      {sortedPrioritizedRecs?.length === 0 && (
        <Typography
          component="div"
          variant={"h6"}
          color={"textSecondary"}
          p={3}
        >
          No recommendations are available for display.
        </Typography>
      )}
      {sortedPrioritizedRecs?.map((recs, index) => {
        const key = recs.priority ?? "" + index;
        return (
          <Box key={key}>
            <Box
              px={2}
              py={1}
              bgcolor={"table.header"}
              display={"flex"}
              alignItems={"center"}
              gap={2}
            >
              <SkeletonWrapper loading={loading}>
                <Typography>{recs.priority ?? "Unknown"}</Typography>
              </SkeletonWrapper>
              <Typography variant={"caption"} color={"textSecondary"}>
                {timePeriodPriority.get(recs.priority ?? "")}
              </Typography>
            </Box>
            <Divider />
            {recs.responseRecommendations?.map((rec, index) => {
              const key = rec?.capability ?? "" + index;
              return (
                <ImpactsAndRecsItem
                  key={key}
                  recData={rec}
                  loading={loading}
                  handleRecsDialogOpenState={handleRecsDialogOpenState}
                />
              );
            })}
          </Box>
        );
      })}
    </Card>
  );
};

export default PrioritiesRecsList;
