import React, { memo } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { locationPatterns } from "./excelSheetReducer";
import { MappingRowsResultDto } from "@lib/ShiOneClient";

interface ExampleData {
  colName?: string;
  exampleData?: string[];
}

const MemoExcelColumnAutoComplete = memo(function ExcelColumnAutoComplete({
  item,
  uploadResponse,
  handleMappingChange,
}: {
  item: any;
  uploadResponse: MappingRowsResultDto;
  handleMappingChange: any;
}) {
  // Prepare options for Autocomplete
  const options = [
    ...new Set(
      uploadResponse?.excelRowsList?.map((row) => {
        if (item.excelSheet.locationPattern === locationPatterns.startsWith) {
          if (row?.sheetName?.startsWith(item.excelSheet.locationKey)) {
            return {
              colName: row.columnName,
              exampleData: row.exampleData,
            };
          }
        } else if (row.sheetName === item.excelSheet.locationKey) {
          return {
            colName: row.columnName,
            exampleData: row.exampleData,
          };
        }
      })
    ),
  ].filter(Boolean);

  return (
    <Autocomplete
      fullWidth
      options={options as ExampleData[]}
      getOptionLabel={(option) => option.colName || ""}
      value={options.find((option) => option?.colName === item.column) || null}
      onChange={(_event, newValue) => {
        handleMappingChange(
          { target: { name: "column", value: newValue?.colName || "" } },
          item.id
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select a column"
          variant="outlined"
          disabled={item.naOverride}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.colName}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <div>{option.colName}</div>
            {option.exampleData?.map((i, index) => (
              <Typography key={index} variant="caption" color="textSecondary">
                {i}
              </Typography>
            ))}
          </Box>
        </li>
      )}
    />
  );
});

export default MemoExcelColumnAutoComplete;
