import React from "react";
import { ToolsType } from "@features/assessments-feature/types/PlatformInsightsProductsType";
import { Recommend, Stars } from "@mui/icons-material";
import { SxProps, Tooltip } from "@mui/material";

const ToolsTypeIcon = ({
  type,
  showTooltip = false,
  sx,
  className,
}: {
  type: ToolsType;
  showTooltip?: boolean;
  sx?: SxProps;
  className?: string;
}) => {
  if (type === ToolsType.KnownTools)
    return (
      <Tooltip followCursor title={showTooltip ? "Current using" : ""}>
        <Stars
          aria-label={"using-tools"}
          sx={sx ?? { color: "text.secondary" }}
          className={className}
        />
      </Tooltip>
    );
  else
    return (
      <Tooltip followCursor title={showTooltip ? "Recommended" : ""}>
        <Recommend
          aria-label={"recommended-tools"}
          sx={sx ?? { color: "text.secondary" }}
          className={className}
        />
      </Tooltip>
    );
};

export default ToolsTypeIcon;
