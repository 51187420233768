import { Box } from "@mui/material";
import React from "react";
import AssessmentCapabilityInsights from "@features/assessments-feature/components/capability-insights/AssessmentCapabilityInsights";

const CapabilityInsightsExport = () => {
  return (
    <Box className={"assessment-break"}>
      <AssessmentCapabilityInsights />
    </Box>
  );
};

export default CapabilityInsightsExport;
