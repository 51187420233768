import React from "react";
import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import TableHead from "@mui/material/TableHead";

const CapabilityTable = ({
  children,
  header,
  isKnownProduct = false,
}: {
  children: React.ReactNode;
  header: React.ReactNode;
  isKnownProduct?: boolean;
}) => {
  return (
    <Grid item container gap={3}>
      <Grid item xs={"auto"} container mt={1}>
        {header}
      </Grid>
      <Grid item xs container>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell>Capability</TableCell>
              <TableCell>Maturity</TableCell>
              <TableCell>
                {isKnownProduct ? "Also using" : "Currently using"}
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default CapabilityTable;
