import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Typography,
} from "@mui/material";
import { AssessmentProgram, TargetMaturityBreakdown } from "@lib/ShiOneClient";
import Box from "@mui/material/Box";
import { Line } from "react-chartjs-2";
import shiColors from "shared-ui/src/theme/shiColors";
import {
  Chart,
  Legend,
  LineElement,
  PointElement,
  Tooltip as ChartJSTooltip,
} from "chart.js";
import useAdjustCategoriesChartPositions from "@features/assessments-feature/hooks/useAdjustCategoriesChartPositions";
import DemoModeOverlay from "@features/assessments-feature/components/DemoModeOverlay";
import useAssessmentDemoData from "@features/assessments-feature/hooks/useAssessmentDemoData";
import { useChartJsDefaults } from "shared-ui/src/theme/useChartJsDefaults";

Chart.register(LineElement, PointElement, Legend, ChartJSTooltip);

const AssessmentOverviewTargetMaturityBreakdown = ({
  loading,
  data,
  program,
}: {
  loading: boolean;
  data: TargetMaturityBreakdown[] | undefined;
  program: AssessmentProgram | undefined;
}) => {
  useChartJsDefaults();
  const { isDemoMode } = useAssessmentDemoData();
  const { customLabelsPlugin, labelHeight, labelPosition, labelWidth } =
    useAdjustCategoriesChartPositions();
  const subCategoriesList: string[] = [];
  const targetMaturityList: number[] = [];
  const currentMaturityList: number[] = [];
  data?.forEach((d) => {
    subCategoriesList.push(d.subCategory ?? "");
    targetMaturityList.push(Number(d.targetMaturity?.toFixed(1)) ?? 0);
    currentMaturityList.push(Number(d.currentMaturity?.toFixed(1)) ?? 0);
  });

  const options = {
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    layout: {
      padding: {
        bottom: 64,
        right: 8,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        max: 5,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      customLabelsPlugin: {},
    },
  };

  const chartData = {
    labels: subCategoriesList,
    datasets: [
      {
        label: "Current Maturity",
        data: currentMaturityList,
        borderColor: shiColors.blue.DEFAULT,
        backgroundColor: shiColors.blue.DEFAULT,
      },
      {
        label: "Target Maturity",
        data: targetMaturityList,
        borderColor: shiColors.pink.DEFAULT,
        backgroundColor: shiColors.pink.DEFAULT,
      },
    ],
  };

  return (
    <Card sx={{ position: "relative" }}>
      {program && isDemoMode.get(program) && <DemoModeOverlay />}
      <CardHeader
        title={"Target maturity breakdown"}
        titleTypographyProps={{ variant: "body1", textTransform: "uppercase" }}
      />
      <Divider />
      <CardContent
        sx={{
          paddingLeft: 3,
          paddingRight: 4,
          paddingY: 2.5,
          height: "50vh",
        }}
      >
        {loading ? (
          <Skeleton variant="rectangular" height={"47vh"} />
        ) : (
          <Box height={"100%"} sx={{ position: "relative" }}>
            <Line
              data={chartData}
              options={options}
              plugins={[customLabelsPlugin]}
            />
            {subCategoriesList.map((label, index) => {
              const key = label + index;
              return (
                <Box
                  key={key}
                  top={labelHeight}
                  left={labelPosition[index]}
                  position="absolute"
                  color={"text.secondary"}
                  display="flex"
                  alignItems="start"
                  justifyContent="center"
                  textAlign="center"
                  width={labelWidth[index]}
                  sx={{ transform: "translateX(-50%)" }}
                >
                  {labelPosition.length > 0 && (
                    <Typography variant={"caption"}>{label}</Typography>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default AssessmentOverviewTargetMaturityBreakdown;
