import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import GlobalConstants from "@constants";
import React from "react";
import { KnowledgeBaseMenu } from "../common";
import { NavItemType } from "../../NavigationTypes";
import { supportItemsToShowType } from "../../NavItemsToShowTypes";
import { useFeatureFlag } from "shared-ui/src/utils/FeatureFlagHelper";
import { useClaims } from "../../../../../../auth/ClaimsProvider";
import { ShiOnePermission } from "../../../../../../auth/authorizationTypes";

export function CustomerSupportCenterMenuV2(
  supportItemsToShow: supportItemsToShowType,
  knowledgebase: any,
): NavItemType {
  const { enabled: messageCenterEnabled } = useFeatureFlag(
    "message-center-profile-navigation",
  );
  const { hasPermission } = useClaims();

  const hasSupportReadPermission = hasPermission(
    ShiOnePermission.supportRequestRead,
  );
  const hasSupportWritePermission = hasPermission(
    ShiOnePermission.supportRequestSubmit,
  );

  return {
    id: "support",
    type: "Dashboard",
    displayName: "Support",
    icon: <LocalHospitalIcon sx={{ fontSize: "large" }} />,
    visible:
      Object.values(supportItemsToShow).includes(true) ||
      hasSupportReadPermission ||
      hasSupportWritePermission,
    path: "/support",
    children: [
      {
        id: "newrequest",
        type: "Link",
        displayName: "New Request",
        path: GlobalConstants.knownPaths.supportCenter_NewRequest,
        visible: hasSupportWritePermission,
      },
      {
        id: "requests",
        type: "Link",
        displayName: "Requests",
        path: "/support-center/requests",
        visible: hasSupportReadPermission,
      },
      {
        id: "serviceReports",
        type: "Link",
        displayName: "Reports",
        path: "/support-center/service-report",
        visible: supportItemsToShow.showServiceReport,
      },
      {
        id: "myDevices",
        type: "Link",
        displayName: "My Devices",
        path: "/support-center/mydevices",
        visible: supportItemsToShow.showMyDevices,
      },
      {
        id: "messageCenter",
        type: "Link",
        displayName: "News & Updates",
        path: "/message-center",
        visible: messageCenterEnabled,
      },
      KnowledgeBaseMenu({
        knowledgebase,
        showKnowledgeBase: supportItemsToShow.showKnowledgeBase,
      }),
    ],
  };
}
